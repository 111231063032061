import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import withRoleAccess from '../../hoc/withRoleAccess';
import moment from 'moment';
import { debounce } from 'lodash'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Select from 'react-select';
import FullCalendar from '@fullcalendar/react';
import thLocale from '@fullcalendar/core/locales/th'; // Import Thai locale
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Modal from '../../components/Modal';
import './Classes.css';
import { confirmAlert } from 'react-confirm-alert';
import { refreshToken, isTokenExpiredError } from '../../Utils/authUtils';

const hourOptions = [0, 1, 2, 3];
const minuteOptions = [0, 15, 30, 45];


const calculateEndDate = (schedule) => {
  if (!Array.isArray(schedule)) {
    console.error('Schedule is not an array:', schedule);
    return ''; // Handle invalid input gracefully
  }

  return schedule.map(slot => {
    if (!slot.start_date) return ''; // Skip if no start date is set

    const parsedStartDate = new Date(slot.start_date);
    if (isNaN(parsedStartDate.getTime())) {
      console.error('Invalid start_date:', slot.start_date);
      return '';
    }

    let endDate;
    if (slot.recurring === 'daily') {
      endDate = new Date(parsedStartDate.setDate(parsedStartDate.getDate() + (slot.number_of_recurrences - 1)));
    } else if (slot.recurring === 'weekly') {
      endDate = new Date(parsedStartDate.setDate(parsedStartDate.getDate() + (slot.number_of_recurrences - 1) * 7));
    } else if (slot.recurring === 'bi-weekly') {
      endDate = new Date(parsedStartDate.setDate(parsedStartDate.getDate() + (slot.number_of_recurrences - 1) * 14));
    } else {
      return slot.start_date; // No recurrence
    }

    return endDate.toISOString().split('T')[0];
  });
};


const formatDateTime = (date, time) => {
  return `${moment(date).format('YYYY-MM-DD')}T${time}`;
};

function Classes() {
  const { t, i18n } = useTranslation();
  const [teachers, setTeachers] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [classrooms, setClassrooms] = useState([]);
  const initialNewClassState = {
    class_code: '',
    classDetails: [
      {
        subject_id: '',
        teacher_id: '',
        schedule: [
          { 
            day: '', 
            time: '', 
            hours: '0', 
            minutes: '0', 
            recurring: 'none', 
            number_of_recurrences: 1,
            start_date: '' // Each slot has its own start_date
          }
        ],
        total_hours: 0,
      }
    ],
    classroom_id: '',
    price: 0,
    register_capacity: 0,
    isprivate: false,
    is_template: false,
    create_date: new Date().toISOString().split('T')[0]
  };
  const [newClass, setNewClass] = useState(initialNewClassState);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmingClass, setConfirmingClass] = useState(null);
  const [teacherClasses, setTeacherClasses] = useState({});
  const [teacherSchedules, setTeacherSchedules] = useState({});
  const [showTeacherCalendars, setShowTeacherCalendars] = useState({});

  const API_URL = process.env.REACT_APP_API_URL;


  const fetchTeachers = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/teachers`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      // Filter teachers with status 'active' and sort alphabetically by nickname, then firstname
      const activeTeachers = response.data
        .filter((teacher) => teacher.status === 'active')
        .sort((a, b) =>
          `${a.nickname} ${a.firstname}`.localeCompare(`${b.nickname} ${b.firstname}`)
        );
  
      setTeachers(activeTeachers);
      setLoading(false);
    } catch (error) {
      if (isTokenExpiredError(error)) {
        refreshToken().then(() => {
          fetchTeachers();
        }).catch(err => {
          console.error('Token refresh failed:', err);
          setError('Session expired. Please log in again.');
        });
      } else {
        console.error('Error fetching teachers:', error);
        setError('Error fetching teachers. Please try again later.');
      }
      setLoading(false);
    }
  }, [API_URL]);
  
  const fetchSubjects = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/subjects`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      // Sort subjects alphabetically by title
      const sortedSubjects = response.data.sort((a, b) => a.title.localeCompare(b.title));
      setSubjects(sortedSubjects);
    } catch (error) {
      console.error('Error fetching subjects:', error);
    }
  }, [API_URL]);
  

  const fetchClassrooms = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/classrooms`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setClassrooms(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching classrooms:', error);
      setError('Failed to fetch classrooms. Please try again later.');
      setLoading(false);
    }
  }, [API_URL]);

  const fetchTeacherClasses = useCallback(async (teacherId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/classes/teacher/${teacherId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
  
      const transformedClasses = response.data.map(cls => ({
        id: cls.classid,
        title: cls.subject_name,
        start: formatDateTime(cls.date, cls.schedule_time),
        end: formatDateTime(cls.date, cls.end_time),
        type: 'class'
      }));
  
      // Use filter to remove duplicates
      const distinctClasses = transformedClasses.filter((cls, index, self) =>
        index === self.findIndex(c => c.id === cls.id)
      );
  
      setTeacherClasses(prevClasses => ({
        ...prevClasses,
        [teacherId]: distinctClasses // Store distinct classes
      }));
    } catch (error) {
      console.error('Failed to fetch teacher classes:', error);
    }
  }, [API_URL]);
  
  const fetchTeacherSchedule = useCallback(async (teacherId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/teachers/${teacherId}/events`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setTeacherSchedules(prevSchedules => ({
        ...prevSchedules,
        [teacherId]: response.data
      }));
      setShowTeacherCalendars(prevShowCalendars => ({
        ...prevShowCalendars,
        [teacherId]: true
      }));
    } catch (error) {
      console.error('Error fetching teacher schedule:', error);
      setError('Failed to fetch teacher schedule. Please try again later.');
    }
  }, [API_URL]);
  
  // Call these inside useEffect
  useEffect(() => {
    fetchTeachers();
    fetchSubjects();
    fetchClassrooms();
  }, [fetchTeachers, fetchSubjects, fetchClassrooms, API_URL]);
   

  useEffect(() => {
    newClass.classDetails.forEach(detail => {
      if (detail.teacher_id) {
        fetchTeacherSchedule(detail.teacher_id);
        fetchTeacherClasses(detail.teacher_id);
      }
    });
  }, [newClass.classDetails, fetchTeacherSchedule, fetchTeacherClasses]);

  const checkTimeOverlap = (start1, end1, start2, end2) => {
    return (
      (start1 >= start2 && start1 < end2) ||
      (end1 > start2 && end1 <= end2) ||
      (start1 <= start2 && end1 >= end2)
    );
  };
  
  const checkScheduleOverlap = (teacherId, newStartDate, newEndDate, newStartTime, newEndTime) => {
    // Combine the date and time
    const newStart = moment(`${newStartDate} ${newStartTime}`);
    const newEnd = moment(`${newStartDate} ${newEndTime}`);
    
    // Check classes
    const teacherClsOverlap = teacherClasses[teacherId]?.some(cls => {
      const clsStart = moment(cls.start);
      const clsEnd = moment(cls.end);
      return checkTimeOverlap(newStart, newEnd, clsStart, clsEnd);
    });
  
    // Check events
    const teacherEvtOverlap = teacherSchedules[teacherId]?.some(evt => {
      const evtStart = moment(`${evt.start}`);
      const evtEnd = moment(`${evt.end}`);
      return checkTimeOverlap(newStart, newEnd, evtStart, evtEnd);
    });
  
    return teacherClsOverlap || teacherEvtOverlap;
  };

  const updateTotalHoursAndEndDate = (updatedClassDetails) => {
    if (!Array.isArray(updatedClassDetails)) {
      console.error('Class details is not an array:', updatedClassDetails);
      return; // Stop execution if input is invalid
    }
  
    const totalHours = updatedClassDetails.reduce((total, detail) => {
      if (!detail.schedule || !Array.isArray(detail.schedule)) {
        console.error('Schedule is missing or not an array:', detail.schedule);
        return total;
      }
      return total + calculateTotalHours(detail.schedule);
    }, 0);
  
    const overallStartDate = calculateOverallStartDate(updatedClassDetails); // Calculate earliest start date
    const endDate = updatedClassDetails.map(detail =>
      calculateEndDate(detail.schedule)
    );
  
    setNewClass(prevClass => ({
      ...prevClass,
      classDetails: updatedClassDetails,
      total_hours: totalHours,
      start_date: overallStartDate, // Set earliest start date
      end_date: endDate[endDate.length - 1] // Use the last end date for consistency
    }));
  };
  
  
  const handleNewClassChange = (e, index = 0) => {
    const { name, value } = e.target;
    setNewClass((prevClass) => {
      const updatedClassDetails = [...prevClass.classDetails];
  
      if (name === 'class_code' || name === 'start_date' || name === 'recurring' || name === 'classroom_id' || name === 'book_details_materials' || name === 'isprivate' || name === 'price' || name === 'register_capacity' || name === 'is_template') {
        let updatedClass = { ...prevClass, [name]: value };
  
        // Set 'isprivate' to false if not checked
        if (name === 'isprivate') {
          updatedClass = { ...prevClass, isprivate: !prevClass.isprivate };
        }
  
        if (name === 'recurring') {
          // Initialize schedule array if recurrence is 'none' or 'daily'
          if (value === 'none' || value === 'daily') {
            updatedClass.classDetails[index].schedule = [{ time: '', hours: '0', minutes: '0' }];
          }
        }
  
        updateTotalHoursAndEndDate(updatedClassDetails);
        return updatedClass;
      } else {
        updatedClassDetails[index] = {
          ...updatedClassDetails[index],
          [name]: value,
        };
  
        updateTotalHoursAndEndDate(updatedClassDetails);
        return { ...prevClass, classDetails: updatedClassDetails };
      }
    });
  };
  

// Ensure recurrence is handled at the time slot level in handleScheduleChange
const handleScheduleChange = (index, scheduleIndex, field, value) => {
  const updatedClassDetails = [...newClass.classDetails];
  const updatedSchedule = [...updatedClassDetails[index].schedule];
  const currentSlot = updatedSchedule[scheduleIndex];
  
  // Update the value
  updatedSchedule[scheduleIndex] = {
    ...currentSlot,
    [field]: value,
  };

  // Check for overlap if we have all necessary schedule information
  if (currentSlot.start_date && currentSlot.time && 
      (field === 'start_date' || field === 'time' || field === 'hours' || field === 'minutes')) {
    
    const endTime = moment(currentSlot.time, 'HH:mm')
      .add(parseInt(currentSlot.hours || 0, 10), 'hours')
      .add(parseInt(currentSlot.minutes || 0, 10), 'minutes')
      .format('HH:mm');

    const hasOverlap = checkScheduleOverlap(
      updatedClassDetails[index].teacher_id,
      currentSlot.start_date,
      currentSlot.start_date,
      currentSlot.time,
      endTime
    );

    if (hasOverlap) {
      alert(t('timeSlotOverlapWarning')); // Or use a more sophisticated notification system
      return; // Optionally prevent the change
    }
  }

  updatedClassDetails[index] = {
    ...updatedClassDetails[index],
    schedule: updatedSchedule,
  };

  updateTotalHoursAndEndDate(updatedClassDetails);
};


const calculateSlotRecurrenceDates = (slot) => {
  if (!slot.start_date) {
    console.error("Slot start_date is not defined:", slot);
    return [];
  }

  const startDate = moment(slot.start_date);

  if (!startDate.isValid()) {
    console.error("Invalid start_date:", slot.start_date);
    return [];
  }

  let recurrenceDates = [];

  if (slot.recurring === "weekly") {
    for (let i = 0; i < slot.number_of_recurrences; i++) {
      recurrenceDates.push(startDate.clone().add(i, "weeks").format("YYYY-MM-DD"));
    }
  } else if (slot.recurring === "bi-weekly") {
    for (let i = 0; i < slot.number_of_recurrences; i++) {
      recurrenceDates.push(startDate.clone().add(i * 2, "weeks").format("YYYY-MM-DD"));
    }
  } else if (slot.recurring === "daily") {
    for (let i = 0; i < slot.number_of_recurrences; i++) {
      recurrenceDates.push(startDate.clone().add(i, "days").format("YYYY-MM-DD"));
    }
  } else {
    // No recurrence
    recurrenceDates.push(startDate.format("YYYY-MM-DD"));
  }

  return recurrenceDates;
};

const calculateAllSlotDatesAndCommonStartDate = (classDetails) => {
  let allDates = []; // To store all recurrence dates

  classDetails.forEach((detail) => {
    detail.schedule.forEach((slot) => {
      const recurrenceDates = calculateSlotRecurrenceDates(slot);
      allDates = allDates.concat(recurrenceDates);
    });
  });

  // Find the earliest date
  const commonStartDate = allDates.length
    ? moment.min(allDates.map((date) => moment(date))).format("YYYY-MM-DD")
    : null;

  return { allDates, commonStartDate };
};


// Adjust the calculateTotalHours function to factor in the recurrence per timeslot
const calculateTotalHours = (schedule) => {
  return schedule.reduce((total, slot) => {
    const hours = parseInt(slot.hours, 10);
    const minutes = parseInt(slot.minutes, 10);
    const recurrences = parseInt(slot.number_of_recurrences || 1, 10); // Default to 1 if not set
    return total + (hours + (minutes / 60)) * recurrences;
  }, 0);
};

const handleAddScheduleSlot = useCallback((classIndex) => {
  setNewClass(prevClass => {
    const updatedClassDetails = [...prevClass.classDetails];
    const updatedSchedule = [...updatedClassDetails[classIndex].schedule];
    updatedSchedule.push({
      day: '', 
      time: '', 
      hours: '0', 
      minutes: '0', 
      recurring: 'none', 
      number_of_recurrences: 1,
      start_date: '' // Ensure start_date is initialized here
    });
    updatedClassDetails[classIndex] = {
      ...updatedClassDetails[classIndex],
      schedule: updatedSchedule
    };
    return {
      ...prevClass,
      classDetails: updatedClassDetails
    };
  });
}, []);


const debouncedHandleAddScheduleSlot = debounce((classIndex) => {
  handleAddScheduleSlot(classIndex);
}, 300);


  const handleAddClassDetail = () => {
    setNewClass((prevClass) => {
      const updatedClassDetails = [
        ...prevClass.classDetails,
        {
          subject_id: '',
          teacher_id: '',
          schedule: [],
          total_hours: 0,
          number_of_recurrences: 1
        }
      ];
      updateTotalHoursAndEndDate(updatedClassDetails);
      return {
        ...prevClass,
        classDetails: updatedClassDetails
      };
    });
  };

  const handleSaveClass = async (classData) => {
    const token = localStorage.getItem('token');
  
    // Calculate the earliest start date from all slots
    const allDates = classData.classDetails.flatMap(detail =>
      detail.schedule.flatMap(slot => calculateRecurrenceDates(slot))
    );
    
    const earliestDate = moment.min(allDates.map(date => moment(date))).format('YYYY-MM-DD');
    const latestDate = moment.max(allDates.map(date => moment(date))).format('YYYY-MM-DD');
  
    // Format the data for API
    const formattedData = {
      ...classData,
      start_date: earliestDate, // Set the class start_date to the earliest date
      end_date: latestDate, // Set the class end_date to the latest date
      classDetails: classData.classDetails.map(detail => ({
        subject_id: parseInt(detail.subject_id),
        teacher_id: parseInt(detail.teacher_id),
        schedule: detail.schedule.map(slot => ({
          day: slot.day,
          time: slot.time,
          hours: parseInt(slot.hours),
          minutes: parseInt(slot.minutes),
          recurring: slot.recurring,
          number_of_recurrences: parseInt(slot.number_of_recurrences),
          start_date: slot.start_date,
          end_date: calculateEndDate(slot.start_date, slot.number_of_recurrences, slot.recurring)
        }))
      })),
      classroom_id: parseInt(classData.classroom_id),
      price: parseFloat(classData.price),
      register_capacity: parseInt(classData.register_capacity),
      total_hours: calculateTotalHours(classData.classDetails)
    };
  
  
    try {
      await axios.post(`${API_URL}/classes`, formattedData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setConfirmationMessage('Class created successfully');
      setNewClass(initialNewClassState);
      setShowConfirmationModal(false);
    } catch (error) {
      console.error('Error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      setError(error.response?.data?.message || 'Failed to save class.');
    }
  };
  
  
  const handleConfirmSubmit = () => {
    const { commonStartDate } = calculateAllSlotDatesAndCommonStartDate(confirmingClass.classDetails);
  
    // Set the common start date to the database
    const classToSave = {
      ...confirmingClass,
      start_date: commonStartDate, // Use the earliest date as the common start_date
    };
  
    handleSaveClass(classToSave);
  };
  
  
  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Create array to store all overlapping dates
    let overlappingDates = [];
  
    // Check each class detail and schedule for overlaps
    newClass.classDetails.forEach((detail) => {
      if (!detail.teacher_id) return;
  
      const teacherName = teachers.find(t => t.userid === detail.teacher_id)?.nickname;
      
      detail.schedule.forEach(slot => {
        // Get all dates for this recurring schedule
        const dates = calculateRecurrenceDates(slot);
        
        dates.forEach(date => {
          // Calculate end time for this slot
          const endTime = moment(slot.time, 'HH:mm')
            .add(parseInt(slot.hours || 0, 10), 'hours')
            .add(parseInt(slot.minutes || 0, 10), 'minutes')
            .format('HH:mm');
  
          // Check for overlap
          if (checkScheduleOverlap(detail.teacher_id, date, date, slot.time, endTime)) {
            overlappingDates.push({
              date: moment(date).format('DD-MMM-YYYY'),
              time: `${slot.time} - ${endTime}`,
              teacher: teacherName
            });
          }
        });
      });
    });
  
    // If there are overlaps, show warning but allow to proceed
    if (overlappingDates.length > 0) {
      const warningMessage = overlappingDates.map((overlap, index) => 
        `${index + 1}. ${overlap.teacher}: ${overlap.date} at ${overlap.time}`
      ).join('\n');
  
      confirmAlert({
        title: t('Schedule Overlap Warning'),
        message: (
          <div style={{ whiteSpace: 'pre-line' }}>
            {t('The following schedules overlap with existing classes/events:')}
            {'\n\n'}
            {warningMessage}
            {'\n\n'}
            {t('Do you want to proceed anyway?')}
          </div>
        ),
        buttons: [
          {
            label: t('Yes, proceed'),
            onClick: () => {
              setConfirmingClass(newClass);
              setShowConfirmationModal(true);
            }
          },
          {
            label: t('No, I\'ll revise'),
            onClick: () => {}
          }
        ]
      });
    } else {
      // No overlaps, proceed normally
      setConfirmingClass(newClass);
      setShowConfirmationModal(true);
    }
  };


  const handleDeleteScheduleSlot = (classIndex, scheduleIndex) => {
    const updatedClassDetails = [...newClass.classDetails];
    const updatedSchedule = [...updatedClassDetails[classIndex].schedule];
    updatedSchedule.splice(scheduleIndex, 1);
    updatedClassDetails[classIndex] = {
      ...updatedClassDetails[classIndex],
      schedule: updatedSchedule
    };

    updateTotalHoursAndEndDate(updatedClassDetails);
  };

  const handleDeleteClassDetail = (index) => {
    setNewClass((prevClass) => {
      const updatedClassDetails = [...prevClass.classDetails];
      updatedClassDetails.splice(index, 1);
      return {
        ...prevClass,
        classDetails: updatedClassDetails
      };
    });
  };
  

  const combinedEvents = (teacherId) => {
    return [
      ...(teacherSchedules[teacherId] || []).map(event => ({
        title: event.title, // Remove time from the title
        start: event.start,
        end: event.end,
        type: 'schedule',
      })),
      ...(teacherClasses[teacherId] || []).map(cls => ({
        title: cls.title,
        start: cls.start,
        end: cls.end,
        type: 'class',
      }))
    ];
  };
  

  const calculateOverallStartDate = (classDetails) => {
    let earliestDate = null;
  
    classDetails.forEach(detail => {
      detail.schedule.forEach(slot => {
        if (slot.start_date) {
          const slotDate = new Date(slot.start_date);
          if (!earliestDate || slotDate < earliestDate) {
            earliestDate = slotDate;
          }
        }
      });
    });
  
    return earliestDate ? earliestDate.toISOString().split('T')[0] : null;
  };

  const calculateRecurrenceDates = (slot) => {
    if (!slot.start_date) {
      console.error('No start date provided for slot:', slot);
      return [];
    }
  
    let dates = [];
    const startDate = moment(slot.start_date);
  
    if (slot.recurring === 'weekly') {
      for (let i = 0; i < slot.number_of_recurrences; i++) {
        dates.push(startDate.clone().add(i, 'weeks').format('YYYY-MM-DD'));
      }
    } else if (slot.recurring === 'bi-weekly') {
      for (let i = 0; i < slot.number_of_recurrences; i++) {
        dates.push(startDate.clone().add(i * 2, 'weeks').format('YYYY-MM-DD'));
      }
    } else if (slot.recurring === 'daily') {
      for (let i = 0; i < slot.number_of_recurrences; i++) {
        dates.push(startDate.clone().add(i, 'days').format('YYYY-MM-DD'));
      }
    } else {
      dates.push(startDate.format('YYYY-MM-DD'));
    }
  
    return dates;
  };
  

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div>
      <Sidebar />
      <Header />
      <div>
        <div className="form-and-preview">
        <div className="new-class-container">
            <h2>{t('createNewCourse')}</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="class_code">{t('classCode')}</label>
                  <input
                    type="text"
                    name="class_code"
                    value={newClass.class_code}
                    onChange={handleNewClassChange}
                    placeholder={t('classCode')}
                    required
                  />
                </div>
              </div>

              {newClass.classDetails.map((detail, index) => (
                <div className="form-wrap" key={index}>
                          <div className="form-row">
                        <div className="form-group">
                          <label htmlFor={`subject_id-${index}`}>{t('subject')}</label>
                          <Select
                            name={`subject_id-${index}`}
                            value={subjects
                              .map((subject) => ({
                                value: subject.subjectid,
                                label: subject.title,
                              }))
                              .find((option) => option.value === detail.subject_id) || null}
                            onChange={(selectedOption) =>
                              handleNewClassChange(
                                { target: { name: "subject_id", value: selectedOption?.value } },
                                index
                              )
                            }
                            options={subjects.map((subject) => ({
                              value: subject.subjectid,
                              label: subject.title,
                            }))}
                            placeholder={t('Search or Select a Subject')}
                            isClearable
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor={`teacher_id-${index}`}>{t('teacher')}</label>
                          <Select
                            name={`teacher_id-${index}`}
                            value={teachers
                              .map((teacher) => ({
                                value: teacher.userid,
                                label: `${teacher.nickname} ${teacher.firstname}`,
                              }))
                              .find((option) => option.value === detail.teacher_id) || null}
                            onChange={(selectedOption) =>
                              handleNewClassChange(
                                { target: { name: "teacher_id", value: selectedOption?.value } },
                                index
                              )
                            }
                            options={teachers.map((teacher) => ({
                              value: teacher.userid,
                              label: `${teacher.nickname} ${teacher.firstname}`,
                            }))}
                            placeholder={t('Search or Select a Tutor')}
                            isClearable
                          />
                        </div>
                      </div>

                  <div className="form-row">
                    <button
                      type="button"
                      onClick={() => debouncedHandleAddScheduleSlot(index)}
                      className="add-time-slot"
                    >
                      {t('addTimeSlot')}
                    </button>
                  </div>

                  {detail.schedule.map((slot, scheduleIndex) => (
                      <div className="form-row schedule-slot" key={scheduleIndex}>
                        {/* Column 1: Day and Time */}
                        <div className="form-group">
                          <label htmlFor={`day-${index}-${scheduleIndex}`}>{t('day')}</label>
                          <select
                            name="day"
                            value={slot.day}
                            onChange={(e) => handleScheduleChange(index, scheduleIndex, 'day', e.target.value)}
                            required
                          >
                            <option value="">{t('selectDay')}</option>
                            <option value="Monday">{t('monday')}</option>
                            <option value="Tuesday">{t('tuesday')}</option>
                            <option value="Wednesday">{t('wednesday')}</option>
                            <option value="Thursday">{t('thursday')}</option>
                            <option value="Friday">{t('friday')}</option>
                            <option value="Saturday">{t('saturday')}</option>
                            <option value="Sunday">{t('sunday')}</option>
                          </select>
                        </div>
                        
                        <div className="form-group">
                          <label htmlFor={`time-${index}-${scheduleIndex}`}>{t('time')}</label>
                          <input
                            type="time"
                            name="time"
                            value={slot.time}
                            onChange={(e) => handleScheduleChange(index, scheduleIndex, 'time', e.target.value)}
                            required
                            min="06:00"
                            max="21:00"
                          />
                        </div>
                        
                        {/* Column 2: Hours and Minutes */}
                        <div className="form-group">
                          <label htmlFor={`hours-${index}-${scheduleIndex}`}>{t('hours')}</label>
                          <select
                            name="hours"
                            value={slot.hours || '0'}
                            onChange={(e) => handleScheduleChange(index, scheduleIndex, 'hours', e.target.value)}
                            required
                          >
                            {hourOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        
                        <div className="form-group">
                          <label htmlFor={`minutes-${index}-${scheduleIndex}`}>{t('minutes')}</label>
                          <select
                            name="minutes"
                            value={slot.minutes || '0'}
                            onChange={(e) => handleScheduleChange(index, scheduleIndex, 'minutes', e.target.value)}
                            required
                          >
                            {minuteOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        
                        {/* Column 3: Recurring and Recurrences */}
                        <div className="form-group">
                          <label htmlFor={`recurring-${index}-${scheduleIndex}`}>{t('recurring')}</label>
                          <select
                            name="recurring"
                            value={slot.recurring || 'none'}
                            onChange={(e) => {
                              handleScheduleChange(index, scheduleIndex, 'recurring', e.target.value);
                              if (e.target.value === 'none') {
                                handleScheduleChange(index, scheduleIndex, 'number_of_recurrences', 1); // Set recurrences to 1 when 'none'
                              }
                            }}
                          >
                            <option value="none">{t('oneClass')}</option>
                            <option value="daily">{t('daily')}</option>
                            <option value="weekly">{t('weekly')}</option>
                            <option value="bi-weekly">{t('biWeekly')}</option> {/* Added bi-weekly option */}
                          </select>
                        </div>
                        
                        <div className="form-group">
                          <label htmlFor={`number_of_recurrences-${index}-${scheduleIndex}`}>
                            {t('numberOfRecurrences')}
                          </label>
                          <input
                            name="number_of_recurrences"
                            value={slot.recurring === 'none' ? 1 : slot.number_of_recurrences}  // Force 1 when recurring is 'none'
                            onChange={(e) => handleScheduleChange(index, scheduleIndex, 'number_of_recurrences', e.target.value)}
                            min="1"
                            required
                            disabled={slot.recurring === 'none'}  
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor={`start_date-${index}-${scheduleIndex}`}>{t('startDate')}</label>
                          <input
                            type="date"
                            name="start_date"
                            value={slot.start_date || ''}
                            onChange={(e) => handleScheduleChange(index, scheduleIndex, 'start_date', e.target.value)}
                            required
                          />
                        </div>

                        
                        <div>
                          <button
                            type="button"
                            onClick={() => handleDeleteScheduleSlot(index, scheduleIndex)}
                            className="delete-button"
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </div>
                      </div>
                    ))}


                  <button type="button" onClick={() => handleDeleteClassDetail(index)} className="delete-time-slot">
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              ))}

              <button type="button" onClick={handleAddClassDetail} className="add-class-detail">
                {t('addAnotherSubjectTeacher')}
              </button>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="start_date">{t('startDate')}</label>
                  <input
                    type="date"
                    name="start_date"
                    value={newClass.start_date}
                    onChange={handleNewClassChange}
                    placeholder={t('startDate')}
                    required
                  />
                </div>
                <div className="form-group end-date">
                  <label htmlFor="end_date">{t('endDate')}</label>
                  <input type="date" name="end_date" value={newClass.end_date} readOnly placeholder={t('endDate')} />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group total-hours">
                  <label htmlFor="total_hours">{t('totalHours')}</label>
                  <input name="total_hours" value={(newClass.total_hours || 0).toFixed(2)} placeholder={t('totalHours')} readOnly />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="classroom_id">{t('selectClassroom')}</label>
                  <select name="classroom_id" value={newClass.classroom_id} onChange={handleNewClassChange}>
                    <option value="">{t('selectClassroom')}</option>
                    {classrooms.map((classroom) => (
                      <option key={classroom.classroomid} value={classroom.classroomid}>
                        {classroom.number}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="book_details_materials">{t('Notes')}</label>
                  <textarea name="book_details_materials" value={newClass.book_details_materials} onChange={handleNewClassChange} placeholder={t('Notes')}></textarea>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="price">{t('price')}</label>
                  <input name="price" value={newClass.price} onChange={handleNewClassChange} placeholder={t('price')} required min="0" />
                </div>

                <div className="form-group">
                  <label htmlFor="register_capacity">{t('registerCapacity')}</label>
                  <input name="register_capacity" value={newClass.register_capacity} onChange={handleNewClassChange} placeholder={t('registerCapacity')} required min="0" />
                </div>
              </div>

              <div className="form-row checkbox-container">
                <label>
                  <input type="checkbox" name="isprivate" checked={newClass.isprivate} onChange={() => setNewClass({ ...newClass, isprivate: !newClass.isprivate })} />
                  <span className="checkmark"></span>
                  <h3>{t('checkPrivateClass')}</h3>
                </label>
              </div>

              <div className="form-row">
                <button type="submit" className="submit-button">{t('createCourse')}</button>
              </div>
            </form>
          </div>

          <div className="teacher-calendar-container">
            {newClass.classDetails.map((detail, index) => (
              <div key={index}>
                {showTeacherCalendars[detail.teacher_id] && (
                  <div>
                    <h3>{t('teacher')} {teachers.find(teacher => teacher.userid === detail.teacher_id)?.firstname}</h3>
                    <FullCalendar
                      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                      initialView="timeGridWeek"
                      allDaySlot={false}
                      locale={i18n.language === 'th' ? thLocale : 'en'} // Apply the locale
                      headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay',
                      }}
                      events={combinedEvents(detail.teacher_id)}
                      eventClassNames={(info) => {
                        if (info.event.extendedProps.type === 'class') {
                          return ['class-event'];
                        } else {
                          return ['schedule-event'];
                        }
                      }}
                      height={787}
                      slotMinTime="06:00:00"
                      slotMaxTime="20:00:00"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        {showConfirmationModal && (
  <div className="confirmation-modal">
    <div className="modal-content">
      <h2>{t('confirmClassDetails')}</h2>
      <table>
        <tbody>
          <tr>
            <td>
              <ul>
                {confirmingClass.classDetails
                  .flatMap((detail, classIndex) =>
                    detail.schedule.flatMap((slot, slotIndex) => {
                      const teacher = teachers.find(teacher => teacher.userid === Number(detail.teacher_id));
                      const subject = subjects.find(sub => sub.subjectid === Number(detail.subject_id));

                      const teacherName = teacher ? `${teacher.firstname} ${teacher.lastname}` : 'Unknown Teacher';
                      const subjectName = subject ? subject.title : 'Unknown Subject';

                      // Calculate all dates for this slot
                      const dates = calculateRecurrenceDates(slot);
                      
                      return dates.map((date, dateIndex) => {
                        const endTime = moment(slot.time, 'HH:mm')
                          .add(parseInt(slot.hours || 0, 10), 'hours')
                          .add(parseInt(slot.minutes || 0, 10), 'minutes')
                          .format('HH:mm');

                        return {
                          id: `${classIndex}-${slotIndex}-${dateIndex}`,
                          date: date,
                          time: moment(slot.time, 'HH:mm').format('HH:mm'),
                          endTime: endTime,
                          teacherName: teacherName,
                          subjectName: subjectName,
                          dateTimeForSort: moment(`${date} ${slot.time}`, 'YYYY-MM-DD HH:mm')
                        };
                      });
                    })
                  )
                  .sort((a, b) => a.dateTimeForSort.valueOf() - b.dateTimeForSort.valueOf())
                  .map(item => (
                    <li key={item.id}>
                      <strong>{t('date')}:</strong> {moment(item.date).format('DD-MMM-YYYY')},&nbsp;
                      <strong>{t('time')}:</strong> {item.time} - {item.endTime},&nbsp;
                      <strong>{t('tutor')}:</strong> {item.teacherName},&nbsp;
                      <strong>{t('subject')}:</strong> {item.subjectName}
                    </li>
                  ))}
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <button onClick={handleConfirmSubmit} className="confirm-button">
        {t('confirm')}
      </button>
      <button onClick={() => setShowConfirmationModal(false)} className="modal-close-icon">
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  </div>
)}

      </div>
      {confirmationMessage && (
          <Modal isOpen={true} onClose={() => setConfirmationMessage('')}>
            <p className="confirmation-message">{confirmationMessage}</p>
          </Modal>
        )}
    </div>
  );
}

export default withRoleAccess(Classes, ['admin', 'superadmin']);
