import React from 'react';
import '../Dashboard.css';
import { confirmAlert } from 'react-confirm-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import 'react-confirm-alert/src/react-confirm-alert.css';

const CustomModal = ({ isOpen, onClose, onSubmit, reason, setReason, note, setNote }) => {
  if (!isOpen) return null;

  const handleSubmit = () => {
    onSubmit(reason, note); 
    confirmAlert({
      title: 'Event Updated',
      message: 'The event has been updated successfully.',
      buttons: [
        {
          label: 'Ok',
          onClick: () => {
            setReason(''); 
            setNote(''); 
            onClose(); 
          }
        }
      ]
    });
  };

  return (
    <div className="modal-overlay" style={{ zIndex: 1100 }}>
      <div className="modal-content">
      <div className="modal-close-icon" onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
        <h3>Select Reason for Change</h3>
        <select value={reason} onChange={(e) => setReason(e.target.value)}>
          <option value="">Select a reason</option>
          <option value="admin_adjust">Admin Adjust</option>
          <option value="cancelled_by_student">Cancelled by student (CL by S)</option>
          <option value="cancelled_by_teacher">Cancelled by teacher (CL by T)</option>
          <option value="class_on_hold_by_student">Class on hold by student</option>
          <option value="class_on_hold_by_school">Class on hold by school</option>
          <option value="others">Others</option>
        </select>

        <textarea
          className="non-resizable-textarea" // Add this class
          placeholder="Add a note"
          value={note}
          onChange={(e) => setNote(e.target.value)}
        ></textarea>

        <button className="fit-content-button" onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default CustomModal;
