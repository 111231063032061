import React, { useState, useEffect, useMemo } from "react";
import { Table, Select, Tabs, Tag, Button } from "antd";
import { ReloadOutlined } from '@ant-design/icons';
import axios from "axios";
import moment from "moment";
import DetailsModal from './DetailsModal';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from "react-i18next";

const { Option } = Select;

const ClassTable = () => {
    const generateTimeSlots = () => {
        const slots = [];
        for (let hour = 10; hour < 20; hour++) {
          for (let quarter = 0; quarter < 4; quarter++) {
            const startMinute = quarter * 15;
            let endHour = hour;
            let endMinute = startMinute + 15;
    
            if (endMinute === 60) {
              endMinute = 0;
              endHour += 1;
            }
    
            const startTimeStr = `${hour.toString().padStart(2, "0")}:${startMinute.toString().padStart(2, "0")}`;
            const endTimeStr = `${endHour.toString().padStart(2, "0")}:${endMinute.toString().padStart(2, "0")}`;
    
            slots.push({
              key: startTimeStr,
              label: startTimeStr,
              startTime: startTimeStr,
              endTime: endTimeStr,
              parentHour: hour,
            });
          }
        }
        return slots;
      };
    
      // Initialize state with generated timeSlots
      const { t, i18n } = useTranslation();
      const [timeSlots, setTimeSlots] = useState(generateTimeSlots());
      const [teachers, setTeachers] = useState([]);
      const [classrooms, setClassrooms] = useState([]);
      const [selectedTeacher, setSelectedTeacher] = useState("");
      const [classes, setClasses] = useState([]);
      const [teacherEvents, setTeacherEvents] = useState([]);
      const [selectedYear, setSelectedYear] = useState(moment().year());
      const [currentMonth, setCurrentMonth] = useState(moment().startOf("month"));
      const [showModal, setShowModal] = useState(false);
      const [modalData, setModalData] = useState(null);
      const [loading, setLoading] = useState(false);
      const [subjects, setSubjects] = useState([]);
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [changeReason, setChangeReason] = useState('');
      const [changeNote, setChangeNote] = useState('');
      const [pendingUpdate, setPendingUpdate] = useState(null);
      const [editValues, setEditValues] = useState({
        date: null,
        teacherId: '',
        roomId: '',
        subjectId: '',
        scheduleTime: '', 
        scheduleHour: '', 
      });
      
      const [editMode, setEditMode] = useState({
        date: false,
        teacher: false,
        room: false,
        subject: false,
        time: false, 
      });

  const API_URL = process.env.REACT_APP_API_URL;

  // Generate Time Slots (static)
  useEffect(() => {
    const slots = [];
    for (let hour = 6; hour < 21; hour++) {
      for (let quarter = 0; quarter < 4; quarter++) {
        const startMinute = quarter * 15;
        let endHour = hour;
        let endMinute = startMinute + 15;

        // Adjust for rollover to the next hour
        if (endMinute === 60) {
          endMinute = 0; // Reset to 0
          endHour += 1;  // Increment the hour
        }

        slots.push({
          key: `${hour.toString().padStart(2, "0")}:${startMinute
            .toString()
            .padStart(2, "0")}-${endHour.toString().padStart(2, "0")}:${endMinute
              .toString()
              .padStart(2, "0")}`,
          label: `${hour.toString().padStart(2, "0")}:${startMinute
            .toString()
            .padStart(2, "0")} - ${endHour.toString().padStart(2, "0")}:${endMinute
              .toString()
              .padStart(2, "0")}`,
          parentHour: hour,
        });
      }
    }

    setTimeSlots(slots);
  }, []);

  const timeIntervals = [
    { value: 30, label: '30 min' },
    { value: 45, label: '45 min' },
    { value: 60, label: '1 hour' },
    { value: 75, label: '1:15 hour' },
    { value: 90, label: '1:30 hour' },
    { value: 105, label: '1:45 hour' },
    { value: 120, label: '2 hours' },
    { value: 135, label: '2:15 hours' },
    { value: 150, label: '2:30 hours' },
    { value: 165, label: '2:45 hours' },
    { value: 180, label: '3 hours' },
  ];
  

   const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
    };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };

  const getDayOfWeek = (dateString) => {
    const date = new Date(dateString);
    const daysOfWeek = [
      t('Sunday'),
      t('Monday'),
      t('Tuesday'),
      t('Wednesday'),
      t('Thursday'),
      t('Friday'),
      t('Saturday')
    ];
    return daysOfWeek[date.getDay()];
  };
  

  // Fetch Data
  useEffect(() => {
    fetchSubjects();
    fetchTeachers();
    fetchClassrooms();
  }, []);

  useEffect(() => {
    fetchClassesAndEvents();
  }, [currentMonth, selectedTeacher]);

  const fetchTeachers = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/teachers`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTeachers(response.data.filter((teacher) => teacher.status === "active"));
    } catch (error) {
      console.error("Error fetching teachers:", error);
    }
  };

  const fetchSubjects = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/subjects`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      const sortedSubjects = response.data
        .filter((subject) => subject.title) 
        .sort((a, b) => a.title.localeCompare(b.title));
  
      setSubjects(sortedSubjects); 

    } catch (error) {
      console.error('Error fetching subjects:', error);
    }
  };

  const fetchClassrooms = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/classrooms`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const classroomsData = response.data || [];
      const availableClassrooms = classroomsData
        .filter(classroom => classroom.availability === true)
        .sort((a, b) => {
          // Sort numerically by room number
          return Number(a.number) - Number(b.number);
        });
      setClassrooms(availableClassrooms);
    } catch (error) {
      console.error('Error fetching classrooms:', error);
    }
  };

  const fetchClassesAndEvents = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const startDate = currentMonth.format("YYYY-MM-DD");
      const endDate = currentMonth.clone().endOf("month").format("YYYY-MM-DD");

      const [classResponse, eventResponse] = await Promise.all([
        axios.get(`${API_URL}/classes`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { startDate, endDate },
        }),
        axios.get(
          selectedTeacher
            ? `${API_URL}/teachers/${selectedTeacher}/events`
            :
          {
            headers: { Authorization: `Bearer ${token}` },
            params: { startDate, endDate },
          }
        ),
      ]);

      const formattedClasses = (classResponse.data || []).map((cls) => ({
        ...cls,
        type: "class",
      }));

      const formattedEvents = (eventResponse.data || []).map((evt) => ({
        ...evt,
        type: "event",
      }));

      setClasses(formattedClasses);
      setTeacherEvents(formattedEvents);
    } catch (error) {
      console.error("Error fetching data:", error);
      setClasses([]);
      setTeacherEvents([]);
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    setLoading(true);
    try {
      await Promise.all([fetchTeachers(), fetchSubjects(), fetchClassrooms(), fetchClassesAndEvents()]);
    } catch (error) {
      console.error("Error refreshing data:", error);
    } finally {
      setLoading(false);
    }
  };

  
  const handleEdit = (field) => {
    setEditMode({ ...editMode, [field]: true });
    setPendingUpdate({ field }); 
  
  if (field === 'time') {
    setEditValues({
      ...editValues,
      scheduleTime: modalData.data.schedule_time,
      scheduleHour: modalData.data.schedule_hour
    });
  }
    
    if (field === 'subject') {
      const currentSubject = subjects.find(
        subject => subject.title === modalData.data.subject_name
      );
      setEditValues({
        ...editValues,
        subjectId: currentSubject?.subject_id || undefined
      });
    } else {
      setEditValues({
        ...editValues,
        [field]: field === 'date' 
          ? moment(modalData.data.date).format('YYYY-MM-DD')
          : field === 'teacher' 
            ? modalData.data.teacher_id 
            : field === 'room'
              ? modalData.data.classroom_number
              : undefined
      });
    }
  };


  const calculateEndTimeLog = (startTime, hours) => {
    let durationInMinutes;
  
    if (typeof hours === "string" && hours.includes(":")) {
      // If "1:30" format, split into hours and minutes
      const [h, m] = hours.split(":");
      durationInMinutes = parseFloat(h) * 60 + parseFloat(m);
    } else if (typeof hours === "number" || !isNaN(parseFloat(hours))) {
      // If "90" (minutes) or "1.5" (hours), determine duration
      const parsedHours = parseFloat(hours);
      durationInMinutes = parsedHours > 10 ? parsedHours : parsedHours * 60; // Interpret large numbers as minutes
    } else {
      throw new Error(`Invalid schedule_hour format: ${hours}`);
    }
  
    // Start from the given time
    const startMoment = moment(startTime, "HH:mm");
  
    // Add the calculated minutes
    const endMoment = startMoment.clone().add(durationInMinutes, "minutes");
  
    // Return in HH:mm:ss format
    return endMoment.format("HH:mm:ss");
  };
  

  const handleSave = async (field) => {
    // Store the update details and open the modal
    setPendingUpdate({ field, updateData: { ...editValues } });
    setIsModalOpen(true);
  };

  
  const handleUpdateSubmit = async (reason, note) => {
    try {
      const token = localStorage.getItem("token");
      const { field } = pendingUpdate;
      const updateData = {};
  
      // Prepare the update data
      switch (field) {
        case "date":
          updateData.date = editValues.date;
          break;
        case "time":
          updateData.schedule_time = editValues.scheduleTime;
          updateData.schedule_hour = editValues.scheduleHour;
          break;
        case "teacher":
          updateData.teacher_id = editValues.teacherId;
          break;
        case "room":
          updateData.classroom_id = editValues.roomId;
          break;
        case "subject":
          updateData.subjectid = editValues.subjectId;
          break;
      }
  
      // Send the PUT request to update the class
      await axios.put(
        `${API_URL}/classes/class-table/${modalData.data.classid}`,
        updateData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      // Update `modalData` to reflect changes immediately in the UI
      setModalData((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          ...updateData,
          end_time:
            field === "time"
              ? calculateEndTimeLog(updateData.schedule_time, updateData.schedule_hour)
              : prev.data.end_time, // Update end_time if the time changes
        },
      }));
  
      // Log the changes (if needed)
      const logData = {
        classid: modalData.data.classid,
        teacherid: modalData.data.teacher_id,
        changereason: reason,
        note: note,
      };
  
      if (field === "date" || field === "time") {
     
        logData.originaldate = moment(modalData.data.date).format("YYYY-MM-DD");
        logData.originalstarttime = moment(modalData.data.schedule_time, ["HH:mm", "HH:mm:ss"]).format("HH:mm:ss");
        logData.originalendtime = moment(modalData.data.end_time, ["HH:mm", "HH:mm:ss"]).format("HH:mm:ss");
      
        if (field === "date") {
          logData.newdate = moment(updateData.date).format("YYYY-MM-DD");
          logData.newstarttime = logData.originalstarttime;
          logData.newendtime = logData.originalendtime;
        } else if (field === "time") {
          const startTime = moment(updateData.schedule_time, "HH:mm");
          let hours = updateData.schedule_hour;
      
          if (typeof hours === "string" && hours.includes(":")) {
            const [h, m] = hours.split(":");
            hours = parseFloat(h) + parseFloat(m) / 60;
          } else if (!isNaN(parseFloat(hours))) {
            const parsedHours = parseFloat(hours);
            hours = parsedHours > 10 ? parsedHours / 60 : parsedHours;
          }
      
          const endTime = calculateEndTimeLog(updateData.schedule_time, hours);
      
          logData.newdate = logData.originaldate;
          logData.newstarttime = startTime.format("HH:mm:ss");
          logData.newendtime = endTime;
          logData.newhour = hours.toFixed(2);
        }
      } else {
        // If the field is teacher, subject, or room, log the same date and time details
        logData.originaldate = moment(modalData.data.date).format("YYYY-MM-DD");
        logData.originalstarttime = moment(modalData.data.schedule_time, ["HH:mm", "HH:mm:ss"]).format("HH:mm:ss");
        logData.originalendtime = moment(modalData.data.end_time, ["HH:mm", "HH:mm:ss"]).format("HH:mm:ss");
      
        // Keep newdate, newstarttime, and newendtime the same as the original
        logData.newdate = logData.originaldate;
        logData.newstarttime = logData.originalstarttime;
        logData.newendtime = logData.originalendtime;
      
                                      
      }
            
  
      if (validateLogData(logData)) {
        await axios.post(`${API_URL}/class-change/newlogs`, logData, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }
  
      // Refetch data to ensure everything is synced
      await fetchClassesAndEvents();
  
      // Reset states
      setEditMode({ ...editMode, [field]: false });
      setPendingUpdate(null);
      setChangeReason("");
      setChangeNote("");
  
      // Close the modal
      closeModal();
  

    } catch (error) {
      console.error("Error updating class:", error);
    }
  };
  
  
  // Add validation before sending
  const validateLogData = (logData) => {
    try {
      const requiredFields = [
        "originaldate",
        "originalstarttime",
        "originalendtime",
        "newdate",
        "newstarttime",
        "newendtime",
      ];
  
      requiredFields.forEach((field) => {
        if (!logData[field]) {
          throw new Error(`Missing required field: ${field}`);
        }
  
        if (field.includes("time")) {
          // Validate time format
          if (!moment(logData[field], ["HH:mm", "HH:mm:ss"], true).isValid()) {
            throw new Error(`Invalid time format for field: ${field}`);
          }
        }
  
        if (field.includes("date")) {
          // Validate date format
          if (!moment(logData[field], "YYYY-MM-DD", true).isValid()) {
            throw new Error(`Invalid date format for field: ${field}`);
          }
        }
      });
  
      return true;
    } catch (error) {
      console.error("Validation Error:", error.message);
      throw error;
    }
  };
  

  const calculateSlotSpan = (item, currentDate, timeSlots, slotIndex) => {
    let spanCount = 1;
    let nextSlotIndex = slotIndex + 1;
  
    while (nextSlotIndex < timeSlots.length) {
      const nextSlot = timeSlots[nextSlotIndex];
      const isInSlot = item.type === 'class' 
        ? isClassInTimeSlot(item, currentDate, nextSlot.key)
        : isTeacherEventInTimeSlot(item, currentDate, nextSlot.key);
      
      if (!isInSlot) break;
      spanCount++;
      nextSlotIndex++;
    }
  
    return spanCount;
  };

  // Helper Functions
  const isClassInTimeSlot = (classItem, date, slotKey) => {
    if (!slotKey) {
      console.error("slotKey is undefined or null:", slotKey);
      return false;
    }

    const [slotStart, slotEnd] = slotKey.split("-");
    const classDate = moment(classItem.date).format("YYYY-MM-DD");
    if (classDate !== date) return false;

    const classStartTime = moment(`${classDate} ${classItem.schedule_time}`);
    const classEndTime = moment(`${classDate} ${classItem.end_time}`);
    const slotStartTime = moment(`${date} ${slotStart}`);
    const slotEndTime = moment(`${date} ${slotEnd}`);

    return (
      classStartTime.isBefore(slotEndTime) && classEndTime.isAfter(slotStartTime)
    );
  };

  const isTeacherEventInTimeSlot = (event, date, slotKey) => {
    if (!slotKey) {
      console.error("slotKey is undefined or null:", slotKey);
      return false;
    }

    const [slotStart, slotEnd] = slotKey.split("-");
    const eventDate = moment(event.start).format("YYYY-MM-DD");
    if (eventDate !== date) return false;

    const eventStart = moment(event.start);
    const eventEnd = moment(event.end);
    const slotStartTime = moment(`${date} ${slotStart}`);
    const slotEndTime = moment(`${date} ${slotEnd}`);

    return eventStart.isBefore(slotEndTime) && eventEnd.isAfter(slotStartTime);
  };
 const showDetails = (data, type) => {
    setModalData({ data, type });
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setModalData(null);
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
    setCurrentMonth(moment().year(year).startOf("year"));
  };

  const handleMonthChange = (month) => {
    setCurrentMonth(moment().year(selectedYear).month(month));
  };

  const tableData = useMemo(() => {
    if (!timeSlots || timeSlots.length === 0) {
      console.error("timeSlots is empty or undefined.");
      return [];
    }
  
    const daysInMonth = Array.from(
      { length: currentMonth.daysInMonth() },
      (_, index) => index + 1
    );
  
    return daysInMonth.map((day) => {
      const currentDate = moment(currentMonth).date(day).format("YYYY-MM-DD");
  
      const dayClasses = classes.filter(
        (classItem) =>
          moment(classItem.date).format("YYYY-MM-DD") === currentDate &&
          (!selectedTeacher || classItem.teacher_id === selectedTeacher)
      );
  
      const dayEvents = teacherEvents.filter(
        (event) =>
          moment(event.start).format("YYYY-MM-DD") === currentDate &&
          (!selectedTeacher || event.userid === selectedTeacher)
      );
  
      const row = {
        key: day,
        date: currentDate,
      };
  
      // Track processed `classid` and `eventid` to avoid duplicates
      const processedItems = new Set();
  
      timeSlots.forEach(({ key }, slotIndex) => {
        if (!key) return;
  
        const slotItems = [];
  
        // Process classes
        dayClasses.forEach((classItem) => {
          const classId = classItem.classid;
          if (
            !processedItems.has(classId) &&
            isClassInTimeSlot(classItem, currentDate, key)
          ) {
            slotItems.push({
              ...classItem,
              type: 'class',
              colSpan: calculateSlotSpan(
                { ...classItem, type: 'class' },
                currentDate,
                timeSlots,
                slotIndex
              ),
            });
            processedItems.add(classId);
          }
        });
  
        // Process events
        dayEvents.forEach((event) => {
          const eventId = event.eventid;
          if (
            !processedItems.has(eventId) &&
            isTeacherEventInTimeSlot(event, currentDate, key)
          ) {
            slotItems.push({
              ...event,
              type: 'event',
              colSpan: calculateSlotSpan(
                { ...event, type: 'event' },
                currentDate,
                timeSlots,
                slotIndex
              ),
            });
            processedItems.add(eventId);
          }
        });
  
        if (slotItems.length > 0) {
          row[key] = {
            items: slotItems,
          };
        }
      });
  
      return row;
    });
  }, [currentMonth, classes, teacherEvents, timeSlots, selectedTeacher]);


  const columns = useMemo(() => {
    const groupedColumns = [
      {
        title: t("Date"),
        dataIndex: "date",
        key: "date",
        fixed: "left",
        width: 120, // Increased width to accommodate the tag
        render: (date) => (
          <div className="date-cell">
            <Tag color="blue" style={{ marginBottom: '4px' }}>
              {moment(date).format("dddd")}
            </Tag>
            <br />
            {moment(date).format("DD MMM YY")}
          </div>
        ),
      },
    ];
  
    const hourGroups = {};
  
    timeSlots.forEach((slot) => {
      if (!hourGroups[slot.parentHour]) {
        hourGroups[slot.parentHour] = {
          title: `${slot.parentHour.toString().padStart(2, "0")}:00 - ${(
            slot.parentHour + 1
          )
            .toString()
            .padStart(2, "0")}:00`,
          children: [],
        };
      }

      const minute = parseInt(slot.label.split(":")[1]);
      const minuteLabel = minute === 0 ? "00" : minute.toString();
  
      hourGroups[slot.parentHour].children.push({
        title: (
            <span className="time-label">
              {minuteLabel}
            </span>
          ),
        dataIndex: slot.key,
        key: slot.key,
        width: 40, 
        render: (cellData) => {
          if (!cellData?.items?.length) return null;
  
          return (
            <div className="time-slot-container">
              {cellData.items.map((item, index) => {
                // Convert hex color to RGB for transparency
                const hexToRgb = (hex) => {
                  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
                  return result ? {
                    r: parseInt(result[1], 16),
                    g: parseInt(result[2], 16),
                    b: parseInt(result[3], 16)
                  } : null;
                };
  
                const getBackgroundColor = (color) => {
                  if (!color) return item.type === "class" ? "#055083" : "rgba(248, 131, 121, 0.7)";
                  const rgb = hexToRgb(color);
                  return rgb ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.15)` : null;
                };
  
                const getBorderColor = (color) => {
                  if (!color) return item.type === "class" ? "rgba(129, 199, 132, 0.5)" : "rgba(255, 167, 38, 0.5)";
                  const rgb = hexToRgb(color);
                  return rgb ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.5)` : null;
                };
  
                return (
                  <div
                    key={`${item.type}-${item.id}-${index}`}
                    className={`time-slot-item ${item.type}-item`}
                    style={{
                      width: `${item.colSpan * 57}px`,
                      backgroundColor: getBackgroundColor(item.color),
                      borderColor: getBorderColor(item.color),
                    }}
                    onClick={() => showDetails(item, item.type)}
                  >
                    {item.type === "class" ? (
                      <div className="item-content">
                        <div className="item-header">
                        <span className="item-time">
                        {moment(item.schedule_time, "HH:mm:ss").format("HH:mm")} - {moment(item.end_time, "HH:mm:ss").format("HH:mm")}
                          </span>
                          <small className="class-id">{item.classid}</small><span className="item-code">{item.class_code} </span>

                        </div>
                        <div className="item-body">
                          <div className="item-subject">{item.subject_name}  |  Room: {item.classroom_number}</div>
                        </div>
                      </div>
                    ) : (
                      <div className="item-content event-content">
                        <div className="item-header">
                          <span className="item-title">{item.title}</span>
                          <span className="item-time">
                            {moment(item.start).format("HH:mm")} -{" "}
                            {moment(item.end).format("HH:mm")}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        },
      });
    });
  
    Object.values(hourGroups).forEach((group) => {
      groupedColumns.push(group);
    });
  
    return groupedColumns;
  }, [timeSlots, t, showDetails]);

 

  return (
    <div style={{ padding: "20px" }}>
    <div style={{ marginBottom: "20px" }}>
      <Select
        placeholder={t('Select Teacher')}
        style={{ width: 300 }}
        value={selectedTeacher}
        onChange={setSelectedTeacher}
        allowClear
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => {
          const optionText = option.children || "";
          return optionText.toString().toLowerCase().includes(input.toLowerCase());
        }}
      >
        <Option value="">{t('Please Select a Teacher')}</Option>
        {teachers.map((teacher) => (
          <Option key={teacher.userid} value={teacher.userid}>
            {`${teacher.nickname} ${teacher.firstname}`}
          </Option>
        ))}
      </Select>
  
      <Select
        placeholder={t("Select Year")}
        style={{ width: 150, marginLeft: "20px" }}
        value={selectedYear}
        onChange={handleYearChange}
      >
        {Array.from({ length: 6 }, (_, index) => moment().year() + index).map(
          (year) => (
            <Option key={year} value={year}>
              {year}
            </Option>
          )
        )}
      </Select>
      <Button
        icon={<ReloadOutlined />}
        style={{ width: 100, marginLeft: "20px" }}
        onClick={handleRefresh}
        loading={loading}
      >
        {t("Refresh")}
      </Button>
    </div>
    <div className="month-tabs-container">
      <Tabs
        activeKey={currentMonth.month().toString()}
        onChange={(key) => handleMonthChange(parseInt(key))}
        items={Array.from({ length: 12 }).map((_, index) => ({
          key: index.toString(),
          label: moment().month(index).format("MMM"),
        }))}
        tabBarStyle={{ marginBottom: 0 }}
      />
    </div>
    <style>
        {`
          .ant-table-cell {
            width: 40px !important;
            min-width: 40px !important;
            max-width: 40px !important;
            padding: 4px !important;
            position: relative;
            overflow: visible !important;
          }

          .time-slot-container {
            position: relative;
            min-height: 60px;
            width: 100%;
          }

          .time-slot-item {
            position: relative;  
            color: #ffffff;
            background-clip: padding-box;
            z-index: 1;
            margin: 0;
          }

          .time-slot-item:hover {
            transform: translateY(-2px);
            box-shadow: 0 3px 6px rgba(0,0,0,0.15);
            z-index: 2;
          }

          .item-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            padding: 2px 4px;
          }

          .item-time {
            margin-right: 4px;
            flex-shrink: 0;
          }

          .item-header {
            display: flex; 
            align-items: center; 
            gap: 4px; 
            overflow: hidden;
          }
          .item-code {
            flex-grow: 1; 
            white-space: nowrap; 
            overflow: hidden; 
            text-overflow: ellipsis;
          }
          .ant-table-tbody > tr > td {
            overflow: visible !important;
          }

          .ant-table {
            overflow-x: auto !important;
          }

            .date-cell {
                padding: 8px;
                font-weight: 300;
                font-size: 12px;
                color: #374151;
                width: 100px !important;
              }

              .time-label {
                color: #6B7280;  /* Grey color */
                font-weight: normal;
                font-size: 12px;
              }

              .ant-table-cell {
                width: 60px !important;
                min-width: 60px !important;
                max-width: 60px !important;
                padding: 4px !important;
                position: relative;
                overflow: visible !important;
              }

              /* Make the date column wider */
              .ant-table-cell:first-child {
                width: 150px !important;
                min-width: 150px !important;
                max-width: 150px !important;
              }

              /* Additional styles for header alignment */
              .ant-table-thead > tr > th {
                background-color: #fafafa;
                font-weight: 600;
                color: #1f2937;
                padding: 12px 8px;
                text-align: center;
              }

              .ant-table-thead > tr > th:first-child {
                text-align: left;
              }

                  .ant-table-header {
                position: sticky !important;
                top: 0;
                z-index: 2;
                margin-bottom: 0 !important; /* Remove default margin */
                padding-bottom: 0 !important; /* Remove default padding */
              }

              .ant-table-header table {
                margin: 0 !important;
              }

              .ant-table-sticky-holder {
                margin-top: 0 !important;
              }

              /* Fix the double border */
              .ant-table-header + .ant-table-body {
                border-top: none !important;
              }

              .ant-table-sticky-scroll {
                display: none !important;
              }

              /* Ensure no gap between header and body */
              .ant-table-container {
                border-top: none !important;
              }

              .ant-table-body {
                margin-top: 0 !important;
              }
        `}
      </style>

    {/* Conditional Rendering for the Table */}
    {selectedTeacher ? (
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        scroll={{ x: "max-content", y: "calc(100vh - 250px)" }}
        size="small"
        bordered
        sticky={{
          offsetHeader: 0,
          offsetScroll: 0,
        }}
      />
    ) : (
      <div style={{ marginTop: "20px", textAlign: "center", color: "#999" }}>
        {t("Please select a teacher to view the schedule.")}
      </div>
    )}
  
    <DetailsModal
      showModal={showModal}
      closeModal={closeModal}
      modalData={modalData}
      handleEdit={handleEdit}
      handleSave={handleSave}
      handleUpdateSubmit={handleUpdateSubmit}
      editMode={editMode}
      editValues={editValues}
      setEditValues={setEditValues}
      setEditMode={setEditMode}
      teachers={teachers}
      subjects={subjects}
      classrooms={classrooms}
      timeIntervals={timeIntervals}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      changeReason={changeReason}
      setChangeReason={setChangeReason}
      changeNote={changeNote}
      setChangeNote={setChangeNote}
      formatTime={formatTime}
      formatDate={formatDate}
      getDayOfWeek={getDayOfWeek}
    />
  </div>
  
    
  );
};


export default withRoleAccess(ClassTable, ['admin', 'superadmin']);