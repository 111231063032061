import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Form, Input, Button, Typography, Alert } from 'antd';
import PropTypes from 'prop-types';
import '../common.css';

const { Title } = Typography;

function SignIn({ setUserData }) {
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);

  const { signIn } = useAuth(); 
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;

  const handleSignIn = async (values) => {
    setEmailError('');
    setPasswordError('');
    setLoading(true);

    try {
      const { email, password } = values;
      const deviceName = navigator.userAgent;

      const response = await fetch(`${API_URL}/auth/signin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          email, 
          password, 
          deviceName 
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        const { accessToken, refreshToken, userId, role, isTempPassword } = responseData;

        localStorage.setItem('token', accessToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('userId', userId);
        localStorage.setItem('role', role);
        signIn(accessToken);
        setUserData({ userId, role });

        if (isTempPassword) {
          navigate('/change-password');
        } else {
          navigate('/dashboard');
        }
      } else {
        const errorResponse = await response.json();
        switch (errorResponse.error) {
          case 'UserNotFound':
            setEmailError('The email address is not registered.');
            break;
          case 'InvalidPassword':
            setPasswordError('The password is incorrect.');
            break;
          default:
            setEmailError('Email or password is incorrect.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setEmailError('Email or password is incorrect.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <div 
        style={{
          background: 'white',
          padding: '2rem',
          borderRadius: '8px',
          width: '100%',
          maxWidth: '400px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
        }}
      >
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
          <Title 
            level={2} 
            style={{ 
              margin: 0,
              fontSize: '24px'
            }}
          >
            Sign In
          </Title>
        </div>

        <Form
          layout="vertical"
          onFinish={handleSignIn}
          requiredMark="optional"
        >
          <Form.Item
            name="email"
            style={{ marginBottom: '1rem' }}
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Please enter a valid email!' },
            ]}
          >
            <Input 
              placeholder="Email" 
              size="large"
              style={{
                height: '45px',
                borderRadius: '4px'
              }}
            />
          </Form.Item>

          <Form.Item
            name="password"
            style={{ marginBottom: '1rem' }}
            rules={[
              { required: true, message: 'Please input your password!' }
            ]}
          >
            <Input.Password 
              placeholder="Password" 
              size="large"
              style={{
                height: '45px',
                borderRadius: '4px'
              }}
            />
          </Form.Item>

          {emailError && (
            <Alert
              message={emailError}
              type="error"
              showIcon
              style={{ marginBottom: '1rem' }}
            />
          )}
          {passwordError && (
            <Alert
              message={passwordError}
              type="error"
              showIcon
              style={{ marginBottom: '1rem' }}
            />
          )}

          <Form.Item>
            <Button 
              type="primary" 
              htmlType="submit" 
              block
              loading={loading}
              style={{
                height: '45px',
                borderRadius: '4px',
                fontSize: '16px',
                fontWeight: '500',
                backgroundColor: '#1677ff'
              }}
            >
              Sign In
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

SignIn.propTypes = {
  setUserData: PropTypes.func.isRequired,
};

export default SignIn;