import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from "moment";
import { 
  Row, 
  Col, 
  Card, 
  Avatar, 
  Table, 
  Tag, 
  Typography, 
  Space,
  Statistic
} from 'antd';
import { 
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  SafetyCertificateOutlined,
  TrophyOutlined,
  CrownOutlined,
  FieldTimeOutlined,
  GlobalOutlined,
  ReadOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  HourglassOutlined,
  BarChartOutlined
} from '@ant-design/icons';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import MonthlyHoursTrends from '../../components/MonthlyHoursTrends';
import CustomPieChart from '../../components/CustomPieChart';
import getUserIdFromToken from '../../Utils/authUtils';
import { useTranslation } from 'react-i18next';
import './Teacher.css';

const { Title, Text } = Typography;

function TeacherDashboard() {
  const { t, i18n } = useTranslation();
  const userId = getUserIdFromToken();
  const [teacher, setTeacher] = useState(null);
  const [classGroups, setClassGroups] = useState([]);
  const [error, setError] = useState(null);
  const [totalMonthlyHours, setTotalMonthlyHours] = useState(0);
  const [registrationsCount, setRegistrationsCount] = useState([]);
  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  };

  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  };

  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')} ${t('hr')}`;
  };

  const formatScheduleHour = (scheduleHourString) => {
    try {
      const [hours, minutes] = scheduleHourString.split(' ').map((value) => parseInt(value, 10));
      return `${hours}:${minutes.toString().padStart(2, '0')} ${t('hr')}`;
    } catch (error) {
      console.error('Invalid schedule hour value:', scheduleHourString);
      return 'Invalid Time';
    }
  };

  useEffect(() => {
    const fetchTeacherData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/teachers/${userId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setTeacher(response.data);
      } catch (error) {
        console.error('Error fetching teacher data:', error);
        setError(t('failedToFetchTeacherData'));
      }
    };

    const fetchTotalMonthlyHours = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/classes/teacher/totalhours/${userId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setTotalMonthlyHours(response.data.totalHours);
      } catch (error) {
        console.error('Error fetching total monthly hours:', error);
        setError(t('failedToFetchTotalMonthlyHours'));
      }
    };

    const fetchClassGroups = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/class-groups/teacher/${userId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setClassGroups(response.data);
      } catch (error) {
        console.error('Error fetching class groups:', error);
        setError(t('failedToFetchClassGroups'));
      }
    };

    const fetchRegistrationsCount = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/registrations/registrations-count`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setRegistrationsCount(response.data);
      } catch (error) {
        console.error('Error fetching registrations count:', error);
        setError(t('failedToFetchRegistrationsCount'));
      }
    };

    fetchTeacherData();
    fetchClassGroups();
    fetchRegistrationsCount();
    fetchTotalMonthlyHours();
  }, [userId, API_URL, t]);

  const getRegisteredStudents = (groupId) => {
    const group = registrationsCount.find(rc => rc.groupid === groupId);
    return group ? group.registered_students : 0;
  };

  const today = new Date().setHours(0, 0, 0, 0);

  // Separate class groups into future and past
  const futureClassGroups = classGroups.filter(
    classGroup => new Date(classGroup.start_date) >= today
  );
  const pastClassGroups = classGroups.filter(
    classGroup => new Date(classGroup.start_date) < today
  );

  // Sort the class groups
  const sortedFutureClassGroups = [...futureClassGroups].sort(
    (a, b) => new Date(a.start_date) - new Date(b.start_date)
  );
  const sortedPastClassGroups = [...pastClassGroups].sort(
    (a, b) => new Date(b.start_date) - new Date(a.start_date)
  );

  if (error) return <p>{error}</p>;
  if (!teacher) return <p>{t('loading')}</p>;

  const columns = [
    {
      title: t('classCode'),
      dataIndex: 'class_code',
      key: 'class_code',
      fixed: 'left',
      render: (class_code, record) => (
        <Link
          to={`/classdetails/${record.groupid}`}
          style={{ textDecoration: 'none', fontWeight: 'bold' }}
        >
          {class_code}
        </Link> 
      ),
    },
    {
      title: t('subjects'),
      dataIndex: 'subjects',
      key: 'subjects',
      render: subjects => subjects.map(subject => subject.title).join(', '),
    },
    {
      title: t('startDate'),
      dataIndex: 'start_date',
      key: 'start_date',
      render: formatDate,
    },
    {
      title: t('endDate'),
      dataIndex: 'end_date',
      key: 'end_date',
      render: formatDate,
    },
    {
      title: t('totalHours'),
      dataIndex: 'total_hours',
      key: 'total_hours',
      responsive: ['md'],
      render: formatScheduleHour,
    },
    {
      title: t('My Hours'),
      dataIndex: 'teacher_hours',
      key: 'teacher_hours',
      responsive: ['lg'],
    },
    {
      title: t('type'),
      dataIndex: 'isprivate',
      key: 'isprivate',
      render: isprivate => (
        <Tag color={isprivate ? 'blue' : 'green'}>
          {isprivate ? t('private') : t('group')}
        </Tag>
      ),
    },
    {
      title: t('registerCapacity'),
      dataIndex: 'register_capacity',
      key: 'register_capacity',
    },
    {
      title: t('registeredStudents'),
      dataIndex: 'groupid',
      key: 'groupid',
      render: getRegisteredStudents,
    },
  ];

  return (
    <div>
      <Sidebar />
      <Header />
      <div style={{ background: '#f0f2f5', minHeight: '100vh', width: '98%' }}>
        {/* Teacher Header */}
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Card>
              <Row justify="space-between" align="middle">
                <Col xs={24} sm={16}>
                  <Space align="start" size={16}>
                    <Avatar size={64} src={teacher.photourl} icon={<UserOutlined />} />
                    <Space direction="vertical" size={0}>
                      <Title level={4} style={{ margin: 0 }}>
                        {teacher.firstname} {teacher.lastname} ({teacher.nickname})
                      </Title>
                      <Text type="secondary">
                        <CalendarOutlined /> {t('dateJoined')}: {formatDate(teacher.createdat)}
                      </Text>
                      <Tag color={teacher.online ? 'success' : 'default'}>
                        {teacher.online ? t('online') : t('offline')}
                      </Tag>
                    </Space>
                  </Space>
                </Col>
                <Col xs={24} sm={8} style={{ textAlign: 'right' }}>
                  <Text type="secondary">
                    {t('lastSignedIn')}: {teacher.last_signed_in ? formatDateTime(teacher.last_signed_in) : 'N/A'}
                  </Text>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          <Col xs={24} lg={8}>
          <Card 
        title={
          <Space>
            <UserOutlined />
            <Text strong>{t('profile')}</Text>
          </Space>
        }
        bordered={false}
      >
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          {/* Personal Information Section */}
          <div>
            <Space direction="vertical" style={{ width: '100%' }} size="middle">
              <Row align="middle">
                <Col span={24}>
                  <Card size="small" bordered={false} style={{ background: '#f5f5f5' }}>
                    <Space>
                      <MailOutlined />
                      <Text type="secondary">{t('email')}:</Text>
                      <Text strong>{teacher.email}</Text>
                    </Space>
                  </Card>
                </Col>
              </Row>
              <Row align="middle">
                <Col span={24}>
                  <Card size="small" bordered={false} style={{ background: '#f5f5f5' }}>
                    <Space>
                      <PhoneOutlined />
                      <Text type="secondary">{t('phone')}:</Text>
                      <Text strong>{teacher.phone || t('notAvailable')}</Text>
                    </Space>
                  </Card>
                </Col>
              </Row>
            </Space>
          </div>

          <div>
            <Space direction="vertical" style={{ width: '100%' }} size="middle">
              {[
                { degree: 'bachelor', icon: <SafetyCertificateOutlined /> },
                { degree: 'master', icon: <TrophyOutlined /> },
                { degree: 'doctoral', icon: <CrownOutlined /> }
              ].map(({ degree, icon }) => (
                teacher[`${degree}Degree`] && (
                  <Card 
                    key={degree}
                    size="small"
                    bordered={false}
                    style={{ background: '#f5f5f5' }}
                  >
                    <Space align="start" style={{ width: '100%' }}>
                      {icon}
                      <div>
                        <Text type="secondary">{t(`${degree}Degree`)}:</Text>
                        <br />
                        <Text strong>{teacher[`${degree}Degree`]}</Text>
                        <br />
                        <Text type="secondary">{teacher[`${degree}_school`]}</Text>
                      </div>
                    </Space>
                  </Card>
                )
              ))}
            </Space>
            <Space direction="vertical" style={{ width: '100%' }} size="middle">
              <Card size="small" bordered={false} style={{ background: '#f5f5f5' }}>
                <Space align="start">
                  <GlobalOutlined />
                  <div>
                    <Text type="secondary">{t('Language')}:</Text>
                    <br />
                    <Space size={[0, 8]} wrap>
                      {Array.isArray(teacher.language) 
                        ? teacher.language.map(lang => (
                            <Tag key={lang} color="blue">{lang}</Tag>
                          ))
                        : <Text strong>{teacher.language || t('notAvailable')}</Text>
                      }
                    </Space>
                  </div>
                </Space>
                    </Card>

                    <Card size="small" bordered={false} style={{ background: '#f5f5f5' }}>
                      <Space align="start">
                        <ReadOutlined />
                        <div>
                          <Text type="secondary">{t('subjectsTaught')}:</Text>
                          <br />
                          <Space size={[0, 8]} wrap>
                            {Array.isArray(teacher.specialize_subjects)
                              ? teacher.specialize_subjects.map(subject => (
                                  <Tag key={subject} color="green">{subject}</Tag>
                                ))
                              : <Text strong>{teacher.specialize_subjects || t('noSubjects')}</Text>
                            }
                          </Space>
                        </div>
                      </Space>
                    </Card>
                  </Space>
                </div>
              </Space>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h2>{t('studentRating')}</h2>
                <CustomPieChart />
              </div>
            </Card>
            
          </Col>
          
          <Col xs={24} lg={16}>
          <Card bordered={false}>
          <Row gutter={[24, 24]}>
  {/* Statistics Row */}
  <Col xs={24}>
    <Row gutter={[16, 16]}>
      {/* Main Hours Card */}
      <Col xs={24} md={8}>
        <Card
          style={{
            background: 'linear-gradient(135deg, #1890ff, #096dd9)',
            color: 'white',
            borderRadius: '12px',
            overflow: 'hidden',
          }}
          bodyStyle={{ padding: '24px' }}
        >
          <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Row justify="space-between" align="middle">
              <Col>
                <Text style={{ color: 'rgba(255, 255, 255, 0.85)', fontSize: '16px' }}>
                  {t('Current Month Hours')}
                </Text>
              </Col>
              <Col>
                <ClockCircleOutlined style={{ fontSize: '24px', color: 'white' }} />
              </Col>
            </Row>
            
            <Statistic
              value={formatMinutesToHours(totalMonthlyHours)}
              valueStyle={{ 
                color: 'white', 
                fontSize: '36px',
                fontWeight: 'bold'
              }}
              prefix={<HourglassOutlined />}
            />



          </Space>
        </Card>
      </Col>

      {/* Average Hours/Week */}
      <Col xs={24} md={8}>
        <Card 
          bordered={false} 
          style={{ 
            background: 'linear-gradient(135deg, #fce3b4, #f9d69e)',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Statistic
            title={t('Average Hours/Week')}
            value={(totalMonthlyHours / (60 * 4)).toFixed(1)}
            suffix={t('hr')}
            prefix={<FieldTimeOutlined style={{ color: '#a17328 ' }} />}
            valueStyle={{ color: '#a17328 ', fontSize: '36px' }}
          />
        </Card>
      </Col>

       {/* Average Hours/Day */}
       <Col xs={24} md={8}>
  <Card 
    bordered={false} 
    style={{ 
      background: 'linear-gradient(135deg, #E8D3EF, #D7BDE2)',// Soft Lavender
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <Statistic
      title={t('Average Hours/Day')} // Updated title
      value={(totalMonthlyHours / (60 * moment().daysInMonth())).toFixed(2)} // Format to two decimal places
      suffix={t('hr')}
      prefix={<FieldTimeOutlined style={{ color: '#6F42C1' }} />} // Soft Violet Icon
      valueStyle={{ color: '#6F42C1', fontSize: '36px' }} // Matching Violet Text
    />
  </Card>
</Col>
    </Row>
  </Col>

  {/* Chart Row - Full Width */}
  <Col xs={24}>
    <Card
      title={
        <Space>
          <BarChartOutlined style={{ fontSize: '20px' }} />
          <span>{t('Monthly Hours Trend')}</span>
        </Space>
      }
      bordered={false}
      headStyle={{ borderBottom: 'none', fontSize: '16px' }}
    >
      <MonthlyHoursTrends teacherId={userId} />
    </Card>
  </Col>
</Row>
</Card>
          </Col>
        </Row>

        <div>
      {/* Future Classes Table */}
      <Card style={{ marginTop: 16 }}>
        <h3>{t('upcomingClasses')}</h3>
        <Table
          columns={columns}
          dataSource={sortedFutureClassGroups}
          rowKey="groupid"
          bordered
          scroll={{ x: 'max-content' }}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            showQuickJumper: true,
          }}
        />
      </Card>

      {/* Past Classes Table */}
      <Card style={{ marginTop: 16 }}>
        <h3>{t('All Classes')}</h3>
        <Table
          columns={columns}
          dataSource={sortedPastClassGroups}
          rowKey="groupid"
          bordered
          scroll={{ x: 'max-content' }}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            showQuickJumper: true,
          }}
        />
      </Card>
    </div>
      </div>
    </div>
  );
}

export default withRoleAccess(TeacherDashboard, ['superadmin', 'admin', 'teacher']);
