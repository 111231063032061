import React from 'react';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="version">v4.2.2</div>
        <div className="divider">|</div>
        <div className="company-info">
          <span>Powered by </span>
          <a 
            href="https://plann.tech" 
            target="_blank" 
            rel="noopener noreferrer"
            className="company-link"
          >
            PLANN TECH CO.,LTD
          </a>
        </div>
        <div className="divider">|</div>
        <a 
          href="mailto:info@plann.tech"
          className="email-link"
        >
          info@plann.tech
        </a>
        <div className="copyright">
          © {currentYear}
        </div>
      </div>
    </footer>
  );
};

export default Footer;