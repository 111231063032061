import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell, ResponsiveContainer } from 'recharts';
import { useTranslation } from 'react-i18next';
import withRoleAccess from '../hoc/withRoleAccess';

const formatMinutesToHours = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}h ${remainingMinutes}m`;
};

const MonthlyHoursTrends = ({ teacherId }) => {
  const { t } = useTranslation();
  const [hoursData, setHoursData] = useState([]);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchMonthlyHours = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${API_URL}/classes/teacher/monthlyhours/${teacherId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) throw new Error('Failed to fetch data');
        const data = await response.json();

        // Map month-year combinations for display
        const convertedData = data.map((item) => ({
          ...item,
          month: item.month_year, // Use the "Mon YYYY" format from the backend
          display_hours: item.total_hours / 60, // Convert minutes to hours for display
          original_minutes: item.total_hours, // Keep original value for tooltip
        }));

        setHoursData(convertedData);
      } catch (error) {
        console.error('Error fetching monthly hours:', error);
        setError(t('fetchMonthlyHoursError'));
      }
    };

    fetchMonthlyHours();
  }, [teacherId, API_URL, t]);

  if (error) return <p>{error}</p>;

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white border border-gray-200 shadow-lg rounded-lg p-3">
          <p className="text-sm font-medium text-gray-700 mb-1">{label}</p>
          <p className="text-sm text-gray-600">
            <span className="font-medium">Total Hours: </span>
            {formatMinutesToHours(payload[0].payload.original_minutes)}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={hoursData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="5 5" stroke="#f0f0f0" vertical={false} />
        <XAxis
          dataKey="month" // Use "month" as the key for the month-year format
          tick={{ fontSize: 12, fill: '#666' }}
          axisLine={{ stroke: '#e5e7eb' }}
          tickLine={{ stroke: '#e5e7eb' }}
        />
        <YAxis
          ticks={[0, 10, 20, 30, 40, 50]}
          domain={[0, 50]}
          tick={{ fontSize: 12, fill: '#666' }}
          axisLine={{ stroke: '#e5e7eb' }}
          tickLine={{ stroke: '#e5e7eb' }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend
          wrapperStyle={{ fontSize: 12, color: '#666' }}
          payload={[
            {
              value: 'Total Hours',
              type: 'rect',
              id: 'total_hours',
            },
          ]}
        />
        <Bar dataKey="display_hours" name="Total Hours" radius={[4, 4, 0, 0]}>
          {hoursData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={index % 2 === 0 ? '#a5d6b7' : '#b39ddb'}
              fillOpacity={0.8}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default withRoleAccess(MonthlyHoursTrends, ['admin', 'superadmin', 'teacher']);
