import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import withRoleAccess from '../../hoc/withRoleAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faUser, faChalkboardTeacher, faCheckSquare, faPalette, faSave } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import PasswordModal from './PasswordModal';
import EditTeacherModal from './EditTeacherModal';
import Modal from '../../components/Modal';
import '../Dashboard.css';
import { SketchPicker } from 'react-color';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

function EditDeleteTeacher({ onCreateNewTeacher }) {
  const { t } = useTranslation();
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [teacherIdToDelete, setTeacherIdToDelete] = useState(null);
  const [teacherIdToEdit, setTeacherIdToEdit] = useState(null);
  const [passwordError, setPasswordError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [colorPickerVisible, setColorPickerVisible] = useState(null); 
  const [selectedColor, setSelectedColor] = useState('#000000');
  const itemsPerPage = 50;
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;



  const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      // Generate a random number between 0 and 7 for a darker shade
      color += letters[Math.floor(Math.random() * 8)];
    }
    return color;
  };
  
  // Generate an array of random dark colors
  const darkColors = Array.from({ length: 40 }, () => generateRandomColor());



    const fetchTeachers = useCallback(async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError(t('noToken'));
          setLoading(false);
          return;
        }
        const response = await axios.get(`${API_URL}/teachers`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
  
        // Sort teachers by userid before setting the state
        const sortedTeachers = response.data.sort((a, b) => a.userid - b.userid);
        setTeachers(sortedTeachers);  // Update the state with sorted teachers
      } catch (error) {
        console.error('Error fetching teachers:', error);
        setError(t('fetchTeachersError'));
      } finally {
        setLoading(false);
      }
    }, [API_URL, t]);
  
    useEffect(() => {
      fetchTeachers();
    }, [fetchTeachers]); // Now fetchTeachers is included in the dependency array
  
    const saveTeacher = async (updatedTeacher) => {
      try {
        const token = localStorage.getItem('token');
        await axios.put(`${API_URL}/teachers/${updatedTeacher.userid}`, updatedTeacher, {
          headers: { Authorization: `Bearer ${token}` }
        });
  
        confirmAlert({
          title: t('success'),
          message: t('teacherUpdated'),
          buttons: [
            {
              label: 'OK',
              onClick: () => {},
            }
          ]
        });
  
        // Fetch the updated teachers list after saving
        await fetchTeachers();
        
        setIsEditModalOpen(false);
      } catch (error) {
        console.error('Error updating teacher:', error);
        setError(t('updateTeacherError'));
      }
    };
  
    const handleEdit = (teacherId) => {
      setTeacherIdToEdit(teacherId);
      setIsEditModalOpen(true);
    };
  
    const handleDelete = (teacherId) => {
      setTeacherIdToDelete(teacherId);
      setPasswordError(''); // Clear any previous error message
      setIsPasswordModalOpen(true);
    };
  
    const confirmDelete = async (password) => {
      setIsPasswordModalOpen(false);
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          setError(t('noToken'));
          return;
        }
        await axios.delete(`${API_URL}/teachers/${teacherIdToDelete}`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          data: { password }
        });
        setTeachers(prev => prev.filter(teacher => teacher.userid !== teacherIdToDelete)); // Update local state
  
        confirmAlert({
          title: t('success'),
          message: t('teacherDeleted'),
          buttons: [
            {
              label: 'OK',
              onClick: () => {},
            }
          ]
        });
      } catch (error) {
        console.error('Error deleting teacher:', error);
        if (error.response && error.response.status === 401) {
          setPasswordError(t('invalidPassword'));
          setIsPasswordModalOpen(true); // Reopen the modal with an error message
        } else {
          setError(t('deleteTeacherError'));
        }
      }
    };
  
    const handleColorChange = (color) => {
      setSelectedColor(color.hex);
    };
  
    const handleSaveColor = async (teacherId) => {
      try {
        const token = localStorage.getItem('token');
        await axios.put(`${API_URL}/teachers/${teacherId}/color`, { color: selectedColor }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        confirmAlert({
          title: t('success'),
          message: t('colorUpdated'),
          buttons: [
            {
              label: 'OK',
              onClick: () => {},
            }
          ],
        });
        const updatedTeachers = teachers.map(teacher =>
          teacher.userid === teacherId ? { ...teacher, color: selectedColor } : teacher
        );
        setTeachers(updatedTeachers);
        setColorPickerVisible(null); // Close the color picker popup after saving
      } catch (error) {
        console.error('Error updating color:', error);
        confirmAlert({
          title: t('error'),
          message: t('errorUpdatingColor'),
          buttons: [
            {
              label: 'OK',
              onClick: () => {},
            }
          ],
        });
      }
    };
  
    const toggleColorPicker = (teacherId) => {
      setColorPickerVisible(teacherId === colorPickerVisible ? null : teacherId);
    };
  
    const viewTeacherDashboard = (teacherId) => {
      navigate(`/teacher-dashboard-admin/${teacherId}`);
    };
  
    const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
      setCurrentPage(1); 
    };
  
    // Filter and paginate teachers
    const filteredTeachers = teachers.filter(teacher =>
      teacher.firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      teacher.lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
      teacher.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    const totalPages = Math.ceil(filteredTeachers.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentTeachers = filteredTeachers.slice(startIndex, endIndex);
  
    if (loading) return <p>{t('loading')}</p>;
    if (error) return <p>{error}</p>;
  

  return (
    <div className="task-list-container">
      <button className="create-teacher-button" onClick={onCreateNewTeacher}>
        <FontAwesomeIcon icon={faChalkboardTeacher} /> {t('createNewTeacher')}
      </button>
      <div className="search-container">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder={t('searchPlaceholder')}
        />
      </div>
      <div className="pagination-container">
        <button
          onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))}
          disabled={currentPage === 1}
          className="page-button"
        >
          {t('previous')}
        </button>
        {[...Array(totalPages).keys()].map(number => (
          <button
            key={number + 1}
            onClick={() => setCurrentPage(number + 1)}
            className={`page-button ${currentPage === number + 1 ? 'active' : ''}`}
          >
            {number + 1}
          </button>
        ))}
        <button
          onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="page-button"
        >
          {t('next')}
        </button>
      </div>
      <div className="task-list-container">
        <table className="task-list-table">
          <thead>
            <tr>
            <th>{t('id')}</th>
              <th><FontAwesomeIcon icon={faCheckSquare} /> <FontAwesomeIcon icon={faUser} /></th>
              <th>{t('photo')}</th>
              <th>{t('name')}</th>
              <th>{t('color')}</th>
              <th>{t('nickname')}</th>
              <th>{t('subjects')}</th>
              <th>{t('about')}</th>
              <th>{t('actions')}</th>
            </tr>
          </thead>
          <tbody>
            {currentTeachers.map((teacher) => (
              <tr key={teacher.userid}>
                 <td>{teacher.userid}</td>
                <td><button onClick={() => viewTeacherDashboard(teacher.userid)} className="dashboard-button">
                    <FontAwesomeIcon icon={faUser} />
                  </button></td>
                <td><img src={teacher.photourl || 'default-path/defaultImg.jpg'} alt={`${teacher.firstname} ${teacher.lastname}`} className="teacher-img" /></td>
                <td>{teacher.firstname} {teacher.lastname}</td>
                <td>
                  <div
                    style={{
                      backgroundColor: teacher.color || '#ffffff',
                      width: '30px',
                      height: '30px',
                      borderRadius: '4px',
                      border: '1px solid #ddd'
                    }}
                  />
                  </td>
                <td>{teacher.nickname}</td>
                <td className="about-column">{teacher.specialize_subjects}</td>
                <td className="about-column">{teacher.schedule}</td>
                <td><div className="action-buttons">
                  <button onClick={() => handleEdit(teacher.userid)} className="edit-button">
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button onClick={() => handleDelete(teacher.userid)} className="delete-button">
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                  <button
                    onClick={() => toggleColorPicker(teacher.userid)}
                    className="color-icon-button"
                  >
                    <FontAwesomeIcon
                      icon={faPalette}
                    />
                  </button>
                  {colorPickerVisible === teacher.userid && (
                    <Modal isOpen={colorPickerVisible === teacher.userid} onClose={() => setColorPickerVisible(null)}>
                      <div className="color-picker-container">
                        <SketchPicker color={selectedColor} onChange={handleColorChange} />
                        <div className="preset-colors">
                          {darkColors.map((color, index) => (
                            <div
                              key={index}
                              className="color-swatch"
                              style={{ backgroundColor: color }}
                              onClick={() => setSelectedColor(color)}
                            />
                          ))}
                        </div>
                      </div>
                      <button
                        onClick={() => handleSaveColor(teacher.userid)}
                        className="save-color-button" 
                      >
                        <FontAwesomeIcon icon={faSave} className="save-color-icon" /> Save
                      </button>
                    </Modal>
                  )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <PasswordModal
        isOpen={isPasswordModalOpen}
        onClose={() => setIsPasswordModalOpen(false)}
        onConfirm={confirmDelete}
        errorMessage={passwordError}
      />
      <EditTeacherModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onSave={saveTeacher}  // Ensure saveTeacher is passed correctly here
        teacherId={teacherIdToEdit}
      />
    </div>
  );
}

export default withRoleAccess(EditDeleteTeacher, ['admin', 'superadmin']);
