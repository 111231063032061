import React, { useState, useEffect } from 'react';
import { Layout, Table, Card, Space } from 'antd';
import moment from 'moment';
import getUserIdFromToken from '../../Utils/authUtils';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import withRoleAccess from '../../hoc/withRoleAccess';

const { Content } = Layout;

const MonthlyHoursSummary = () => {
  const { t } = useTranslation();
  const userId = getUserIdFromToken();
  const [hoursData, setHoursData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;


  useEffect(() => {
    const fetchMonthlyHours = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/classes/teacher/monthlyhours/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        const data = response.data.map((item) => ({
          ...item,
          monthYear: moment(item.month_year, 'MMM YYYY').format('MMM YYYY'), // Use "Mon YYYY" from the backend
          display_hours: item.total_hours / 60, // Convert minutes to hours
          original_minutes: item.total_hours, // Keep original minutes for tooltip
        }));

        setHoursData(data);
      } catch (error) {
        console.error('Error fetching monthly hours:', error);
        setError(t('fetchMonthlyHoursError'));
      } finally {
        setLoading(false);
      }
    };

    fetchMonthlyHours();
  }, [userId, API_URL, t]);

  const columns = [
    {
      title: t('Month'),
      dataIndex: 'monthYear',
      key: 'monthYear',
    },
    {
      title: t('Total Hours'),
      key: 'total_hours',
      render: (_, record) => (
        <span title={`${record.original_minutes} minutes`}>
          {record.display_hours.toFixed(1)}h
        </span>
      ),
      sorter: (a, b) => a.display_hours - b.display_hours,
    },
  ];

  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Header />
        <Content className="p-6">
          <Card title={t('Monthly Hours Summary')} className="mb-6">
            <Space direction="vertical" size="large" className="w-full">
              {error ? (
                <p>{error}</p>
              ) : (
                <Table
                  columns={columns}
                  dataSource={hoursData}
                  rowKey="monthYear"
                  loading={loading}
                  pagination={false}
                  scroll={{ x: 'max-content' }}
                />
              )}
            </Space>
          </Card>
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRoleAccess(MonthlyHoursSummary, ['teacher']);
