import React from "react";
import { Tabs } from "antd";
import withRoleAccess from '../../hoc/withRoleAccess';
import ClassTable from "./ClassTable";
import AllClassList from "./AllClassList";
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';

const { TabPane } = Tabs;

const ClassManagement = () => {
  return (
    <div style={{ padding: "20px", marginTop: "70px", marginLeft: "70px" }}>
              <Sidebar />
              <Header />
      <Tabs defaultActiveKey="1" size="large">
        <TabPane tab="Class Table" key="1">
          <ClassTable />
        </TabPane>
        <TabPane tab="All Class List" key="2">
          <AllClassList />
        </TabPane>

      </Tabs>
    </div>
  );
};


export default withRoleAccess(ClassManagement, ['admin', 'superadmin']);