import React, { useState, useEffect } from 'react';
import moment from 'moment';
import getUserIdFromToken from '../../Utils/authUtils';
import { createTask, updateTask, deleteTask } from './apiService';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Modal, Input, Select, Button } from 'antd'; // Import Ant Design components
import './Calendar.css';

const { TextArea } = Input;
const { Option } = Select;

const TaskPopup = ({ isOpen, task, onClose, onSave, date, tasks = [], classes = [], users = [] }) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [recurrence, setRecurrence] = useState('none');
  const [recurrenceCount, setRecurrenceCount] = useState(1);
  const [groupId, setGroupId] = useState(task ? task.groupId : uuidv4());
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);

  const isNewTask = !task;

  useEffect(() => {
    if (task) {
      setTitle(task.title || '');
      setDescription(task.description || '');
      setGroupId(task.groupId || uuidv4());
      setSelectedUsers(task.sharedUsers || []);
      setSelectedRoles(task.sharedRoles || []);
    } else {
      setTitle('');
      setDescription('');
      setRecurrence('none');
      setRecurrenceCount(1);
      setGroupId(uuidv4());
      setSelectedUsers([]);
      setSelectedRoles([]);
    }
  }, [task, isOpen]);



  const handleSaveTask = async () => {
    const userId = getUserIdFromToken();
    if (!userId) {
      console.error('User ID not found, user might not be logged in');
      return;
    }
  
    const taskStart = task ? moment(task.start) : moment(date.start);
    const taskEnd = task ? moment(task.end) : moment(date.end);
  
    const isOverlapping = classes.some(cls => {
      const classStart = moment(cls.start);
      const classEnd = moment(cls.end);
      return (
        taskStart.isBetween(classStart, classEnd, null, '[)') ||
        taskEnd.isBetween(classStart, classEnd, null, '(]') ||
        (taskStart.isBefore(classStart) && taskEnd.isAfter(classEnd))
      );
    });
  
    if (isOverlapping) {
      Modal.error({
        title: t('Time Slot Conflict'),
        content: t('The selected time overlaps with an existing class. Please choose a different time.')
      });
      return;
    }
  
    const taskDetails = {
      userId,
      title,
      description,
      start: task ? moment(task.start).format('YYYY-MM-DDTHH:mm:ss') : date ? moment(date.start).format('YYYY-MM-DDTHH:mm:ss') : null,
      end: task ? moment(task.end).format('YYYY-MM-DDTHH:mm:ss') : date ? moment(date.end).format('YYYY-MM-DDTHH:mm:ss') : null,
      recurrence: task ? task.recurrence : recurrence,
      recurrenceCount,
      groupId: task ? task.groupId : groupId,
      sharedWith: {
        users: selectedUsers,
        roles: selectedRoles,
        all: false, // Adjust based on the UI logic
      },
    };
    if (isNewTask) {
      taskDetails.recurrence = recurrence;
      taskDetails.recurrenceCount = recurrenceCount;
    }
  
    try {
      if (task) {
        const taskId = task.id.replace(/^task-/, '').replace(/^class-/, '');
        await updateTask(taskId, taskDetails);
      } else {
        await createTask(taskDetails);
      }
      onSave();
      onClose();
    } catch (error) {
      console.error('Error saving task:', error);
    }
  };
  

  const handleDeleteTask = async (deleteGroup) => {
    try {
      const deleteParams = { deleteGroup: deleteGroup ? 'true' : 'false' };
      await deleteTask(task.id, deleteParams);
      Modal.success({
        title: t('Success'),
        content: t('Task deleted successfully'),
      });
      onSave();
      onClose();
    } catch (error) {
      Modal.error({
        title: t('Error'),
        content: t('Failed to delete task'),
      });
      console.error('Error deleting task:', error);
    }
  };

  const openDeleteModal = () => {
    setIsDeleteModalVisible(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  return (
    <>
      <Modal
        title={task ? t('Edit Task') : t('New Task')}
        visible={isOpen}
        onCancel={onClose}
        footer={[
          task && (
            <Button key="delete" type="primary" danger onClick={openDeleteModal}>
              {t('Delete')}
            </Button>
          ),
          <Button key="save" type="primary" onClick={handleSaveTask}>
            {t('Save')}
          </Button>,
        ]}
      >
        <div className="form-row">
          <label>{t('Title')}:</label>
          <Input value={title} onChange={(e) => setTitle(e.target.value)} />
        </div>
        <div className="form-row">
          <label>{t('Description')}:</label>
          <TextArea value={description} onChange={(e) => setDescription(e.target.value)} />
        </div>
        <div className="form-row">
          <label>{t('Date')}:</label>
          <Input
            readOnly
            value={task ? moment(task.start).format('YYYY-MM-DD') : date ? moment(date.start).format('YYYY-MM-DD') : ''}
          />
        </div>
        <div className="form-row">
          <label>{t('Start Time')}:</label>
          <Input
            readOnly
            value={task ? moment(task.start).format('HH:mm') : date ? moment(date.start).format('HH:mm') : ''}
          />
        </div>
        <div className="form-row">
          <label>{t('End Time')}:</label>
          <Input
            readOnly
            value={task ? moment(task.end).format('HH:mm') : date ? moment(date.end).format('HH:mm') : ''}
          />
        </div>
        {isNewTask && (
          <>
            <div className="form-row">
              <label>{t('Recurrence')}:</label>
              <Select value={recurrence} onChange={(value) => setRecurrence(value)}>
                <Option value="none">{t('None')}</Option>
                <Option value="daily">{t('Daily')}</Option>
                <Option value="weekly">{t('Weekly')}</Option>
                <Option value="bi-weekly">{t('Bi-Weekly')}</Option>
                <Option value="monthly">{t('Monthly')}</Option>
              </Select>
            </div>
            {recurrence !== 'none' && (
              <div className="form-row">
                <label>{t('Number of Recurrences')}:</label>
                <Input
                  type="number"
                  value={recurrenceCount}
                  onChange={(e) => setRecurrenceCount(e.target.value)}
                  min="1"
                />
              </div>
            )}
          </>
        )}

        <div className="form-row">
          <label>{t('Share with Users')}:</label>
          <Select
            mode="multiple"
            value={selectedUsers}
            onChange={(value) => setSelectedUsers(value)}
            style={{ width: '100%' }}
            placeholder={t('Select users to share with')}
            showSearch
            filterOption={(input, option) =>
              (option?.children || '')
                .toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            }
          >
            {users.map((user) => (
              <Option key={user.userid} value={user.userid}>
                {user.nickname} ({user.role})
              </Option>
            ))}
          </Select>
        </div>
        <div className="form-row">
          <label>{t('Share with Roles')}:</label>
          <Select
            mode="multiple"
            value={selectedRoles}
            onChange={(value) => setSelectedRoles(value)}
            style={{ width: '100%' }}
            placeholder={t('Select roles to share with')}
          >
            <Option value="superadmin">{t('Superadmin')}</Option>
            <Option value="admin">{t('Admin')}</Option>
            <Option value="teacher">{t('Teacher')}</Option>
          </Select>
        </div>
      </Modal>

      <Modal
        title={t('Confirm delete')}
        visible={isDeleteModalVisible}
        onCancel={closeDeleteModal}
        footer={[
          <Button key="single" type="default" onClick={() => handleDeleteTask(false)}>
            {t('Delete single event')}
          </Button>,
          <Button key="group" type="primary" danger onClick={() => handleDeleteTask(true)}>
            {t('Delete entire group')}
          </Button>,
        ]}
      >
        <p>{t('Do you want to delete this event or the entire group?')}</p>
      </Modal>
    </>
  );
};

export default TaskPopup;