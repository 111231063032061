import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { 
  Layout, 
  Card, 
  Input, 
  Button, 
  Table, 
  Typography, 
  Modal, 
  Space, 
  Avatar, 
  Tag, 
  Pagination,
  Spin,
  Alert,
  Tooltip
} from 'antd';
import { 
  DownloadOutlined, 
  CalendarOutlined, 
  TeamOutlined, 
  ClockCircleOutlined,
  SearchOutlined
} from '@ant-design/icons';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { sarabunFont } from '../../sarabunFont';
import './Classes.css';

const { Content } = Layout;
const { Title, Text } = Typography;

// Keeping the original font loading function
const loadSarabunFont = (doc) => {
  doc.addFileToVFS('Sarabun.ttf', sarabunFont.src.split('base64,')[1]);
  doc.addFont('Sarabun.ttf', 'Sarabun', 'normal');
  doc.setFont('Sarabun');
};

const CurrentClasses = () => {
  const { t, i18n } = useTranslation();
  const [classGroups, setClassGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [popupData, setPopupData] = useState(null);
  const [registrationsCount, setRegistrationsCount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const itemsPerPage = 30;
  const API_URL = process.env.REACT_APP_API_URL;

  // Keeping all the original utility functions
  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      const options = { day: '2-digit', month: 'short', year: 'numeric' };
      return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
    } catch (error) {
      console.error('Invalid date value:', dateString);
      return 'Invalid Date';
    }
  };

  const formatTime = (timeString) => {
    try {
      const [hours, minutes] = timeString.split(':');
      return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    } catch (error) {
      console.error('Invalid time value:', timeString);
      return 'Invalid Time';
    }
  };

  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')} ${t('hrs')}`;
  };

  const formatScheduleHour = (scheduleHourString) => {
    try {
      const [hours, minutes] = scheduleHourString.split(' ').map((value) => parseInt(value, 10));
      return `${hours}:${minutes.toString().padStart(2, '0')} ${t('hrs')}`;
    } catch (error) {
      console.error('Invalid schedule hour value:', scheduleHourString);
      return 'Invalid Time';
    }
  };

  const getDayOfWeek = (dateString) => {
    const date = new Date(dateString);
    const daysOfWeek = t('daysOfWeek', { returnObjects: true });
    return daysOfWeek[date.getDay()];
  };

  // API calls remain the same
  const fetchClassGroups = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/class-groups`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setClassGroups(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching class groups:', error);
      setError(t('fetchClassGroupsError'));
      setLoading(false);
    }
  }, [API_URL, t]);

  const fetchRegistrationsCount = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/registrations/registrations-count`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setRegistrationsCount(response.data);
    } catch (error) {
      console.error('Error fetching registrations count:', error);
      setError(t('fetchRegistrationsCountError'));
    }
  }, [API_URL, t]);

  useEffect(() => {
    fetchClassGroups();
    fetchRegistrationsCount();
  }, [fetchClassGroups, fetchRegistrationsCount]);

  // Schedule modal handlers
  const handleScheduleClick = (schedule) => {
    const sortedSchedule = [...schedule].sort((a, b) => new Date(a.date) - new Date(b.date));
    setPopupData(sortedSchedule);
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    loadSarabunFont(doc);
  
    const margin = 20;
    const startY = margin + 60;
    const pageWidth = doc.internal.pageSize.getWidth();
    const centerX = pageWidth / 2;
    const maxLineWidth = pageWidth - (margin * 2); // Maximum width for text
  
    const headerImage = new Image();
    headerImage.src = '/images/Theplanner.png';
  
    headerImage.onload = () => {
      // Add header image
      doc.addImage(headerImage, 'PNG', margin, margin, 35, 30);
  
      // Add header text
      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');
      doc.text('THE PLANNER EDUCATION', margin + 50, margin + 10);
  
      doc.setFont('helvetica', 'normal');
      doc.text('The Mercury Ville @Chidlom 3rd floor', margin + 50, margin + 15);
      doc.text('Lumpini Pathumwan Bangkok', margin + 50, margin + 20);
      doc.text('www.theplannereducation.com', margin + 50, margin + 25);
      doc.text('Tel. 02-253-2533 or 095-726-2666 Line: @theplanner', margin + 50, margin + 30);
  
      // Prepare class details data
      const classCode = popupData[0]?.class_code || 'N/A';
      const totalHours = popupData?.[0]?.total_hours || 0;
      const subjects = [...new Set(popupData.map(slot => slot.subject_name))];
  
      // Add class code
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.text(classCode, centerX, startY - 20, { align: 'center' });
      doc.text(`Total Hours: ${formatMinutesToHours(totalHours)}`, centerX, startY - 15, { align: 'center' });
  
      // Handle long subject list with word wrapping
      doc.setFont('helvetica', 'normal');
      const subjectsText = `Subjects: ${subjects.join(', ')}`;
      
      // Split long subject text into multiple lines
      const splitSubjects = doc.splitTextToSize(subjectsText, maxLineWidth);
      
      // Calculate total height needed for subjects
      const lineHeight = 5;
      const totalSubjectsHeight = splitSubjects.length * lineHeight;
      
      // Add each line of subjects text
      splitSubjects.forEach((line, index) => {
        doc.text(line, centerX, startY - 10 + (index * lineHeight), { align: 'center' });
      });
  
      // Adjust table starting position based on number of subject lines
      const tableStartY = startY + totalSubjectsHeight;
  
      // Sort popupData
      const sortedPopupData = popupData.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA.getTime() === dateB.getTime() 
          ? a.schedule_time.localeCompare(b.schedule_time)
          : dateA - dateB;
      });
  
      // Prepare table data with specific column widths
      const columns = [
        { header: 'DAY', dataKey: 'schedule_day', width: 30 },
        { header: 'DATE', dataKey: 'date', width: 35 },
        { header: 'SUBJECT', dataKey: 'subject_name', width: 60 },
        { header: 'TIME', dataKey: 'schedule_time', width: 40 },
        { header: 'HOURS', dataKey: 'schedule_hour', width: 25 }
      ];
  
      const rows = sortedPopupData.map(slot => ({
        schedule_day: getDayOfWeek(slot.date),
        date: formatDate(slot.date),
        subject_name: slot.subject_name,
        schedule_time: `${formatTime(slot.schedule_time)} - ${formatTime(slot.end_time)}`,
        schedule_hour: formatScheduleHour(slot.schedule_hour)
      }));
  
      // Add table with optimized settings
      doc.autoTable({
        startY: tableStartY,
        head: [columns.map(col => col.header)],
        body: rows.map(row => columns.map(col => row[col.dataKey])),
        theme: 'plain', // Change to plain theme
        styles: {
          font: 'Sarabun',
          fontStyle: 'normal',
          cellPadding: 2, // Slightly increased padding
          overflow: 'linebreak',
          fontSize: 9,
          lineWidth: 0, // Remove borders
        },
        columnStyles: {
          subject_name: { cellWidth: 45 },
          schedule_time: { cellWidth: 30 },
          schedule_day: { cellWidth: 25 },
          date: { cellWidth: 25 },
          nickname: { cellWidth: 25 },
          schedule_hour: { cellWidth: 20 },
          classroom_number: { cellWidth: 20 }
        },
        headStyles: {
          fillColor: '#005082', // Dark blue header like in the image
          textColor: '#FFFFFF',
          fontSize: 10,
          fontStyle: 'bold',
          halign: 'left', // Left align header text
          valign: 'middle',
          lineWidth: 0 // Remove header borders
        },
        // Add alternating row styles
        alternateRowStyles: {
          fillColor: '#F5F5F5' // Light gray for alternate rows
        },
        // Base row styles (for non-alternate rows)
        bodyStyles: {
          lineWidth: 0, // Remove borders
          textColor: '#333333', // Dark gray text
          fontSize: 10,
          halign: 'left',
          valign: 'middle',
          fillColor: '#FFFFFF' // White background for normal rows
        },
        margin: { left: margin },
        tableWidth: 'auto',
        // Ensure proper cell padding and alignment
        didParseCell: function(data) {
          if (data.cell.raw === null || data.cell.raw === undefined) {
            data.cell.text = '-'; // Replace empty cells with dash
          }
        },
        // Add more space between rows
        rowSpacing: 1,
      });
  
  
      // Save the PDF
      doc.save(`${classCode}.pdf`);
    };
  
    headerImage.onerror = () => {
      console.error('Failed to load the header image.');
    };
  };

  // Data processing
  const currentDate = new Date();
  const sortedClassGroups = classGroups
    .filter(cls => cls.isprivate === false && new Date(cls.end_date) >= currentDate)
    .sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

  const filteredClassGroups = sortedClassGroups.filter(cls =>
    cls.class_code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getRegisteredStudents = (groupId) => {
    const group = registrationsCount.find(rc => rc.groupid === groupId);
    return group ? group.registered_students : 0;
  };

  // Table columns configuration for the schedule modal
  const scheduleColumns = [
    {
      title: t('day'),
      dataIndex: 'date',
      key: 'day',
      render: (date) => getDayOfWeek(date),
    },
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      render: (date) => formatDate(date),
    },
    {
      title: t('subjectName'),
      dataIndex: 'subject_name',
      key: 'subject_name',
    },

    {
      title: t('time'),
      key: 'time',
      render: (_, record) => `${formatTime(record.schedule_time)} - ${formatTime(record.end_time)}`,
    },
    {
      title: t('hours'),
      dataIndex: 'schedule_hour',
      key: 'hours',
      render: (hours) => formatScheduleHour(hours),
    },
  ];


  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return <Alert message={error} type="error" />;
  }

  return (
<Layout>
  <Sidebar />
  <Layout>
    <Header />
    <Content 
      style={{ 
        marginTop: '50px', 
        padding: '24px',
        minHeight: '280px',
        background: 'linear-gradient(180deg, #f0f2f5 0%, #ffffff 100%)'
      }}
    >
      <div style={{ maxWidth: 1200, margin: '0 auto' }}>
        <Title 
          level={2}
          style={{
            textAlign: 'center',
            marginBottom: 40,
            color: '#1a365d',
            position: 'relative'
          }}
        >
          {t('ourCourses')}
        </Title>
        
        <Space direction="vertical" size="large" style={{ width: '100%', marginBottom: 24 }}>
          {/* Search and Pagination Container */}
          <div style={{
            background: 'white',
            padding: '24px',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            marginBottom: 24
          }}>
            <Input
              prefix={<SearchOutlined />}
              placeholder={t('searchByClassCode')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ 
                maxWidth: 300,
                marginBottom: 16
              }}
            />
            
            <Pagination
              current={currentPage}
              total={filteredClassGroups.length}
              pageSize={itemsPerPage}
              onChange={(page) => setCurrentPage(page)}
              showSizeChanger={false}
              style={{ 
                textAlign: 'center',
                marginTop: 16
              }}
            />
          </div>

          {/* Cards Grid */}
          <div style={{ 
            display: 'grid', 
            gap: '24px', 
            gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))'
          }}>
            {filteredClassGroups
              .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
              .map((cls) => (
                <Card
                  key={cls.groupid}
                  hoverable
                  style={{
                    background: 'white',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                    borderRadius: '8px',
                    overflow: 'hidden'
                  }}
                  actions={[
                    <Tooltip title={t('viewSchedule')}>
                      <Button 
                        type="link" 
                        icon={<CalendarOutlined />}
                        onClick={() => handleScheduleClick(cls.classes)}
                      >
                        {t('viewSchedule')}
                      </Button>
                    </Tooltip>
                  ]}
                >
                  <Space direction="vertical" size="small" style={{ width: '100%' }}>
                    <Space align="center">
                      <Avatar 
                        style={{ 
                          backgroundColor: ['#f56a00', '#7265e6', '#ffbf00'][cls.groupid % 3],
                          verticalAlign: 'middle',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                        }}
                        size={40}
                      >
                        {cls.class_code.charAt(0)}
                      </Avatar>
                      <Title level={4} style={{ margin: 0 }}>{cls.class_code}</Title>
                    </Space>

                    <div style={{
                      background: '#f8f9fa',
                      padding: '16px',
                      borderRadius: '6px',
                      marginTop: '12px'
                    }}>
                      <Space direction="vertical" size={8}>
                        <Text type="secondary">
                          <CalendarOutlined style={{ marginRight: 8 }} />
                          {formatDate(cls.start_date)} - {formatDate(cls.end_date)}
                        </Text>
                        <Text>
                          <ClockCircleOutlined style={{ marginRight: 8 }} />
                          {t('totalHours')}: {formatMinutesToHours(cls.total_hours)}
                        </Text>
                        <Text>
                          <TeamOutlined style={{ marginRight: 8 }} />
                          {t('maxRegister')}: {cls.register_capacity}
                        </Text>
                        <Text>
                          {t('totalRegister')}: {getRegisteredStudents(cls.groupid)}
                        </Text>
                        <Tag 
                          color={cls.isprivate ? 'purple' : 'blue'}
                          style={{ marginTop: 8 }}
                        >
                          {cls.isprivate ? t('private') : t('group')}
                        </Tag>
                      </Space>
                    </div>
                  </Space>
                </Card>
            ))}
          </div>
        </Space>

        {/* Modal */}
        <Modal
          title={
            <div style={{ 
              borderBottom: '1px solid #f0f0f0',
              padding: '16px 0',
              margin: '0 -24px 16px',
              paddingLeft: 24
            }}>
              {popupData?.[0]?.class_code || t('scheduleDetails')}
            </div>
          }
          open={!!popupData}
          onCancel={() => setPopupData(null)}
          width={900}
          footer={[
            <Button 
              key="download" 
              type="primary" 
              icon={<DownloadOutlined />}
              onClick={handleDownloadPDF}
            >
              {t('downloadPDF')}
            </Button>,
            <Button key="close" onClick={() => setPopupData(null)}>
              {t('close')}
            </Button>
          ]}
          bodyStyle={{ padding: '24px' }}
        >
          <Table
            columns={scheduleColumns}
            dataSource={popupData}
            rowKey={(record, index) => index}
            pagination={false}
            scroll={{ x: true }}
            style={{ 
              background: 'white',
              borderRadius: '8px',
              overflow: 'hidden'
            }}
          />
        </Modal>
      </div>
    </Content>
  </Layout>
</Layout>
  );
};

export default withRoleAccess(CurrentClasses, ['admin', 'superadmin', 'teacher', 'student']);