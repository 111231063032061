import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Card, Typography } from 'antd';
import { BookOutlined, UserOutlined } from '@ant-design/icons';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Modal from '../../components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faClock, faCalendarAlt, faCloudUpload, faUpload, faCheck, faTimes, faMinus, faEdit,
  faPaperPlane, faChalkboardTeacher, faBook, faVideo, faUserClock, faSave, 
  faLock, faPeopleGroup, faComment, faTrash, faUser, faCalendar, faFileAlt, faFilePdf, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import './Classes.css';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

const { Title, Text } = Typography;

const ClassDetails = () => {
  const { t, i18n } = useTranslation();
  const { groupid } = useParams();
  const [classDetails, setClassDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [attendance, setAttendance] = useState({});
  const [attendanceStatistics, setAttendanceStatistics] = useState({});
  const [savedAttendances, setSavedAttendances] = useState({});
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [classComments, setClassComments] = useState({});
  const [registeredStudents, setRegisteredStudents] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [submittedSchedules, setSubmittedSchedules] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [newVideoMeetingLinks, setNewVideoMeetingLinks] = useState({});
  const [isCommentSaved, setIsCommentSaved] = useState({});
  const [videoMeetingLinks, setVideoMeetingLinks] = useState({});
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [isSubmittedHomeworkOpen, setIsSubmittedHomeworkOpen] = useState(false);
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [studentUploadedPdfs, setStudentUploadedPdfs] = useState({});
  const [uploadedPdfs, setUploadedPdfs] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [homework, setHomework] = useState({});
  const [editMode, setEditMode] = useState({});
  const timetableRef = useRef(); 

  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year:'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  };

  const formatUploadDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false // This forces 24-hour format
    };
    return new Intl.DateTimeFormat(i18n.language, options).format(date);
  };
  const formatTime = (timeString) => {
    const [hour, minute] = timeString.split(':');
    return `${hour}:${minute}`;
  };
  
  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')} ${t('hr')}`;
  };
  
  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const formatHours = (decimalHours) => {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  };
  

  const calculateEndTime = (startTime, durationMinutes) => {
    return moment(startTime, 'HH:mm:ss')
      .add(durationMinutes, 'minutes')
      .format('HH:mm');
  };

  const calculateClassEndTime = (startTime, durationMinutes) => {
    const timeParts = startTime.split(':');
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);
  
    const momentTime = moment().hours(hours).minutes(minutes);

    const endTime = momentTime.add(durationMinutes, 'minutes');

    return endTime.format('HH:mm');
  };
  

  useEffect(() => {
    const fetchClassDetails = async () => {
      try {
        const response = await axios.get(`${API_URL}/class-groups/${groupid}`);
        setClassDetails(response.data);

        const { classgroup_ids, classes } = response.data;

        const fetchMessages = async () => {
          const token = localStorage.getItem('token');
          const messageResponses = await Promise.all(classgroup_ids.map(id => 
            axios.get(`${API_URL}/messages/${id}`, {
              headers: { Authorization: `Bearer ${token}` },
            }) 
          ));
          const messages = messageResponses.flatMap(res => res.data);
          setMessages(messages);
        };

        const fetchAttendanceStatus = async () => {
          const token = localStorage.getItem('token');
          const attendanceResponses = await Promise.all(classes.map(cls => 
            axios.get(`${API_URL}/attendance/${cls.classid}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
          ));
          const submittedClassIds = attendanceResponses
            .filter(res => res.data.length > 0)
            .map(res => res.config.url.split('/').pop());
          setSubmittedSchedules(submittedClassIds);
        };

        const fetchAttendanceStatistics = async () => {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${API_URL}/attendance/group/${groupid}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setAttendanceStatistics(response.data);
        };

        const fetchAllClassComments = async () => {
          const token = localStorage.getItem('token');
          const commentResponses = await Promise.all(classes.map(cls => 
            axios.get(`${API_URL}/class-comments/${cls.classid}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
          ));
          const commentsData = commentResponses.flatMap(res => res.data);
          const formattedComments = commentsData.reduce((acc, comment) => {
            acc[comment.classid] = comment.comment;
            return acc;
          }, {});
          setClassComments(formattedComments);
          setIsCommentSaved(Object.keys(formattedComments).reduce((acc, classid) => {
            acc[classid] = true;
            return acc;
          }, {}));
        };

        const fetchAllVideoMeetingLinks = async () => {
          const token = localStorage.getItem('token');
          const videoLinkResponses = await Promise.all(classes.map(cls =>
            axios.get(`${API_URL}/video-meeting-links/${cls.classid}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
          ));
          const videoLinksData = videoLinkResponses.flatMap(res => res.data);
          const formattedVideoLinks = videoLinksData.reduce((acc, link) => {
            acc[link.classid] = link.video_meeting_link;
            return acc;
          }, {});
          setVideoMeetingLinks(formattedVideoLinks);
        };

        const fetchClassPdfs = async () => {
          try {
              const token = localStorage.getItem('token');
              const pdfResponses = await Promise.all(classes.map(cls =>
                  axios.get(`${API_URL}/upload/class/${cls.classid}`, {
                      headers: { Authorization: `Bearer ${token}` }
                  })
              ));
              const pdfData = pdfResponses.flatMap(res => res.data);
              const formattedPdfs = pdfData.reduce((acc, pdf) => {
                  if (!acc[pdf.classid]) {
                      acc[pdf.classid] = [];
                  }
                  acc[pdf.classid].push(pdf);
                  return acc;
              }, {});
              setUploadedPdfs(formattedPdfs);
          } catch (error) {
              console.error('Error fetching PDFs for classes:', error);
          }
      };

      const fetchStudentPdfs = async () => {
        try {
            const token = localStorage.getItem('token');
            // Fetch PDFs for each class
            const pdfResponses = await Promise.all(classes.map(cls =>
                axios.get(`${API_URL}/upload/studentupload/class/${cls.classid}`, {
                    headers: { Authorization: `Bearer ${token}` }
                })
            ));
    
            // Flatten the responses and log the data
            const pdfData = pdfResponses.flatMap(res => res.data);
    
            // Reduce the data into the correct format for each class
            const formattedPdfs = pdfData.reduce((acc, pdf) => {
                if (!acc[pdf.class_id]) {
                    acc[pdf.class_id] = [];
                }
                acc[pdf.class_id].push(pdf);
                return acc;
            }, {});
  
    
            setStudentUploadedPdfs(formattedPdfs); // Set state
        } catch (error) {
            console.error('Error fetching PDFs for classes:', error);
        }
    };
    
        

        fetchMessages();
        fetchAttendanceStatus();
        fetchAttendanceStatistics();
        fetchAllClassComments();
        fetchAllVideoMeetingLinks();
        fetchClassPdfs();
        fetchStudentPdfs();

      } catch (error) {
        setError('Failed to fetch class details.');
      } finally {
        setLoading(false);
      }
    };

    const fetchRegisteredStudents = async () => {
      try {
        const response = await axios.get(`${API_URL}/registrations/classgroup/${groupid}`);
        setRegisteredStudents(response.data);
      } catch (error) {
        console.error('Error fetching registered students:', error);
      }
    };

    fetchClassDetails();
    fetchRegisteredStudents();
  }, [groupid, API_URL]);

  const handleSendMessage = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${API_URL}/messages`,
        { groupid, message: newMessage },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNewMessage('');
      setMessages(prevMessages => [
        ...prevMessages,
        { nickname: 'You', lastname: '', message: newMessage, timestamp: new Date().toISOString() }
      ]);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleClassCommentChange = (classId, value) => {
    setClassComments((prev) => ({
      ...prev,
      [classId]: value,
    }));
    setNewComment(value); // Update the newComment state as well
  };

  const handleAddClassComment = async (classid) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${API_URL}/class-comments`,
        { classid, comment: newComment },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setClassComments((prev) => ({
        ...prev,
        [classid]: newComment,
      }));
      setNewComment('');
      setIsCommentSaved((prev) => ({
        ...prev,
        [classid]: true
      }));
    } catch (error) {
      console.error('Error adding class comment:', error);
    }
  };

  const handleAddVideoMeetingLink = async (classid) => {
    try {
      const token = localStorage.getItem('token');
      const linkValue = newVideoMeetingLinks[classid];  // Get the specific link for this class
  
      if (!linkValue) return;  // Don't proceed if no link is provided
  
      await axios.post(
        `${API_URL}/video-meeting-links`,
        { classid, video_meeting_link: linkValue },  // Use the specific link
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      setVideoMeetingLinks((prev) => ({
        ...prev,
        [classid]: linkValue,  // Use the specific link
      }));
  
      // Clear only this specific class's input
      setNewVideoMeetingLinks(prev => {
        const updated = { ...prev };
        delete updated[classid];
        return updated;
      });
  
    } catch (error) {
      console.error('Error adding video meeting link:', error);
    }
  };
  const handleDeleteVideoMeetingLink = async (classid) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${API_URL}/video-meeting-links/${classid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setVideoMeetingLinks((prev) => ({
        ...prev,
        [classid]: null,
      }));
    } catch (error) {
      console.error('Error deleting video meeting link:', error);
    }
  };

  const handleNewVideoMeetingLink = (classId, value) => {
    setNewVideoMeetingLinks(prev => ({
      ...prev,
      [classId]: value
    }));
  };

  const handlePdfChange = (e) => {
    const selectedFile = e.target.files[0];
    setSelectedPdf(selectedFile);
};

const handlePdfUpload = async (classid) => {
    if (!selectedPdf) {
        console.error('No PDF selected');
        return;
    }

    const formData = new FormData();
    formData.append('pdfFile', selectedPdf);
    formData.append('classid', classid);

    // Fetch the userId from local storage or state
    const userId = localStorage.getItem('userId'); // Or wherever you're storing it
    formData.append('userId', userId); // Add userId to formData

    try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${API_URL}/upload`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });

        // Update state to reflect the new file associated with this class
        setUploadedPdfs((prev) => ({
            ...prev,
            [classid]: response.data.filePath,
        }));

        setSelectedPdf(null);

        // Show success confirmation alert
        confirmAlert({
            title: 'Success',
            message: 'PDF uploaded successfully!',
            buttons: [
                {
                    label: 'OK',
                    onClick: () => {},
                },
            ],
        });

    } catch (error) {
        console.error('Error uploading PDF:', error);

        // Show error confirmation alert
        confirmAlert({
            title: 'Error',
            message: 'Failed to upload PDF. Please try again.',
            buttons: [
                {
                    label: 'OK',
                    onClick: () => {},
                },
            ],
        });
    }
};

const handleOpenSubmissions = (classid) => {
  setSelectedClassId(classid);
  setIsSubmittedHomeworkOpen(true);
};

const handleAttendanceChange = (studentId, field, value) => {
  setAttendance(prev => ({
    ...prev,
    [studentId]: {
      ...prev[studentId],
      [field]: value
    }
  }));
};

const handleHomeworkChange = (studentId, value) => {
  setHomework(prev => ({
    ...prev,
    [studentId]: value
  }));
};

const handleEdit = (studentId) => {
  setEditMode(prev => ({
    ...prev,
    [studentId]: true
  }));
  // Initialize edit form with current values
  setAttendance(prev => ({
    ...prev,
    [studentId]: {
      status: savedAttendances[studentId].status,
      comment: savedAttendances[studentId].comment
    }
  }));
  setHomework(prev => ({
    ...prev,
    [studentId]: savedAttendances[studentId].homework
  }));
};

const handleUpdate = async (studentId) => {
  try {
    const token = localStorage.getItem('token');
    const savedRecord = savedAttendances[studentId];
    
    if (!savedRecord || !savedRecord.attendance_id) {
      console.error('No attendance record found for update');
      alert('Unable to update: Record not found');
      return;
    }

    const response = await axios.put(
      `${API_URL}/attendance/${savedRecord.attendance_id}`,
      {
        status: attendance[studentId].status,
        comment: attendance[studentId].comment || '',
        homework: homework[studentId] || 'N/A'
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );

    // Make sure we're including all fields from the response
    setSavedAttendances(prev => ({
      ...prev,
      [studentId]: {
        ...response.data  // Use the entire response data which includes edited_at
      }
    }));

    // Clear edit mode
    setEditMode(prev => {
      const newState = { ...prev };
      delete newState[studentId];
      return newState;
    });

    // Refresh the data to ensure we have the latest state
    await handleShowModal(selectedClass);

  } catch (error) {
    console.error('Error updating attendance:', error);
    alert('Failed to update attendance');
  }
};

const handleSaveAttendance = async (studentId) => {
  if (!attendance[studentId]?.status) {
    alert('Please select an attendance status');
    return;
  }

  setSavingInProgress(true);
  try {
    const token = localStorage.getItem('token');
    const attendanceData = [{
      student_id: studentId,
      status: attendance[studentId].status,
      comment: attendance[studentId].comment || '',
      homework: homework[studentId] || 'N/A' // Add homework status
    }];

    await axios.post(
      `${API_URL}/attendance`,
      {
        groupid: classDetails.groupid,
        classid: selectedClass.classid,
        attendance: attendanceData
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      }
    );

    // Update saved attendances with homework
    setSavedAttendances(prev => ({
      ...prev,
      [studentId]: {
        ...attendance[studentId],
        homework: homework[studentId],
        timestamp: new Date().toISOString()
      }
    }));

    // Clear the current student's attendance and homework
    setAttendance(prev => {
      const newState = { ...prev };
      delete newState[studentId];
      return newState;
    });
    setHomework(prev => {
      const newState = { ...prev };
      delete newState[studentId];
      return newState;
    });

  } catch (error) {
    console.error('Error saving attendance:', error);
    alert('Failed to save attendance');
  } finally {
    setSavingInProgress(false);
  }
};

const handleShowModal = async (cls) => {
  setSelectedClass(cls);
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_URL}/attendance/${cls.classid}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    // Format existing attendance records
    const existingAttendance = response.data.reduce((acc, record) => {
      acc[record.student_id] = {
        attendance_id: record.attendance_id,
        status: record.status,
        comment: record.comment,
        homework: record.homework,
        timestamp: record.timestamp,
        edited_at: record.edited_at // Make sure this is being set
      };
      return acc;
    }, {});

    setSavedAttendances(existingAttendance);
    setAttendance({});
    setHomework({});
  } catch (error) {
    console.error('Error fetching attendance:', error);
  }
};

const downloadCSV = () => {
  // Only proceed if there's data
  if (!studentUploadedPdfs[selectedClassId]?.length) return;

  // Create CSV content
  const csvContent = [
    // Header row
    ['Student Name', 'Uploaded Date', 'File Name', 'Homework Status'],
    // Data rows
    ...studentUploadedPdfs[selectedClassId].map(pdf => [
      pdf.nickname,
      formatUploadDate(pdf.upload_date),
      pdf.originalname,
      pdf.homework_status || 'N/A' // Add homework status
    ])
  ]
  .map(row => row.join(','))
  .join('\n');

  // Create blob and download
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', 'submitted_homework.csv');
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const downloadAttendanceCSV = () => {
  if (!registeredStudents?.length) return;

  // Create CSV content
  const csvContent = [
    // Header row
    ['Student Name', 'Status', 'Homework', 'Comments', 'Time', 'Last Edited'],  // Added Last Edited column
    // Data rows
    ...registeredStudents.map(student => {
      const record = savedAttendances[student.student_id];
      return [
        `${student.student_name} (${student.nickname})`,
        record ? record.status : '',
        record ? record.homework || 'N/A' : '',
        record ? record.comment : '',
        record ? moment(record.timestamp).format('DD-MMM-YYYY HH:mm') : '',
        record && record.edited_at ? moment(record.edited_at).format('DD-MMM-YYYY HH:mm') : '-'  // Added edited_at
      ];
    })
  ]
  .map(row => {
    // Escape any commas in the data to prevent CSV formatting issues
    return row.map(cell => {
      if (cell && cell.includes(',')) {
        return `"${cell}"`;
      }
      return cell;
    }).join(',');
  })
  .join('\n');

  // Create blob and download
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', `attendance_${selectedClass?.subject_name}_${moment(selectedClass?.date).format('DD-MM-YYYY')}.csv`);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!classDetails) return <p>No class details found.</p>;

  

  const attendanceData = [
    { name: 'Present', value: attendanceStatistics.present, color: '#36A2EB' },
    { name: 'Late', value: attendanceStatistics.late, color: '#FFCE56' },
    { name: 'Absent', value: attendanceStatistics.absent, color: '#FF6384' },
  ];

  const homeworkStatusStyles = {
    yes: {
      backgroundColor: '#d4edda',
      color: '#155724',
      border: '1px solid #c3e6cb'
    },
    no: {
      backgroundColor: '#f8d7da',
      color: '#721c24',
      border: '1px solid #f5c6cb'
    },
    na: {
      backgroundColor: '#e2e3e5',
      color: '#383d41',
      border: '1px solid #d6d8db'
    }
  };

  return (
    <div>
      <Sidebar />
      <Header />
      <div className="class-details-container">
      <Card className="class-header-card">
      <div className="class-header-content">
        <Title level={2} className="class-code">
          {classDetails.class_code}
        </Title>
        
        <div className="info-container">
          <div className="info-section">
            <div className="section-header">
              <BookOutlined className="section-icon" />
              <Text className="section-label">SUBJECTS</Text>
            </div>
            <Text className="section-content">
              {classDetails.subjects && classDetails.subjects.length > 0 
                ? classDetails.subjects.join(', ')
                : 'No subjects'}
            </Text>
          </div>

          <div className="divider" />

          <div className="info-section">
            <div className="section-header">
              <UserOutlined className="section-icon" />
              <Text className="section-label">TEACHERS</Text>
            </div>
            <Text className="section-content">
              {classDetails.teachers && classDetails.teachers.length > 0 
                ? classDetails.teachers.join(', ')
                : 'No teachers'}
            </Text>
          </div>
        </div>
      </div>
    </Card>

        <div className="info-attendance-row">
        <div className="class-info-container">
  <div className="class-info-header">
    <h3>{t('classDetails')}</h3>
  </div>
  <div className="info-list">
    <div className="info-item">
      <div className="info-icon">
        <FontAwesomeIcon icon={faCalendar} />
      </div>
      <div className="info-content">
        <div className="info-label">{t('startDate')}</div>
        <div className="info-value">{formatDate(classDetails.start_date)}</div>
      </div>
    </div>

    <div className="info-item">
      <div className="info-icon">
        <FontAwesomeIcon icon={faClock} />
      </div>
      <div className="info-content">
        <div className="info-label">{t('totalHours')}</div>
        <div className="info-value">{formatMinutesToHours(classDetails.total_hours)}</div>
      </div>
    </div>

    <div className="info-item">
      <div className="info-icon">
        <FontAwesomeIcon icon={faBook} />
      </div>
      <div className="info-content">
        <div className="info-label">{t('Notes')}</div>
        <div className="info-value">{classDetails.book_details_materials}</div>
      </div>
    </div>

    <div className="info-item">
      <div className="info-icon">
        <FontAwesomeIcon icon={faUser} />
      </div>
      <div className="info-content">
        <div className="info-label">{t('type')}</div>
        <div className="info-value">{classDetails.isprivate ? t('private') : t('group')}</div>
      </div>
    </div>
  </div>

  <button className="schedule-button" onClick={() => setIsModalOpen(true)}>
    <FontAwesomeIcon icon={faCalendarAlt} />
    {t('scheduleDetails')}
  </button>
</div>

<div className="attendance-container">
            <h3>{t('classPerformance')}</h3>
            <div className="attendance-stats">
              <p>{t('present')}: {attendanceStatistics.presentPercentage}%</p>
              <p>{t('absent')}: {attendanceStatistics.absentPercentage}%</p>
              <p>{t('late')}: {attendanceStatistics.latePercentage}%</p>
            </div>
            <div className="attendance-chart" style={{ width: '100%', height: 300 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={attendanceData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        innerRadius={80}
                        fill="#8884d8"
                        paddingAngle={5}
                      >
                        {attendanceData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
          </div>
        </div>

        <div className="info-attendance-row">        
          <div className="registered-students-container">
            <div className="registered-students-header">
              <h3>{t('registeredStudents')}</h3>
              <div className="student-count">
                <FontAwesomeIcon icon={faUsers} />
                <span>{registeredStudents.length} {t('students')}</span>
              </div>
            </div>
            <div className="students-list">
              {registeredStudents.map((student) => (
                <div key={student.student_id} className="student-item">
                  <div className="student-avatar">
                    {student.student_name.charAt(0).toUpperCase()}
                  </div>
                  <div className="student-info">
                    <div className="student-name">{student.student_name} ({student.nickname})</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="class-messages-container">
  <div className="messages-header">
    <h3>{t('messages')}</h3>
  </div>

  <div className="message-list">
    {messages.map((msg, index) => (
      <div key={index} className="message-item">
        <div className="message-sender">
          {msg.nickname}
        </div>
        <div className="message-content">
          {msg.message}
        </div>
        <span className="message-time">
          {formatDate(msg.timestamp)} {t('at')} {formatDateTime(msg.timestamp)}
        </span>
      </div>
    ))}
  </div>
  <div className="message-input-container">
    <textarea
      className="message-textarea"
      value={newMessage}
      onChange={(e) => setNewMessage(e.target.value)}
      placeholder={t('writeMessage')}
    />
    <button className="send-button" onClick={handleSendMessage}>
      <FontAwesomeIcon icon={faPaperPlane} />
      {t('sendMessage')}
    </button>
  </div>
</div>
        </div>

        <div className="class-schedule-container">
  {classDetails.classes?.map((slot, index) => (
    <div key={index} className="class-schedule-card">
      <h3>{t('class')} {index + 1} ({slot.classid})</h3>
      <h3>{slot.subject_name}</h3>
      <h4><FontAwesomeIcon icon={faChalkboardTeacher} /> {slot.nickname}</h4> 
      <div className="video-meeting-link">
  {videoMeetingLinks[slot.classid] ? (
    <div className="video-meeting-active">
      <a 
        href={videoMeetingLinks[slot.classid]} 
        target="_blank" 
        rel="noopener noreferrer"
        className="video-join-link"
      >
        <FontAwesomeIcon icon={faVideo} />
        <span>{t('joinVideoMeeting')}</span>
      </a> 
      <button 
        className="delete-video-button" 
        onClick={() => handleDeleteVideoMeetingLink(slot.classid)}
      >
        <FontAwesomeIcon icon={faTrash} />
      </button>
    </div>
  ) : (
    <div className="video-meeting-input">
      <input
        type="text"
        placeholder={t('addVideoMeetingLink')}
        value={newVideoMeetingLinks[slot.classid] || ''}
        onChange={(e) => handleNewVideoMeetingLink(slot.classid, e.target.value)}
      />
      <button 
        className="add-video-button"
        onClick={() => handleAddVideoMeetingLink(slot.classid)}
      >
        <FontAwesomeIcon icon={faVideo} />
      </button>
    </div>
  )}
</div>
      <p><FontAwesomeIcon icon={faCalendarAlt} /> {formatDate(slot.date)}</p>
      <p><FontAwesomeIcon icon={faClock} /> {formatTime(slot.schedule_time)} - {calculateClassEndTime(slot.schedule_time, slot.schedule_hour)}</p>
      <p><FontAwesomeIcon icon={faUserClock} /> {formatMinutesToHours(slot.schedule_hour)}</p>
      <p><FontAwesomeIcon icon={faDoorOpen} /> {slot.classroom_number}</p>

      <div className="class-comment">
        <textarea
          placeholder={t('addClassComment')}
          value={classComments[slot.classid] || ''}
          onChange={(e) => handleClassCommentChange(slot.classid, e.target.value)}
          readOnly={isCommentSaved[slot.classid]}
          disabled={isCommentSaved[slot.classid]}
          style={{ resize: 'none' }}
        />
      </div>
      {!isCommentSaved[slot.classid] && (
        <button onClick={() => handleAddClassComment(slot.classid)}>
          <FontAwesomeIcon icon={faComment} /> {t('addComment')}
        </button>
      )}
      <div className="pdf-section">
  <div className="pdf-upload-container">
    <h4>{t('Upload Homework')}</h4>
    <div className="pdf-upload-input">
      <label className="file-input-label">
        <input 
          type="file" 
          accept=".pdf" 
          onChange={handlePdfChange}
          className="file-input" 
        />
        <FontAwesomeIcon icon={faCloudUpload} />
        <span>{selectedPdf ? selectedPdf.name : t('choosePDF')}</span>
      </label>
      <button 
        onClick={() => handlePdfUpload(slot.classid)} 
        disabled={!selectedPdf} 
        className="upload-button"
      >
        <FontAwesomeIcon icon={faUpload} />
        {t('upload')}
      </button>
    </div>
  </div>

  <div className="uploaded-files-section">
  {uploadedPdfs[slot.classid]?.length > 0 && (
    <div className="homework-files">
      <h4>{t('Homework')}</h4>
      <div className="files-list">
        {uploadedPdfs[slot.classid].map((pdf, pdfIndex) => (
          <div key={pdfIndex} className="file-item">
            <FontAwesomeIcon icon={faFilePdf} className="file-icon" />
            <a
              href={`${API_URL}/upload/download/${pdf.filename}`}
              target="_blank"
              rel="noopener noreferrer"
              className="file-link"
            >
              {pdf.originalname}
            </a>
          </div>
        ))}
      </div>
    </div>
  )}
<>

  <button 
    className="view-submissions-button"
    onClick={() => handleOpenSubmissions(slot.classid)}
  >
    <FontAwesomeIcon icon={faFileAlt} />
    {t('View Submissions')} 
    ({studentUploadedPdfs[slot.classid]?.length || 0})
  </button>

  <Modal isOpen={isSubmittedHomeworkOpen} onClose={() => setIsSubmittedHomeworkOpen(false)}>
  <div className="submitted-homework-modal">
    <div className="modal-header">
      <h4>Submitted Homework</h4>
      {studentUploadedPdfs[selectedClassId]?.length > 0 && (
        <button onClick={downloadCSV}>Download Report</button>
      )}
    </div>
    {studentUploadedPdfs[selectedClassId]?.length > 0 ? (
      <div className="submitted-files-table scrollable-table-container">
        <table>
          <thead>
            <tr>
              <th>STUDENT NAME</th>
              <th>UPLOADED DATE</th>
              <th>FILES</th>
            </tr>
          </thead>
          <tbody>
            {studentUploadedPdfs[selectedClassId].map((pdf, index) => (
              <tr key={index}>
                <td>{pdf.nickname}</td>
                <td>{formatUploadDate(pdf.upload_date)}</td>
                <td>
                  <div className="file-download">
                    <a
                      href={`${API_URL}/upload/download/${pdf.filename}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {pdf.originalname}
                    </a>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <div className="no-homework-message">No Homework Uploaded</div>
    )}
  </div>
</Modal>

</>
  </div>
</div>

<button
  onClick={() => handleShowModal(slot)}
  disabled={submittedSchedules.includes(slot.classid)}
  className="attendance-button"
>
  <FontAwesomeIcon icon={submittedSchedules.includes(slot.classid) ? faLock : faPeopleGroup} />
</button>
    </div>
  ))}
</div>

        
      </div>

      {selectedClass && (
  <div className="attendance-dialog-backdrop" onClick={(e) => {
    if (e.target === e.currentTarget) setSelectedClass(null);
  }}>
    <div className="attendance-dialog">
      <div className="attendance-dialog-header">
        <div className="header-content">
          <h2>Attendance for {selectedClass?.subject_name}</h2>
          <div className="class-details">
            <div className="class-date">
              <FontAwesomeIcon icon={faCalendar} className="icon" />
              {moment(selectedClass?.date).format('DD-MMM-YYYY')}
            </div>
            <div className="class-time">
              <FontAwesomeIcon icon={faClock} className="icon" />
              {formatTime(selectedClass?.schedule_time)} - {calculateClassEndTime(selectedClass?.schedule_time, selectedClass?.schedule_hour)}
            </div>
          </div>
        </div>
        <div className="header-actions">
          <button onClick={downloadAttendanceCSV} className="download-button">
            Download Report
          </button>
          <button className="close-button" onClick={() => setSelectedClass(null)}>
            ×
          </button>
        </div>
      </div>
      <div className="attendance-dialog-content">
        <table className="attendance-table">
          <thead>
            <tr>
              <th>{t('studentName')}</th>
              <th>{t('status')}</th>
              <th>{t('homework')}</th>
              <th>{t('comments')}</th>
              <th>{t('time')}</th>
            </tr>
          </thead>
          <tbody>
            {registeredStudents.map((student) => {
              const savedRecord = savedAttendances[student.student_id];
              const currentAttendance = attendance[student.student_id];
              
              return (
                <tr key={student.student_id}>
                  <td>{student.student_name} ({student.nickname})</td>
                  {savedRecord && !editMode[student.student_id] ? (
                    <>
                      <td>
                        <div className={`attendance-status-saved status-${savedRecord.status.toLowerCase()}`}>
                          <FontAwesomeIcon icon={faLock} className="icon-lock" />
                          {savedRecord.status}
                        </div>
                      </td>
                      <td>
                        <div 
                          className="homework-status" 
                          style={homeworkStatusStyles[savedRecord.homework?.toLowerCase() === 'n/a' ? 'na' : savedRecord.homework?.toLowerCase()]}
                        >
                          <span className="homework-icon">
                            {savedRecord.homework === 'YES' && <FontAwesomeIcon icon={faCheck} />}
                            {savedRecord.homework === 'NO' && <FontAwesomeIcon icon={faTimes} />}
                            {savedRecord.homework === 'N/A' && <FontAwesomeIcon icon={faMinus} />}
                          </span>
                          {savedRecord.homework}
                        </div>
                      </td>
                      <td>{savedRecord.comment}</td>
                      <td>
                        <div className="attendance-info">
                          <div className="attendance-timestamp">
                            <FontAwesomeIcon icon={faClock} className="icon-clock" />
                            {moment(savedRecord.timestamp).format('DD-MMM-YYYY HH:mm')}
                          </div>
                          {savedRecord.edited_at && (
                            <div className="edited-timestamp">
                              <FontAwesomeIcon icon={faEdit} className="icon-edit" />
                              Edited: {moment(savedRecord.edited_at).format('DD-MMM-YYYY HH:mm')}
                            </div>
                          )}
                          <button 
                            onClick={() => handleEdit(student.student_id)}
                            className="edit-button"
                          >
                            <FontAwesomeIcon icon={faEdit} /> Edit
                          </button>
                        </div>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        <select
                          value={currentAttendance?.status || ''}
                          onChange={(e) => handleAttendanceChange(student.student_id, 'status', e.target.value)}
                          className="attendance-select"
                        >
                          <option value="">{t('selectStatus')}</option>
                          <option value="Present">{t('present')}</option>
                          <option value="Absent">{t('absent')}</option>
                          <option value="Late">{t('Late ≤ 15 M')}</option>
                        </select>
                      </td>
                      <td>
                        <select
                          value={homework[student.student_id] || ''}
                          onChange={(e) => handleHomeworkChange(student.student_id, e.target.value)}
                          className="homework-select"
                        >
                          <option value="">Select Status</option>
                          <option value="YES">YES</option>
                          <option value="NO">NO</option>
                          <option value="N/A">N/A</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          value={currentAttendance?.comment || ''}
                          onChange={(e) => handleAttendanceChange(student.student_id, 'comment', e.target.value)}
                          placeholder="Add Comment"
                          className="attendance-input"
                        />
                      </td>
                      <td>
                        <button
                          onClick={() => editMode[student.student_id] ? 
                            handleUpdate(student.student_id) : 
                            handleSaveAttendance(student.student_id)
                          }
                          disabled={savingInProgress || !currentAttendance?.status}
                          className="attendance-save-button"
                        >
                          <FontAwesomeIcon icon={faSave} className="save-icon" />
                          {editMode[student.student_id] ? 'Update' : 'Save'} Attendance
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  </div>
)}

<Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
  <div ref={timetableRef}>
    <h2>{classDetails?.class_code || t('scheduleDetails')}</h2> 
    <table>
      <thead>
        <tr>
        <th>{t('day')}</th>
        <th>{t('date')}</th>
          <th>{t('subjectName')}</th>
          <th>{t('time')}</th>
          <th>{t('teacherName')}</th>
          <th>{t('hours')}</th>
        </tr>
      </thead>
      <tbody>
        {classDetails.classes
          .sort((a, b) => {
            // First, compare by date
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);

            if (dateA.getTime() !== dateB.getTime()) {
              // If the dates are different, sort by date
              return dateA - dateB;
            } else {
              // If the dates are the same, sort by schedule_time (assuming it's in 'HH:mm' format)
              const timeA = a.schedule_time ? a.schedule_time.split(':').map(Number) : [0, 0]; // Default to 00:00 if time is missing
              const timeB = b.schedule_time ? b.schedule_time.split(':').map(Number) : [0, 0];

              const hoursDiff = timeA[0] - timeB[0];
              const minutesDiff = timeA[1] - timeB[1];

              return hoursDiff !== 0 ? hoursDiff : minutesDiff;
            }
          })
          .map((cls, index) => {
            const startTime = cls.schedule_time;
            const endTime = calculateEndTime(startTime, cls.schedule_hour);
            const hours = (cls.schedule_hour / 60).toFixed(2); // Convert minutes to hours
            return (
              <tr key={index}>
                <td>{moment(cls.date).format('dddd')}</td>
                <td>{formatDate(cls.date)}</td>
                <td>{cls.subject_name}</td>
                <td>{`${formatTime(startTime)} - ${endTime}`}</td>
                <td>{cls.nickname}</td>
                <td>{formatHours(hours)} {t('hrs')}</td> 
              </tr>
            );
          })} 
      </tbody>
    </table>
  </div>
</Modal>

    </div>
  );
};

export default withRoleAccess(ClassDetails, ['admin', 'superadmin', 'teacher']);
