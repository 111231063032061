import axios from 'axios';
import moment from 'moment-timezone';

const API_URL = process.env.REACT_APP_API_URL;

export const getTasks = async () => {
  try {
    const token = localStorage.getItem('token');

    // Fetch user-specific events
    const eventsResponse = await axios.get(`${API_URL}/events`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Fetch shared events
    const sharedCalendarResponse = await axios.get(`${API_URL}/events/shared-calendar`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Format user-specific events
    const events = eventsResponse.data.map((event) => ({
      ...event,
      start: moment.utc(event.start).local().format(),
      end: moment.utc(event.end).local().format(),
      shared: false, // Mark these as not shared
    }));

    // Format shared events, use `is_shared` directly from the backend
    const sharedEvents = sharedCalendarResponse.data.map((shared) => ({
      ...shared,
      start: moment.utc(shared.start).local().format(),
      end: moment.utc(shared.end).local().format(),
      shared: shared.is_shared, // Use the `is_shared` property provided by the backend
    }));

    // Combine user-specific events and shared events
    const combinedTasks = [...events, ...sharedEvents];

    console.log(
      'Combined tasks with shared flag:',
      combinedTasks.map((task) => ({ eventid: task.eventid, shared: task.shared }))
    );

    return combinedTasks;
  } catch (error) {
    console.error('Error fetching tasks and shared events:', error);
    throw error;
  }
};


export const createTask = async (taskDetails) => {
  try {
    const token = localStorage.getItem('token');
    const taskDetailsWithUTC = {
      ...taskDetails,
      start: moment(taskDetails.start).utc().format(), // Convert start time to UTC
      end: moment(taskDetails.end).utc().format(), // Convert end time to UTC
    };

    // API call to create the event
    const response = await axios.post(`${API_URL}/events`, taskDetailsWithUTC, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error creating task:', error);
    throw error;
  }
};


export const updateTask = async (taskId, updatedTaskDetails) => {
  try {
    const token = localStorage.getItem('token');

    // Convert start and end to UTC
    const { start, end, ...restDetails } = updatedTaskDetails;
    const startUTC = moment.tz(start, 'Asia/Bangkok').utc().format();
    const endUTC = moment.tz(end, 'Asia/Bangkok').utc().format();

    // Include the converted times in the update details
    const taskUpdateData = {
      ...restDetails,
      start: startUTC,
      end: endUTC,
    };

    const response = await axios.put(`${API_URL}/events/${taskId}`, taskUpdateData, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
};

export const deleteTask = async (taskId, params) => {
  const token = localStorage.getItem('token');
  if (!token) {
    throw new Error('No token found');
  }

  // Include deleteGroup flag as a query parameter in the DELETE request
  const response = await axios.delete(`${API_URL}/events/${taskId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: params, // Add the params (deleteGroup flag) here
  });

  return response.data;
};
