import React, { useState, useEffect } from "react";
import { Table, Input, Select, Button, Card, Typography, Space } from "antd";
import { CSVLink } from "react-csv";
import axios from "axios";
import withRoleAccess from "../../hoc/withRoleAccess";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faSearch } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import "./Classes.css";

const { Option } = Select;
const { Title } = Typography;

const AllClassList = () => {
  const { t, i18n } = useTranslation();
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [editingClassroomId, setEditingClassroomId] = useState(null);
  const [editedClassroomId, setEditedClassroomId] = useState('');
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM")); 
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 100,
    total: 0
  });

  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
};

const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };


  // Fetch classes
  const fetchClasses = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/classes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setClasses(response.data);
    } catch (error) {
      console.error("Error fetching classes:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch teachers
  const fetchTeachers = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/teachers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTeachers(response.data.filter((teacher) => teacher.status === "active"));
    } catch (error) {
      console.error("Error fetching teachers:", error);
    }
  };

  useEffect(() => {
    fetchClasses();
    fetchTeachers();
  }, []);


  useEffect(() => {
    const fetchClassrooms = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/classrooms`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
  
        const classroomsData = response.data || [];
  
        // Filter classrooms with availability 'true'
        const availableClassrooms = classroomsData.filter(classroom => classroom.availability === true);
  
        // Sort by 'number' field in alphanumeric order
        const sortedClassrooms = availableClassrooms.sort((a, b) => {
          return a.number.localeCompare(b.number, undefined, { numeric: true, sensitivity: 'base' });
        });
  
        setClassrooms(sortedClassrooms);
      } catch (error) {
        console.error('Error fetching classrooms:', error);
      }
    };
  
    fetchClassrooms();
  }, [API_URL]);


  const handleEditClassroomClick = (classId, classroomId) => {
    setEditingClassroomId(classId);
    setEditedClassroomId(classroomId); // Set initial classroom ID
  };

  const handleSaveClassroomClick = async (classId) => {
    try {
      const token = localStorage.getItem('token');
      const updatedClass = {
        classroom_id: editedClassroomId,
      };

      await axios.put(`${API_URL}/classes/${classId}`, updatedClass, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setEditingClassroomId(null);
      setEditedClassroomId('');
      fetchClasses(); // Refresh the class list
    } catch (error) {
      console.error('Error updating classroom:', error);
    }
  };

  // Filter classes based on selected date (month/year)
  const filteredClasses = classes.filter((classItem) => {
    const isInMonthYear =
      moment(classItem.date).format("YYYY-MM") === selectedDate;
    const isSearchMatch =
      classItem.subject_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      classItem.class_code?.toLowerCase().includes(searchTerm.toLowerCase());
    const isTeacherMatch =
      !selectedTeacher || classItem.teacher_id === selectedTeacher;

    return isInMonthYear && isSearchMatch && isTeacherMatch;
  });

  useEffect(() => {
    setPagination(prev => ({
      ...prev,
      total: filteredClasses.length
    }));
  }, [filteredClasses]);

  const handleTableChange = (newPagination, filters, sorter) => {
    setPagination(prev => ({
      ...prev,
      current: newPagination.current,
      pageSize: newPagination.pageSize
    }));
  };

  const getCurrentPageData = () => {
    const { current, pageSize } = pagination;
    const startIndex = (current - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return filteredClasses.slice(startIndex, endIndex);
  };

  // CSV Data for Export
  const csvData = filteredClasses.map((classItem) => ({
    Date: formatDate(classItem.date),
    Day: new Date(classItem.date).toLocaleDateString("en-US", { weekday: "long" }),
    "Class Code": classItem.class_code,
    Subject: classItem.subject_name,
    Tutor:
      teachers.find((teacher) => teacher.userid === classItem.teacher_id)?.nickname ||
      "N/A",
    "Start Time": formatTime(classItem.schedule_time),
    "End Time": classItem.end_time,
    Hours: classItem.schedule_hour,
    Room: classItem.classroom_number,
    Type: classItem.isprivate ? "Private" : "Group",
  }));

  const columns = [
    {
      title: t("Date"),
      dataIndex: "date",
      key: "date",
      width: 120,
      render: (date) => moment(date).format("DD MMM YYYY"),
      fixed: "left",
    },
    {
      title: t("Day"),
      dataIndex: "day",
      key: "day",
      width: 100,
      render: (_, record) => (
        <span className="day-cell">
          {new Date(record.date).toLocaleDateString("en-US", { weekday: "long" })}
        </span>
      ),
    },
    {
      title: t("Time"),
      dataIndex: "time",
      key: "time",
      width: 120,
      render: (_, record) => (
        <span>
          {moment(record.schedule_time, "HH:mm:ss").format("HH:mm")} -{" "}
          {moment(record.end_time, "HH:mm:ss").format("HH:mm")}
        </span>
      ),
    },
    {
      title: t("Class Code"),
      dataIndex: "class_code",
      key: "class_code",
      width: 250,
      render: (text) => <div className="wrapped-cell">{text}</div>,
    },
    {
      title: t("Subject"),
      dataIndex: "subject_name",
      key: "subject_name",
      width: 250,
      render: (text) => <div className="wrapped-cell">{text}</div>,
    },
    {
      title: t("Tutor"),
      dataIndex: "teacher_name",
      key: "teacher_name",
      width: 100,
      render: (_, record) => (
        <span className="tutor-cell">
          {teachers.find((teacher) => teacher.userid === record.teacher_id)?.nickname ||
            t("N/A")}
        </span>
      ),
    },
    {
        title: t("Room"),
        dataIndex: "classroom_number",
        key: "classroom_number",
        width: 150,
        align: "center",
        render: (_, record) => {
          return editingClassroomId === record.classid ? (
            <Space direction="vertical">
              <Select
                value={editedClassroomId || undefined}
                onChange={(value) => setEditedClassroomId(value)}
                style={{ width: "100%" }}
              >
                {classrooms.map((classroom) => (
                  <Option key={classroom.classroomid} value={classroom.classroomid}>
                    {classroom.number}
                  </Option>
                ))}
              </Select>
              <Space>
                <Button
                  type="primary"
                  size="small"
                  onClick={() => handleSaveClassroomClick(record.classid)}
                >
                  {t("Save")}
                </Button>
                <Button
                  size="small"
                  onClick={() => setEditingClassroomId(null)}
                >
                  {t("Cancel")}
                </Button>
              </Space>
            </Space>
          ) : (
            <Space>
              <span>{record.classroom_number || t("N/A")}</span>
              <Button
                type="link"
                onClick={() => handleEditClassroomClick(record.classid, record.classroom_id)}
              >
                {t("Edit")}
              </Button>
            </Space>
          );
        },
      },      
    {
      title: t("Type"),
      dataIndex: "isprivate",
      key: "isprivate",
      width: 90,
      align: "center",
      render: (isPrivate) => (
        <span
          style={{
            display: "inline-block",
            padding: "4px 8px",
            borderRadius: "4px",
            fontSize: "12px",
            lineHeight: 1,
            whiteSpace: "nowrap",
            background: isPrivate ? "#e6f4ff" : "#f6ffed",
            color: isPrivate ? "#1890ff" : "#52c41a",
            border: `1px solid ${isPrivate ? "#91caff" : "#b7eb8f"}`,
          }}
        >
          {isPrivate ? t("Private") : t("Group")}
        </span>
      ),
    },
  ];

  return (
    <Card className="class-list-container">
      <div className="header-section">
        <Title level={3}>{t("All Class List")}</Title>
      </div>

      <div className="cc-search-container">
        <Space size="middle">
          {/* Date Picker */}
          <input
            type="month"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            style={{
              padding: "8px",
              border: "1px solid #d9d9d9",
              borderRadius: "4px",
              fontSize: "14px",
            }}
          />

          <Input
            prefix={
              <FontAwesomeIcon icon={faSearch} style={{ color: "#bfbfbf" }} />
            }
            placeholder={t("Search by subject or class code")}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ width: 300 }}
            allowClear
          />

          <Select
            placeholder={t("Filter by teacher")}
            value={selectedTeacher}
            onChange={(value) => setSelectedTeacher(value)}
            style={{ width: 200 }}
            allowClear
          >
            <Option value="">{t("All Teachers")}</Option>
            {teachers.map((teacher) => (
              <Option key={teacher.userid} value={teacher.userid}>
                {teacher.nickname} {teacher.firstname}
              </Option>
            ))}
          </Select>

          <CSVLink
            data={csvData}
            filename={`class_list_${moment().format("YYYY-MM-DD")}.csv`}
          >
            <Button
              type="primary"
              className="export-button"
              icon={<FontAwesomeIcon icon={faFileExcel} />}
            >
              {t("Export CSV")}
            </Button>
          </CSVLink>
        </Space>
      </div>

      <Table
        dataSource={getCurrentPageData()}
        columns={columns}
        rowKey="classid"
        loading={loading}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total,
          showSizeChanger: true,
          pageSizeOptions: ["50", "100", "200"],
          showTotal: (total, range) => 
            `${range[0]}-${range[1]} of ${total} items`
        }}
        onChange={handleTableChange}
        scroll={{ x: "max-content" }}
        size="middle"
      />
    </Card>
  );
};

export default withRoleAccess(AllClassList, ["admin", "superadmin"]);
