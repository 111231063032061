import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import TaskPopup from './TaskPopup';
import { getTasks, updateTask } from './apiService';
import moment from 'moment';
import 'moment-timezone';
import axios from 'axios';
import thLocale from '@fullcalendar/core/locales/th';
import { useTranslation } from 'react-i18next';
import './Calendar.css';
import { Card, List, Typography, Space } from 'antd'; // Import Ant Design components
import { CalendarOutlined, ClockCircleOutlined} from '@ant-design/icons';
import { confirmAlert } from 'react-confirm-alert'; // Correctly import confirmAlert
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import CSS for react-confirm-alert

const { Title, Text } = Typography;

const Calendar = ({ onTasksChange, onClassesChange }) => {
  const { t, i18n } = useTranslation();
  const [users, setUsers] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [classes, setClasses] = useState([]);
  const [isTaskPopupOpen, setIsTaskPopupOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [dateSelected, setDateSelected] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchTasks();
    fetchClasses();
    fetchUsersWithRoles();
  }, []);


  const fetchTasks = async () => {
    try {
      const fetchedTasks = await getTasks();
      const transformedTasks = fetchedTasks.map(task => ({
        id: `task-${task.eventid.toString()}`, // Add a prefix to ensure uniqueness
        title: task.title,
        start: moment(task.start).local().toISOString(),
        end: moment(task.end).local().toISOString(),
        description: task.description,
        type: task.shared ? 'shared-task' : 'task', // Differentiate shared tasks
        shared: task.shared, // Pass along the shared property for event class determination
        schedule_hour: moment(task.end).diff(moment(task.start), 'minutes')
      }));
      setTasks(transformedTasks);
      onTasksChange(transformedTasks); // Notify parent component of the task changes
    } catch (error) {
      console.error("Failed to fetch tasks:", error);
    }
  };
  

  const fetchClasses = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/classes/teacher`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const transformedClasses = response.data.map((cls) => {
        const startDate = moment(cls.date).format('YYYY-MM-DD') + 'T' + cls.schedule_time;
        const endDate = moment(cls.date).format('YYYY-MM-DD') + 'T' + cls.end_time;
  
        // Conditionally build the title string
        const titleParts = [
          cls.class_code,
          cls.subject_name,
          cls.classroom_number && cls.classroom_number !== 'null' ? cls.classroom_number : null,
        ].filter(Boolean); // Remove any falsy values like null or undefined
  
        return {
          id: `class-${cls.classid}`, // Add a prefix to ensure uniqueness
          title: titleParts.join(' - '), // Join the parts with ' - '
          start: startDate,
          end: endDate,
          type: 'class',
        };
      });
  
      setClasses(transformedClasses);
      onClassesChange(transformedClasses);
    } catch (error) {
      console.error('Failed to fetch classes:', error);
    }
  };
  

  const fetchUsersWithRoles = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Filter users by role
      const filteredUsers = response.data.filter((user) =>
        ['superadmin', 'admin', 'teacher'].includes(user.role)
      );
  
      setUsers(filteredUsers);
    } catch (error) {
      console.error('Error fetching users with roles:', error);
    }
  };
  

  const handleDateClick = (arg) => {
    const newStart = moment(arg.start);
    const newEnd = moment(arg.end);
  
    // Prevent tasks from spanning multiple days
    if (!newStart.isSame(newEnd, 'day')) {
      confirmAlert({
        title: t('Invalid Time Range'),
        message: t('Tasks cannot span multiple days. Please select a time range within the same day.'),
        buttons: [
          {
            label: t('Ok'),
            onClick: () => {},
          },
        ],
      });
      return; // Prevent task creation
    }
  
    // Check for any overlap with existing classes
    const isOverlapping = classes.some((cls) => {
      const classStart = moment(cls.start);
      const classEnd = moment(cls.end);
  
      // Overlap check: new task start or end falls within the class time, or fully overlaps
      return (
        newStart.isBetween(classStart, classEnd, null, '[)') ||
        newEnd.isBetween(classStart, classEnd, null, '(]') ||
        (newStart.isBefore(classStart) && newEnd.isAfter(classEnd)) // full overlap
      );
    });
  
    if (isOverlapping) {
      confirmAlert({
        title: t('Time Slot Conflict'),
        message: t('The selected time overlaps with an existing class. Please choose a different time.'),
        buttons: [
          {
            label: t('Ok'),
            onClick: () => {},
          },
        ],
      });
      return; // Prevent task creation if overlap exists
    }
  
    // Proceed to create the task if all validations pass
    if (arg.view.type !== 'dayGridMonth') {
      setDateSelected({ start: arg.start, end: arg.end });
      setSelectedTask(null);
      setIsTaskPopupOpen(true);
    }
  };
  

  const handleEventClick = ({ event }) => {
    const taskToEdit = tasks.find(task => task.id === event.id);
    if (taskToEdit) {
      setSelectedTask(taskToEdit);
      setIsTaskPopupOpen(true);
    }
  };

  const handleEventDrop = (info) => {
    const newStart = moment(info.event.start);
    const newEnd = moment(info.event.end);
  
    // Check if the event crosses into the next day
    if (!newStart.isSame(newEnd, 'day')) {
      // Revert the event to its original position and show an alert
      confirmAlert({
        title: t('Invalid Time Range'),
        message: t('You cannot drag an event to span across multiple days.'),
        buttons: [
          {
            label: t('Ok'),
            onClick: () => info.revert(), // Revert the event to its original position
          },
        ],
      });
      return; // Prevent further execution
    }
  
    // Proceed with existing overlap check and confirmation logic
    const isOverlapping = [...tasks, ...classes].some(event => {
      if (event.id === info.event.id) return false; // Skip the same event that is being dragged
  
      const eventStart = moment(event.start);
      const eventEnd = moment(event.end);
  
      return (
        newStart.isBetween(eventStart, eventEnd, null, '[)') || 
        newEnd.isBetween(eventStart, eventEnd, null, '(]') || 
        (newStart.isBefore(eventStart) && newEnd.isAfter(eventEnd)) // Full overlap
      );
    });
  
    if (isOverlapping) {
      confirmAlert({
        title: t('Time Slot Conflict'),
        message: t('The selected time overlaps with an existing class or event. Please choose a different time.'),
        buttons: [
          {
            label: t('Ok'),
            onClick: () => info.revert(),
          },
        ],
      });
      return;
    }
  
    // Confirm the change if all validations pass
    confirmAlert({
      title: t('Confirm to submit'),
      message: t('Are you sure you want to change the event?'),
      buttons: [
        {
          label: t('Yes'),
          onClick: () => confirmEventChange(info),
        },
        {
          label: t('No'),
          onClick: () => info.revert(),
        },
      ],
    });
  };
  
  

  const handleEventResize = (info) => {
    const newStart = moment(info.event.start);
    const newEnd = moment(info.event.end);
    const eventId = info.event.id;
  
    // Check for overlap with existing tasks and classes
    const isOverlapping = [...tasks, ...classes].some(event => {
      if (event.id === eventId) return false; // Skip the same event that is being resized
  
      const eventStart = moment(event.start);
      const eventEnd = moment(event.end);
  
      // Overlap check: new event start or end falls within an existing event time, or fully overlaps
      return (
        newStart.isBetween(eventStart, eventEnd, null, '[)') || 
        newEnd.isBetween(eventStart, eventEnd, null, '(]') || 
        (newStart.isBefore(eventStart) && newEnd.isAfter(eventEnd)) // full overlap
      );
    });
  
    if (isOverlapping) {
      // Prevent resize and show confirmation alert if there is an overlap
      confirmAlert({
        title: t('Time Slot Conflict'),
        message: t('The selected time overlaps with an existing class or event. Please choose a different time.'),
        buttons: [
          {
            label: t('Ok'),
            onClick: () => info.revert(), // Revert the event to its original size
          }
        ]
      });
      return; // Stop further execution if overlap exists
    }
  
    // Proceed with the confirmation and task update if there is no overlap
    confirmAlert({
      title: t('Confirm to submit'),
      message: t('Are you sure you want to resize the event?'),
      buttons: [
        {
          label: t('yes'),
          onClick: () => confirmEventChange(info),
        },
        {
          label: t('no'),
          onClick: () => info.revert(),
        },
      ],
    });
  };
  

  const confirmEventChange = async (info) => {
    let taskId = info.event.id;
  
    // Remove prefix from the ID before sending to backend
    taskId = taskId.replace(/^task-/, '').replace(/^class-/, '');
  
    const newStart = info.event.start;
    const newEnd = info.event.end;
    const formattedStart = moment(newStart).format('YYYY-MM-DD HH:mm:ss');
    const formattedEnd = newEnd ? moment(newEnd).format('YYYY-MM-DD HH:mm:ss') : null;
  
    const taskToEdit = tasks.find(task => task.id === `task-${taskId}`);
    if (!taskToEdit) {
      console.error('Task not found');
      info.revert();
      return;
    }
  
    const scheduleDay = moment(newStart).format('dddd'); // Get the day of the week
  
    const taskUpdateData = {
      title: taskToEdit.title,
      description: taskToEdit.description,
      start: formattedStart,
      end: formattedEnd,
      schedule_day: scheduleDay, // Include the schedule_day
    };
  
    try {
      await updateTask(taskId, taskUpdateData);
      fetchTasks();
    } catch (error) {
      console.error('Failed to update task', error);
      info.revert();
    }
  };
  
  const handleTasksChanged = () => {
    fetchTasks();
    setIsTaskPopupOpen(false);
  };

  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div className="event-content">
        {eventInfo.view.type === 'dayGridMonth' ? (
          <div className="event-title">
            {moment(eventInfo.event.start).format('HH:mm')} - {moment(eventInfo.event.end).format('HH:mm')} {eventInfo.event.title}
          </div>
        ) : (
          <>
            <div className="event-title">
              {moment(eventInfo.event.start).format('HH:mm')} - {moment(eventInfo.event.end).format('HH:mm')}
            </div>
            <div>{eventInfo.event.title}</div>
            <div className="event-description">{eventInfo.event.extendedProps.description}</div>
            {eventInfo.event.extendedProps.schedule_hour !== undefined && (
              <div className="event-hours">{formatMinutesToHours(eventInfo.event.extendedProps.schedule_hour)}</div>
            )}
          </>
        )}
      </div>
    );
  };

  // Combine tasks and classes for the calendar events
  const events = [...tasks, ...classes];

  // Filter tasks for the current week
  const startOfWeek = moment().startOf('week');
  const endOfWeek = moment().endOf('week');
  const weekTasks = tasks.filter(task =>
    moment(task.start).isBetween(startOfWeek, endOfWeek, null, '[]')
  );

  // Sort tasks by start time (earliest first)
  weekTasks.sort((a, b) => moment(a.start) - moment(b.start));

  const combinedTasksAndClasses = classes // Use only classes
  .filter(item => moment(item.start).isSameOrAfter(moment())) // Filter out past classes
  .sort((a, b) => moment(a.start) - moment(b.start));

// Filter for the most upcoming 10 classes
const upcomingTasksAndClasses = combinedTasksAndClasses.slice(0, 5);


  return (
    <div className="calendar-layout">
      <div className="calendar-container">
        <div className="calendar-border">
        <FullCalendar
  contentHeight="auto"
  editable={true}
  droppable={true}
  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
  headerToolbar={{
    left: 'prev,next today',
    center: 'title',
    right: 'dayGridMonth,timeGridWeek,timeGridDay',
  }}
  initialView="dayGridMonth"
  selectable={true}
  locale={i18n.language === 'th' ? thLocale : 'en'} // Apply the locale
  selectMirror={true}
  select={handleDateClick}
  events={events.map(event => ({
    ...event,
    extendedProps: {
      ...event,
      shared: event.shared, // Explicitly pass shared flag
    },
  }))}
  eventClick={handleEventClick}
  eventDrop={handleEventDrop}
  eventResize={handleEventResize}
  eventResizableFromStart={true}
  eventDurationEditable={true}
  allDaySlot={false}
  slotLabelFormat={{
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }}
  eventTimeFormat={{
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }}
  slotMinTime="06:00:00"
  eventContent={renderEventContent}
  eventConstraint={{
    start: '00:00', // Restrict dragging/resizing start to the same day
    end: '23:59:59', // Restrict dragging/resizing end to the same day
  }}
  eventClassNames={(info) => {
    if (info.event.extendedProps.shared) {
      return ['shared-event'];
    } else if (info.event.extendedProps.type === 'class') {
      return ['class-event'];
    } else {
      return ['task-event'];
    }
  }}
/>

        </div>
      </div>      
      <div className="task-list-container-side">
        <List
          dataSource={upcomingTasksAndClasses}
          renderItem={(item) => (
            <List.Item onClick={() => handleEventClick({ event: item })}>
              <Card hoverable>
                <Space direction="vertical">
                  <Title level={5}>{item.title}</Title>
                  <Space>
                    <CalendarOutlined />
                    <Text>
                      {moment(item.start).locale(i18n.language).format('LL')}
                    </Text>
                  </Space>
                  <Space>
                    <ClockCircleOutlined />
                    <Text>
                      {moment(item.start).locale(i18n.language).format('LT')} -{' '}
                      {moment(item.end).locale(i18n.language).format('LT')}
                    </Text>
                  </Space>
                </Space>
              </Card>
            </List.Item>
          )}
        />
      </div>
      {isTaskPopupOpen && (
        <TaskPopup
        isOpen={isTaskPopupOpen}
        onClose={() => setIsTaskPopupOpen(false)}
        onSave={handleTasksChanged}
        task={selectedTask}
        date={dateSelected}
        tasks={tasks}
        classes={classes}
        users={users} // Pass users to TaskPopup
      />      
      )}
    </div>
  );
};

export default Calendar;