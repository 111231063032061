import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { Layout, Table, Modal, Button, Input, Select, DatePicker, Row, Col, Card } from 'antd';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import './Registrations.css';
import moment from 'moment-timezone';

const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

const testTypes = [
  { name: "GED", subtypes: ["Mathematical Reasoning", "Reasoning Through Language Arts", "Science", "Social Studies"] },
  { name: "IGCSE", subtypes: ["Mathematics", "English", "Sciences (Biology, Chemistry, Physics)", "History", "Geography", "Foreign Languages", "Art & Design", "Business Studies", "Computer Science"] },
  { name: "A-LEVEL", subtypes: ["Mathematics", "Further Mathematics", "Physics", "Chemistry", "Biology", "English Literature", "History", "Geography", "Economics", "Psychology"] },
  { name: "SAT", subtypes: ["SAT (General)", "SAT Subject Tests"] },
  { name: "IELTS", subtypes: ["IELTS Academic", "IELTS General Training"] },
  { name: "AP", subtypes: ["AP Calculus AB", "AP Calculus BC", "AP Physics", "AP Chemistry", "AP Biology", "AP English Language and Composition", "AP English Literature and Composition", "AP U.S. History", "AP World History", "AP Psychology"] },
  { name: "IB", subtypes: ["IB Mathematics", "IB Physics", "IB Chemistry", "IB Biology", "IB English A", "IB English B", "IB History", "IB Economics", "IB Psychology", "Theory of Knowledge (TOK)"] },
  { name: "TOEFL", subtypes: ["TOEFL iBT", "TOEFL PBT"] },
  { name: "TOEFL-MUIC", subtypes: ["General", "IBT"] },
  { name: "CU-AAT", subtypes: ["ธรรมดา", "E-Testing"] },
  { name: "CU-ATS", subtypes: ["Physics", "Chemistry"] },
  { name: "CU-TEP", subtypes: ["ธรรมดา + Speaking", "E-Testing", "E-Testing + CU-TEP E-Testing Speaking"] },
  { name: "TU-GET", subtypes: ["PBT", "CBT"] },
  { name: "OTHERS", subtypes: [] }
];

const TestScore = () => {
  const { t } = useTranslation();
  const [testScores, setTestScores] = useState([]);
  const [students, setStudents] = useState([]);
  const [formData, setFormData] = useState({
    test_date: '',
    test_name: null, // Allow null value for Details
    score: '',
    type: '',
    sub_type: '',
    student_id: '',
    note: ''
  });
  const [error, setError] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedTestType, setSelectedTestType] = useState('');
  const itemsPerPage = 100;
  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = moment.tz(dateString, 'Asia/Bangkok'); // Convert to Bangkok timezone
    return date.isValid() ? date.format('DD MMM YY').toUpperCase() : 'Invalid date';
  };

  const fetchTestScores = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/test-scores`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setTestScores(response.data);
    } catch (error) {
      console.error('Error fetching test scores:', error);
    }
  }, [API_URL]);

  const fetchStudents = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/students`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setStudents(response.data.students);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchTestScores();
    fetchStudents();
  }, [fetchTestScores, fetchStudents]);

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');

      // Ensure test_date is converted to ISO format in Bangkok timezone
      const formDataToSubmit = {
        ...formData,
        test_date: formData.test_date ? formData.test_date.tz('Asia/Bangkok').format() : null,
      };

      await axios.post(`${API_URL}/test-scores`, formDataToSubmit, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setFormData({
        test_date: '',
        test_name: null,
        score: '',
        type: '',
        sub_type: '',
        student_id: '',
        note: ''
      });
      setIsModalVisible(false);
      fetchTestScores();
    } catch (error) {
      console.error('Error submitting test score:', error);
      setError(t('Error submitting test score'));
    }
  };

  const handleFormChange = (key, value) => {
    setFormData((prevData) => ({ ...prevData, [key]: value }));
  };

  const getSubtypes = () => {
    const selectedTestType = testTypes.find((type) => type.name === formData.type);
    return selectedTestType ? selectedTestType.subtypes : [];
  };

  const getFilteredData = useCallback(() => {
    return testScores.filter(score => {
      const fullName = `${score.firstname} ${score.lastname} ${score.nickname}`.toLowerCase();
      const searchMatch = !searchText || fullName.includes(searchText.toLowerCase());

      const typeMatch = !selectedTestType || score.type === selectedTestType;

      let dateMatch = true;
      if (selectedDateRange && selectedDateRange.length === 2) {
        const testDate = moment.tz(score.test_date, 'Asia/Bangkok');
        const startDate = moment.tz(selectedDateRange[0], 'Asia/Bangkok').startOf('day');
        const endDate = moment.tz(selectedDateRange[1], 'Asia/Bangkok').endOf('day');

        dateMatch = testDate.isBetween(startDate, endDate, null, '[]');
      }

      return searchMatch && typeMatch && dateMatch;
    });
  }, [testScores, searchText, selectedTestType, selectedDateRange]);

  const columns = [
    { title: t('Student'), dataIndex: 'firstname', key: 'firstname', render: (_, record) => `${record.firstname} ${record.lastname}` },
    { title: t('Nickname'), dataIndex: 'nickname', key: 'nickname' },
    { title: t('Test Date'), dataIndex: 'test_date', key: 'test_date', render: formatDate },
    { title: t('Type'), dataIndex: 'type', key: 'type' },
    { title: t('Subject'), dataIndex: 'sub_type', key: 'sub_type' },
    { title: t('Details'), dataIndex: 'test_name', key: 'test_name', render: (text) => text || '-' },
    { title: t('Score'), dataIndex: 'score', key: 'score' },
    { title: t('Note'), dataIndex: 'note', key: 'note' },
  ];

  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Header />
        <Content>
        <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Card>
                    <Row gutter={16} align="middle">
                      <Col xs={24} sm={6} lg={4}>
                        <Button type="primary" onClick={() => setIsModalVisible(true)} style={{ width: '100%' }}>
                          {t('Add Student Score')}
                        </Button>
                      </Col>
                      <Col xs={24} sm={6} lg={5}>
                        <Input
                          placeholder={t('Search by name or nickname')}
                          value={searchText}
                          onChange={e => setSearchText(e.target.value)}
                          style={{ width: '100%' }}
                          allowClear
                        />
                      </Col>
                      <Col xs={24} sm={6} lg={8}>
                        <RangePicker
                          value={selectedDateRange}
                          onChange={setSelectedDateRange}
                          placeholder={[t('Start Date'), t('End Date')]}
                          style={{ width: '100%' }}
                        />
                      </Col>
                      <Col xs={24} sm={6} lg={7}>
                        <Select
                          placeholder={t('Select Test Type')}
                          value={selectedTestType}
                          onChange={setSelectedTestType}
                          style={{ width: '100%' }}
                          allowClear
                        >
                          {testTypes.map(type => (
                            <Option key={type.name} value={type.name}>
                              {type.name}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
          <Modal
            title={t('Add Student Score')}
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            onOk={handleSubmit}
          >
            <div className="form-group">
              <label>{t('Student')}</label>
              <Select
                value={formData.student_id}
                onChange={(value) => handleFormChange('student_id', value)}
                placeholder={t('Select Student')}
                style={{ width: '100%' }}
              >
                {students.map(student => (
                  <Option key={student.userid} value={student.userid}>
                    {`${student.firstname} ${student.lastname} (${student.nickname})`}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="form-group">
              <label>{t('Test Date')}</label>
              <DatePicker
                value={formData.test_date ? moment.tz(formData.test_date, 'Asia/Bangkok') : null}
                onChange={(date) => handleFormChange('test_date', date ? moment.tz(date, 'Asia/Bangkok') : null)}
                style={{ width: '100%' }}
              />
            </div>
            <div className="form-group">
              <label>{t('Test Type')}</label>
              <Select
                value={formData.type}
                onChange={(value) => handleFormChange('type', value)}
                placeholder={t('Select Test Type')}
                style={{ width: '100%' }}
              >
                {testTypes.map((type) => (
                  <Option key={type.name} value={type.name}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </div>
            {formData.type && (
              <div className="form-group">
                <label>{t('Subject')}</label>
                <Select
                  value={formData.sub_type}
                  onChange={(value) => handleFormChange('sub_type', value)}
                  placeholder={t('Select Subject')}
                  style={{ width: '100%' }}
                >
                  {getSubtypes().map((subtype, index) => (
                    <Option key={index} value={subtype}>
                      {subtype}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            <div className="form-group">
              <label>{t('Details')}</label>
              <Input
                value={formData.test_name || ''} // Allow Details to be empty
                onChange={(e) => handleFormChange('test_name', e.target.value)}
                placeholder={t('Enter test details')}
              />
            </div>
            <div className="form-group">
              <label>{t('Score')}</label>
              <Input
                value={formData.score}
                onChange={(e) => handleFormChange('score', e.target.value)}
                placeholder={t('Enter score')}
              />
            </div>
            <div className="form-group">
              <label>{t('Note')}</label>
              <Input.TextArea
                value={formData.note}
                onChange={(e) => handleFormChange('note', e.target.value)}
                rows={4}
                placeholder={t('Enter any additional notes')}
              />
            </div>
            {error && <div className="error-message">{error}</div>}
          </Modal>
          <Table
            columns={columns}
            dataSource={getFilteredData()}
            pagination={{
              current: currentPage,
              pageSize: itemsPerPage,
              total: getFilteredData().length,
              onChange: setCurrentPage,
            }}
            rowKey="id"
          />
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRoleAccess(TestScore, ['admin', 'superadmin']);
