import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import {
  Layout,
  Tabs,
  Input,
  DatePicker,
  Table,
  Space,
  Pagination,
  Tag,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import TeacherRatingReport from './TeacherRatingReport';
import TestScore from './TestScore';
import BookTest from './BookTest';
import './Registrations.css';

const { Content } = Layout;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const RegistrationList = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [studentSearchTerm, setStudentSearchTerm] = useState('');
const [courseSearchTerm, setCourseSearchTerm] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(150);
  const [activeTab, setActiveTab] = useState('attendance');
  const { t } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    if (isNaN(date)) return 'Invalid date';
    return date.toLocaleDateString('en-GB', { 
      day: '2-digit', 
      month: 'short', 
      year: 'numeric' 
    }).toUpperCase();
  };
  
  const formatDateTime = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    if (isNaN(date)) return 'Invalid date';
    return date.toLocaleDateString('en-GB', { 
      day: '2-digit', 
      month: 'short', 
      year: 'numeric'
    }).toUpperCase() + ' ' + 
    date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
  };

  const fetchAttendanceData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/attendance`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sortedAttendance = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      setAttendanceData(sortedAttendance);
      setFilteredClasses(sortedAttendance);
    } catch (error) {
      console.error('Error fetching attendance data:', error);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchAttendanceData();
  }, [fetchAttendanceData]);

  useEffect(() => {
    let filtered = attendanceData.filter((attendance) => {
      const matchesStudent = attendance.student_nickname?.toLowerCase().includes(studentSearchTerm.toLowerCase());
      const matchesCourse = attendance.class_code?.toLowerCase().includes(courseSearchTerm.toLowerCase());
      
      // Both search terms must match if both are provided
      return matchesStudent && matchesCourse;
    });
  
    if (fromDate && toDate) {
      filtered = filtered.filter((attendance) => {
        const attendanceDate = new Date(attendance.date);
        return attendanceDate >= fromDate && attendanceDate <= toDate;
      });
    }
  
    setFilteredClasses(filtered);
    setCurrentPage(1);
  }, [studentSearchTerm, courseSearchTerm, attendanceData, fromDate, toDate]);

  const currentItems = filteredClasses.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const columns = [
    { title: t('student'), dataIndex: 'student_nickname', key: 'student_nickname' },
    { title: t('course'), dataIndex: 'class_code', key: 'class_code' },
    { title: t('classid'), dataIndex: 'classid', key: 'classid' },
    { title: t('subject'), dataIndex: 'subject_name', key: 'subject_name' },
    { 
      title: t('teacher'), 
      dataIndex: 'teacher_nickname', 
      key: 'teacher_nickname',
      render: (teacher_nickname) => teacher_nickname || '-'
    },
    {
      title: t('type'),
      dataIndex: 'isprivate',
      key: 'isprivate',
      render: (isprivate) => (isprivate ? t('private') : t('group')),
    },
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      render: (date) => formatDate(date),
    },
    {
      title: t('attendance'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={status === 'Present' ? 'green' : status === 'Absent' ? 'red' : 'orange'}>
          {status}
        </Tag>
      ),
    },
    {
      title: t('homework'),
      dataIndex: 'homework',
      key: 'homework',
      render: (homework) => (
        <Tag color={
          homework === 'YES' ? 'green' : 
          homework === 'NO' ? 'red' : 
          'default'
        }>
          {homework || 'N/A'}
        </Tag>
      ),
    },
    { 
      title: t('comments'), 
      dataIndex: 'comment', 
      key: 'comment',
      width: 250,
      render: (text) => (
        <div 
          style={{ 
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            minWidth: '150px',
            maxWidth: '250px',
            lineHeight: '1.5'
          }}
        >
          {text || '-'}
        </div>
      )
    },
    {
      title: t('timestamp'),
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp) => formatDateTime(timestamp)
    },
    {
      title: t('Last Edited'),
      dataIndex: 'edited_at',
      key: 'edited_at',
      render: (edited_at) => formatDateTime(edited_at)
    },
];

// Update the CSV data mapping
const csvData = currentItems.map((attendance) => ({
    student: attendance.student_nickname,
    course: attendance.class_code,
    classid: attendance.classid,
    subject: attendance.subject_name,
    teacher: attendance.teacher_nickname || '-',
    type: attendance.isprivate ? t('private') : t('group'),
    date: formatDate(attendance.date),
    attendance: attendance.status,
    homework: attendance.homework || 'N/A',
    comments: attendance.comment,
    timestamp: formatDateTime(attendance.timestamp),
    lastEdited: formatDateTime(attendance.edited_at)
}));

  return (
    <Layout>
      <Sidebar />
      <Layout>
        <Header />
        <Content style={{ marginTop: '60px', marginLeft: '70px', padding: '24px' }}>
          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            <TabPane tab={t('Attendance')} key="attendance">
            <Space style={{ marginBottom: 16 }} wrap>
                <Input
                  placeholder={t('searchByStudentName')}
                  value={studentSearchTerm}
                  onChange={(e) => setStudentSearchTerm(e.target.value)}
                  style={{ width: 200 }}
                  allowClear // Adds an X button to clear the input
                />
                <Input
                  placeholder={t('searchByCourse')}
                  value={courseSearchTerm}
                  onChange={(e) => setCourseSearchTerm(e.target.value)}
                  style={{ width: 200 }}
                  allowClear
                />
                <RangePicker
                  onChange={(dates) => {
                    setFromDate(dates ? dates[0].toDate() : null);
                    setToDate(dates ? dates[1].toDate() : null);
                  }}
                />
                <CSVLink 
                  data={csvData} 
                  filename="attendance_data.csv" 
                  className="ant-btn"
                >
                  <FontAwesomeIcon icon={faFileExcel} /> {t('Export CSV')}
                </CSVLink>
              </Space>
              <Pagination
                current={currentPage}
                total={filteredClasses.length}
                pageSize={itemsPerPage}
                onChange={setCurrentPage}
                showSizeChanger
                onShowSizeChange={(_, size) => setItemsPerPage(size)}
                style={{ marginTop: 16, marginBottom: 16 }}
              />
              <Table
                    columns={columns}
                    dataSource={currentItems}
                    pagination={false}
                    rowKey={(record) => record.attendance_id}
                    scroll={{ 
                      x: 1800, 
                    }}
                    style={{
                      overflowX: 'auto',
                      width: '100%'
                    }}
                />
            </TabPane>
            <TabPane tab={t('Teacher Rating')} key="teacherRating">
              <TeacherRatingReport />
            </TabPane>
            <TabPane tab={t('Register for a Test')} key="bookTest">
              <BookTest />
            </TabPane>
            <TabPane tab={t('Student Test Scores')} key="testScore">
              <TestScore />
            </TabPane>
          </Tabs>
        </Content>
      </Layout>
    </Layout>
  );
};

export default withRoleAccess(RegistrationList, ['admin', 'superadmin']);
