import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { Input, Button } from "antd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faDownload } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Modal from '../../components/Modal';
import { confirmAlert } from 'react-confirm-alert';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { sarabunFont } from '../../sarabunFont'; // Import the font data
import 'react-confirm-alert/src/react-confirm-alert.css';
import './Classes.css';

// Function to load the Sarabun font into jsPDF
const loadSarabunFont = (doc) => {
  doc.addFileToVFS('Sarabun.ttf', sarabunFont.src.split('base64,')[1]);
  doc.addFont('Sarabun.ttf', 'Sarabun', 'normal');
  doc.setFont('Sarabun');
};

const FilterTaskTable = () => {
  const { t, i18n } = useTranslation();
  const [classGroups, setClassGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [popupData, setPopupData] = useState(null);
  const [editData, setEditData] = useState(null);
  const [registrationsCount, setRegistrationsCount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [isPasswordModalOpen, setIsPasswordModalOpen] = React.useState(false);
const [password, setPassword] = React.useState("");
const [passwordError, setPasswordError] = React.useState("");
const [classIdToDelete, setClassIdToDelete] = React.useState(null);


  const itemsPerPage = 150;
  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };

  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')} ${t('hrs')}`;
  };

  const formatScheduleHour = (scheduleHourString) => {
    try {
      const [hours, minutes] = scheduleHourString.split(' ').map((value) => parseInt(value, 10));
      return `${hours}:${minutes.toString().padStart(2, '0')} ${t('hrs')}`;
    } catch (error) {
      console.error('Invalid schedule hour value:', scheduleHourString);
      return 'Invalid Time';
    }
  };

  const getDayOfWeek = (dateString) => {
    const date = new Date(dateString);
    const daysOfWeek = t('daysOfWeek', { returnObjects: true });
    return daysOfWeek[date.getDay()];
  };

 

  const fetchClassGroups = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/class-groups`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setClassGroups(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching class groups:', error);
      setError('Failed to fetch class groups. Please try again later.');
      setLoading(false);
    }
  }, [API_URL]);

  const fetchRegistrationsCount = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/registrations/registrations-count`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setRegistrationsCount(response.data);
    } catch (error) {
      console.error('Error fetching registrations count:', error);
      setError('Failed to fetch registrations count. Please try again later.');
    }
  }, [API_URL]);

  useEffect(() => {
    fetchClassGroups();
    fetchRegistrationsCount();
  }, [fetchClassGroups, fetchRegistrationsCount]);

  const handleScheduleClick = (schedule) => {
    const sortedSchedule = [...schedule].sort((a, b) => new Date(b.date) - new Date(a.date));
    setPopupData(sortedSchedule);
  };

  const closePopup = () => {
    setPopupData(null);
  };

  const closeEditModal = () => {
    setEditData(null);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  const handleSaveEdit = async () => {
    const token = localStorage.getItem('token');
    if (!editData.groupID) {
      console.error('groupID is undefined');
      return;
    }
    try {
      await axios.put(`${API_URL}/class-groups/${editData.groupID}`, editData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setEditData(null);
      fetchClassGroups(); // Refresh the class list
    } catch (error) {
      console.error('Error updating class group:', error);
    }
  };

  const handleEditClick = (classGroup) => {
    setEditData({
      groupID: classGroup.groupid,
      class_code: classGroup.class_code,
      book_details_materials: classGroup.book_details_materials,
      isprivate: classGroup.isprivate,
      register_capacity: classGroup.register_capacity,
      price: classGroup.price,
    });
  };

  const getRegisteredStudents = (groupId) => {
    const group = registrationsCount.find(rc => rc.groupid === groupId);
    return group ? group.registered_students : 0;
  };

  const confirmDeleteClassGroup = (groupID) => {
    confirmAlert({
      title: t('confirmDelete'),
      message: t('areYouSureToDelete'),
      buttons: [
        {
          label: t('yes'),
          onClick: async () => {
            try {
              const token = localStorage.getItem('token');
              await axios.delete(`${API_URL}/class-groups/${groupID}`, {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              });
              setClassGroups((prevClasses) => prevClasses.filter((cls) => cls.groupid !== groupID));
            } catch (error) {
              console.error('Error deleting class group:', error);
              setError('Failed to delete class group.');
            }
          }
        },
        {
          label: t('no')
        }
      ]
    });
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Function to handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDeleteClass = (classId) => {
    setClassIdToDelete(classId); // Store class ID
    setPassword(""); // Reset password input
    setPasswordError(""); // Reset any error messages
    setIsPasswordModalOpen(true); // Open modal
  };
  
  const confirmDeleteClass = async () => {
    try {
      if (!password.trim()) {
        setPasswordError(t("Password is required"));
        return;
      }
  
      const token = localStorage.getItem("token");
      if (!token) {
        setError(t("noToken"));
        return;
      }
  
      try {
        await axios.delete(`${API_URL}/classes/${classIdToDelete}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          data: { password: password.trim() }
        });     
  
      // Update local state to remove the deleted class
      setPopupData((prevData) =>
        prevData.filter((cls) => cls.classid !== classIdToDelete)
      );
  
      // Refresh the class groups data
      fetchClassGroups();
  
      // Close the modal
      setIsPasswordModalOpen(false);
  
      // Success confirmation alert
      confirmAlert({
        title: t("Success"),
        message: t("Class has been successfully deleted."),
        buttons: [{ label: "OK", onClick: () => {} }]
      });
    } catch (error) {
      console.error("Delete request error:", error.response || error);
      
      if (error.response) {
        switch (error.response.status) {
          case 400:
            setPasswordError(t("Password is required."));
            break;
          case 401:
            setPasswordError(t("Invalid password. Please try again."));
            break;
          case 404:
            setError(t("Class or user not found."));
            break;
          default:
            setError(t("Failed to delete class. Please try again later."));
        }
      } else {
        setError(t("Network error. Please check your connection."));
      }
    }
  } catch (error) {
    console.error("Error in confirmDeleteClass:", error);
    setError(t("An unexpected error occurred."));
  } finally {
    // setIsLoading(false);
  }
};
  

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    loadSarabunFont(doc);
  
    const margin = 20;
    const startY = margin + 60;
    const pageWidth = doc.internal.pageSize.getWidth();
    const centerX = pageWidth / 2;
    const maxLineWidth = pageWidth - (margin * 2); // Maximum width for text
  
    const headerImage = new Image();
    headerImage.src = '/images/Theplanner.png';
  
    headerImage.onload = () => {
      // Add header image
      doc.addImage(headerImage, 'PNG', margin, margin, 35, 30);
  
      // Add header text
      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');
      doc.text('THE PLANNER EDUCATION', margin + 50, margin + 10);
  
      doc.setFont('helvetica', 'normal');
      doc.text('The Mercury Ville @Chidlom 3rd floor', margin + 50, margin + 15);
      doc.text('Lumpini Pathumwan Bangkok', margin + 50, margin + 20);
      doc.text('www.theplannereducation.com', margin + 50, margin + 25);
      doc.text('Tel. 02-253-2533 or 095-726-2666 Line: @theplanner', margin + 50, margin + 30);
  
      // Prepare class details data
      const classCode = popupData[0]?.class_code || 'N/A';
      const totalHours = popupData?.[0]?.total_hours || 0;
      const subjects = [...new Set(popupData.map(slot => slot.subject_name))];
  
      // Add class code and total hours
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.text(classCode, centerX, startY - 20, { align: 'center' });
      doc.text(`Total Hours: ${formatMinutesToHours(totalHours)}`, centerX, startY - 15, { align: 'center' });
  
      // Handle long subject list with word wrapping
      doc.setFont('helvetica', 'normal');
      const subjectsText = subjects.join(', ');
      
      // Split long subject text into multiple lines
      const splitSubjects = doc.splitTextToSize(`Subject(s): ${subjectsText}`, maxLineWidth);
      
      // Calculate total height needed for subjects
      const lineHeight = 5;
      const totalSubjectsHeight = splitSubjects.length * lineHeight;
      
      // Add each line of subjects text
      splitSubjects.forEach((line, index) => {
        doc.text(line, centerX, startY - 10 + (index * lineHeight), { align: 'center' });
      });
  
      // Adjust table starting position based on number of subject lines
      const tableStartY = startY + totalSubjectsHeight;
  
      // Sort popupData
      const sortedPopupData = popupData.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        if (dateA.getTime() === dateB.getTime()) {
          return a.schedule_time.localeCompare(b.schedule_time);
        }
        return dateA - dateB;
      });
  
      // Prepare table data
      const columns = [
        { header: 'DAY', dataKey: 'schedule_day', width: 25 },
        { header: 'DATE', dataKey: 'date', width: 25 },
        { header: 'SUBJECT', dataKey: 'subject_name', width: 45 },
        { header: 'TIME', dataKey: 'schedule_time', width: 30 },
        { header: 'TUTOR', dataKey: 'nickname', width: 25 },
        { header: 'HOURS', dataKey: 'schedule_hour', width: 20 },
        { header: 'ROOM', dataKey: 'classroom_number', width: 20 }
      ];
  
      const rows = sortedPopupData.map(slot => ({
        schedule_day: getDayOfWeek(slot.date),
        date: formatDate(slot.date),
        subject_name: slot.subject_name,
        schedule_time: `${formatTime(slot.schedule_time)} - ${formatTime(slot.end_time)}`,
        nickname: slot.nickname,
        schedule_hour: formatScheduleHour(slot.schedule_hour),
        classroom_number: slot.classroom_number
      }));
  
      // Add table with adjusted column widths and word wrap
      doc.autoTable({
        startY: tableStartY,
        head: [columns.map(col => col.header)],
        body: rows.map(row => columns.map(col => row[col.dataKey])),
        theme: 'plain', // Change to plain theme
        styles: {
          font: 'Sarabun',
          fontStyle: 'normal',
          cellPadding: 2, // Slightly increased padding
          overflow: 'linebreak',
          fontSize: 9,
          lineWidth: 0, // Remove borders
        },
        columnStyles: {
          subject_name: { cellWidth: 45 },
          schedule_time: { cellWidth: 30 },
          schedule_day: { cellWidth: 25 },
          date: { cellWidth: 25 },
          nickname: { cellWidth: 25 },
          schedule_hour: { cellWidth: 20 },
          classroom_number: { cellWidth: 20 }
        },
        headStyles: {
          fillColor: '#005082', // Dark blue header like in the image
          textColor: '#FFFFFF',
          fontSize: 10,
          fontStyle: 'bold',
          halign: 'left', // Left align header text
          valign: 'middle',
          lineWidth: 0 // Remove header borders
        },
        // Add alternating row styles
        alternateRowStyles: {
          fillColor: '#F5F5F5' // Light gray for alternate rows
        },
        // Base row styles (for non-alternate rows)
        bodyStyles: {
          lineWidth: 0, // Remove borders
          textColor: '#333333', // Dark gray text
          fontSize: 9,
          halign: 'left',
          valign: 'middle',
          fillColor: '#FFFFFF' // White background for normal rows
        },
        margin: { left: margin },
        tableWidth: 'auto',
        // Ensure proper cell padding and alignment
        didParseCell: function(data) {
          if (data.cell.raw === null || data.cell.raw === undefined) {
            data.cell.text = '-'; // Replace empty cells with dash
          }
        },
        // Add more space between rows
        rowSpacing: 1,
      });
  
      // Save the PDF
      doc.save(`${classCode}.pdf`);
    };
  
    headerImage.onerror = () => {
      console.error('Failed to load the header image.');
    };
  };
  

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  // Sort classes by start date (most recent date on top)
  const sortedClassGroups = [...classGroups].sort((a, b) => new Date(b.start_date) - new Date(a.start_date));

  // Filter class groups based on search term
  const filteredClassGroups = sortedClassGroups.filter(cls =>
    cls.class_code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredClassGroups.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredClassGroups.length / itemsPerPage);

  return (
    <div>
      <Sidebar />
      <Header />
      <div className="manage-course-container">
        <h1>{t('manageCourses')}</h1>
        <div className="search-container">
          <input
            type="text"
            placeholder={t('searchByClassCode')}
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input"
          />
        </div>
        <div className="pagination-container">
          <button onClick={handlePrevPage} disabled={currentPage === 1} className="page-button">{t('prev')}</button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => paginate(index + 1)}
              className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
            >
              {index + 1}
            </button>
          ))}
          <button onClick={handleNextPage} disabled={currentPage === totalPages} className="page-button">{t('next')}</button>
        </div>
        <div className="class-table-container" style={{ overflowX: 'auto'}}>
          <table className="task-list-table">
            <thead>
              <tr>
                <th>{t('classCode')}</th>
                <th>{t('schedule')}</th>
                <th>{t('startDate')}</th>
                <th>{t('endDate')}</th>
                <th>{t('totalHours')}</th>
                <th>{t('Notes')}</th>
                <th>{t('private')}</th>
                <th>{t('capacity')}</th>
                <th>{t('totalRegis')}</th>
                <th>{t('actions')}</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((cls, index) => (
                <tr key={index}>
                  <td>
                    <Link to={`/classdetails/${cls.groupid}`} style={{ textDecoration: 'none', fontWeight: 'bold' }}>
                      {cls.class_code}
                    </Link>
                  </td>
                  <td>
                    <button onClick={() => handleScheduleClick(cls.classes)} className="schedule-button">
                      {t('schedule')}
                    </button>
                  </td>
                  <td>{formatDate(cls.start_date)}</td>
                  <td>{formatDate(cls.end_date)}</td>
                  <td>{formatMinutesToHours(cls.total_hours)}</td>
                  <td>{cls.book_details_materials}</td>
                  <td>{cls.isprivate ? t('private') : t('group')}</td>
                  <td>{cls.register_capacity}</td>
                  <td>{getRegisteredStudents(cls.groupid)}</td>
                  <td><div className="action-buttons">
                    {getRegisteredStudents(cls.groupid) < 1 && (
                      <button
                        onClick={() => confirmDeleteClassGroup(cls.groupid)}
                        className="delete-button"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    )}
                    <button
                      onClick={() => handleEditClick(cls)}
                      className="edit-button"
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </div></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Modal isOpen={!!popupData} onClose={closePopup}>
  <h2>{popupData?.[0]?.class_code || t('scheduleDetails')}</h2> 
  <button onClick={handleDownloadPDF} className="print-button">
    <FontAwesomeIcon icon={faDownload} /> {t('downloadPDF')}
  </button>
  <table className="schedule-table">
    <thead>
      <tr>
        <th>{t('day')}</th>
        <th>{t('date')}</th>
        <th>{t('subjectName')}</th>
        <th>{t('time')}</th>
        <th>{t('teacherName')}</th>
        <th>{t('hours')}</th>
        <th>{t('classroomNumber')}</th>
        <th>{t('actions')}</th>
      </tr>
    </thead>
    <tbody>
      {popupData && popupData
        .sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          
          if (dateA.getTime() !== dateB.getTime()) {
            return dateA - dateB;
          } else {
            const timeA = a.schedule_time ? a.schedule_time.split(':').map(Number) : [0, 0];
            const timeB = b.schedule_time ? b.schedule_time.split(':').map(Number) : [0, 0];
            const hoursDiff = timeA[0] - timeB[0];
            const minutesDiff = timeA[1] - timeB[1];
            return hoursDiff !== 0 ? hoursDiff : minutesDiff;
          }
        })
        .map((slot, index) => (
          <tr key={index}>
            <td>{getDayOfWeek(slot.date)}</td>
            <td>{formatDate(slot.date)}</td>
            <td>{slot.subject_name}</td>
            <td>{formatTime(slot.schedule_time)} - {formatTime(slot.end_time)}</td>
            <td>{slot.nickname}</td>
            <td>{formatScheduleHour(slot.schedule_hour)}</td>
            <td>{slot.classroom_number}</td>
            <td>
              {/* Only show delete button if there are no registrations for this class */}
              {getRegisteredStudents(slot.groupid) < 1 && (
                <button
                  onClick={() => handleDeleteClass(slot.classid, slot.groupid)}
                  className="delete-button small"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              )}
            </td>
          </tr>
        ))}
    </tbody>
  </table>
</Modal>
        <Modal isOpen={!!editData} onClose={closeEditModal}>

          <h2>{t('editClassGroup')}</h2>
          {editData && (
            <form>
              <div>
                <label>{t('classCode')}:</label>
                <input type="text" name="class_code" value={editData.class_code} onChange={handleEditChange} />
              </div>
              <div>
                <label>{t('Notes')}:</label>
                <input type="text" name="book_details_materials" value={editData.book_details_materials} onChange={handleEditChange} />
              </div>
              <div>
                <label>{t('private')}:</label>
                <select name="isprivate" value={editData.isprivate} onChange={handleEditChange}>
                  <option value={true}>{t('private')}</option>
                  <option value={false}>{t('group')}</option>
                </select>
              </div>
              <div>
                <label>{t('registerCapacity')}:</label>
                <input type="number" name="register_capacity" value={editData.register_capacity} onChange={handleEditChange} />
              </div>
              <div>
                <label>{t('price')}:</label>
                <input type="number" name="price" value={editData.price} onChange={handleEditChange} />
              </div>
              <div className="modal-actions">
                <button type="button" onClick={handleSaveEdit} className="confirm-button">{t('confirm')}</button>
              </div>
            </form>
          )}
        </Modal>

        <Modal isOpen={isPasswordModalOpen} onClose={() => setIsPasswordModalOpen(false)}>
  <div>
    <h3 style={{ color: "red" }}>
      {t("Dangerous! This action cannot be reversed.")}
    </h3>
    <p>
      {t(
        "Deleting a class cannot be undone! You also CANNOT add a class into any course later. Ensure you want to delete this class."
      )}
    </p>
    {passwordError && <p style={{ color: "red" }}>{passwordError}</p>}
    <Input.Password
      placeholder={t("Enter your password to confirm")}
      value={password}
      onChange={(e) => setPassword(e.target.value)} // Save password input
      style={{ marginBottom: "16px" }}
    />
    <div>
      <Button
        type="primary"
        danger
        onClick={confirmDeleteClass}
        style={{ marginRight: "8px" }}
      >
        {t("Delete")}
      </Button>
      <Button onClick={() => setIsPasswordModalOpen(false)}>{t("Cancel")}</Button>
    </div>
  </div>
</Modal>


      </div>
    </div>
  );
};

export default withRoleAccess(FilterTaskTable, ['admin', 'superadmin']);
